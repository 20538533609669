import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'src/app/store';
import {GET} from 'src/lib/httpClient';
import {enqueueError} from '../appSlice';

export interface IEngineeringOrganization {
	name: string;
	id: string;
}

export interface IOrganizationListResponse {
	organizations: IEngineeringOrganization[];
}

interface IOrganizationsState {
	loading: boolean;
	hasError: boolean;
	organizations: IEngineeringOrganization[];
}

const initialState: IOrganizationsState = {
	loading: false,
	hasError: false,
	organizations: [],
};

export const organizationsSlice = createSlice({
	name: 'engineering/organizations',
	initialState,
	reducers: {
		setOrganizationsLoading: (state) => {
			state.loading = true;
		},
		setOrganizations: (state, action: PayloadAction<IOrganizationListResponse>) => {
			state.organizations = action.payload.organizations;
			state.loading = false;
			state.hasError = false;
		},
		getOrganizationsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {setOrganizationsLoading, setOrganizations, getOrganizationsFail} = organizationsSlice.actions;

export const getOrganizationsAsync = (): AppThunk => (dispatch) => {
	dispatch(setOrganizationsLoading());
	GET<IOrganizationListResponse>(`/api/engineering/organizations/`)
		.then((response) => {
			dispatch(setOrganizations(response));
		})
		.catch((error) => {
			dispatch(getOrganizationsFail());
			dispatch(enqueueError('unable_to_load_organizations', error));
		});
};

export const selectOrganizationsRoot = (state: RootState) => state.engineering.organizations;

// Returns the organizations for engineering purposes (in array format in this case).
export const selectOrganizations = createSelector([selectOrganizationsRoot], (organizations): IEngineeringOrganization[] | undefined => {
	return organizations.organizations;
});

export const selectOrganizationsLoading = (state: RootState) => state.engineering.organizations.loading;

export default organizationsSlice.reducer;
