import {Box} from '@mui/material';
import {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {solutionNavigationWideMode} from 'src/reducers/appSlice';

export interface SolutionLayoutProps {
	sidebar: ReactNode;
}

export const SolutionLayout = (props: SolutionLayoutProps) => {
	const wideMode: boolean = useSelector(solutionNavigationWideMode);

	function wideModeColumnValue(): string {
		if (wideMode) {
			return '160px 1fr';
		} else {
			return '40px 1fr';
		}
	}

	return (
		<Box
			sx={{
				display: 'grid',
				height: '100%',
				width: '100%',
				gridTemplateAreas: [`"navigation solutionmain"`],
				gridTemplateColumns: wideModeColumnValue(),
				gridTemplateRows: '1fr',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					gridArea: 'navigation',
					overflow: 'false',
					height: '100%',
				}}
			>
				{props.sidebar}
			</Box>
			<Box
				sx={{
					gridArea: 'solutionmain',
					overflow: 'false',
					minWidth: 0, // To allow down scaling in trends using  ParentSize
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
};
