import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {jsonArrayMember, jsonMember, jsonObject} from 'typedjson';
import {AppThunk, RootState} from '../../app/store';
import {DiagramLink, DiagramNode} from '../../lib/blockDiagram/guiLibraryStructures/BlockDiagramData';
import {Block} from '../../lib/blockDiagram/instructionClasses/Blocks/Block';
import {Line} from '../../lib/blockDiagram/instructionClasses/Lines/Line';
import {GET, PUT} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

interface ISingleLineDiagrams {
	collection: IDiagramInfoCollection;
}

export interface IDiagramInfoCollection {
	[key: string]: IDiagramInfo;
}
export interface IDiagramInfo {
	configStringForm: string;
	// configuration: DiagramConfiguration | undefined; // Unparsed configuration
	// diagramData: IDiagramData | undefined; // Data based on the configuration
}
@jsonObject
export class DiagramConfiguration {
	@jsonArrayMember(Block)
	public blocks: Block[];
	@jsonArrayMember(Line)
	public lines: Line[];
	@jsonMember(Number)
	public spacing: number;

	public constructor(blocks?: Block[], lines?: Line[], spacing?: number) {
		this.blocks = blocks ? blocks : [];
		this.lines = lines ? lines : [];
		this.spacing = spacing ? spacing : 0;
	}
}
export interface IDiagramData {
	nodes: DiagramNode[];
	links: DiagramLink[];
}

export const emptyDiagramData: IDiagramData = {
	nodes: [],
	links: [],
};

const initialState: ISingleLineDiagrams = {
	collection: {},
};

interface ISingleLineDiagramResponse {
	configuration: string;
}

export const singleLineDiagramSlice = createSlice({
	name: 'engineering/singleLineDiagrams',
	initialState,
	reducers: {
		setSingleLineDiagramConfig: (state, action: PayloadAction<{solutionId: string; configuration: string}>) => {
			if (!state.collection[action.payload.solutionId]) {
				state.collection[action.payload.solutionId] = {configStringForm: ''};
			}
			state.collection[action.payload.solutionId].configStringForm = action.payload.configuration;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.collection[action.payload];
		});
	},
});

export const {setSingleLineDiagramConfig} = singleLineDiagramSlice.actions;

// Get and set configuration.
export const querySingleLineDiagramConfig =
	(solutionId: string): AppThunk =>
	async (dispatch) => {
		try {
			const response = await GET<ISingleLineDiagramResponse>(`/api/engineering/singleLine/${solutionId}`);
			await dispatch(setSingleLineDiagramConfig({solutionId: solutionId, configuration: response.configuration}));
		} catch (e) {
			dispatch(enqueueError('unable_to_load_sld', e));
			console.error('Could not get single line configuration from DB', e);
		}
	};
// Set diagram configuration from UI.
export const SetSingleLineDiagramConfig =
	(solutionId: string, configString: string): AppThunk =>
	async (dispatch) => {
		try {
			await dispatch(setSingleLineDiagramConfig({solutionId: solutionId, configuration: configString}));
			PUT<ISingleLineDiagramResponse>(`/api/engineering/singleLine/${solutionId}`, {solutionId: solutionId, configuration: configString});
		} catch (e) {
			dispatch(enqueueError('unable_to_set_sld', e));
			console.error('Could not set the new single line configuration from a string', configString, e);
		}
	};

// The selectors.
export const SelectSingleDiagramInfoRoot = (state: RootState) => state.engineering.singleLineDiagrams;

export const selectSingleLineDiagramConfigBySolutionId = (solutionId: string) =>
	createSelector([SelectSingleDiagramInfoRoot], (state: ISingleLineDiagrams) => {
		const info = state.collection[solutionId];
		return info ? info.configStringForm : '';
	});

export default singleLineDiagramSlice.reducer;
