/**
 * Types originating from UI configuration are used both in Engineering and Trading side views.
 * To allow sharing of types and to avoid circular dependencies is it better to have these all in
 * same place.
 */

export interface IEngineeringUIConfig {
	Views?: IViews;
	Trends?: ITrendsViewConfiguration;
	MomentaryValues?: IMomentaryValuesViewConfiguration;
	MainScreenWidgets?: IMainScreenWidgetsConfiguration;
	BatteryView?: IBMSConfig;
}

export interface ITradingUIConfig {
	MainScreenWidgets?: IMainScreenWidgetsConfiguration;
}

/**
 * Transform rules
 */
export type TransformRules = Record<string, ITransformRule>;

export const logicalOperators = ['and', 'or'] as const;
export type LogicalOperator = (typeof logicalOperators)[number];

const arithmeticOperators = ['avg', 'sum', 'div', 'multiply'] as const;
export type ArithmeticOperator = (typeof arithmeticOperators)[number];

const comparisonOperators = ['eq', 'lt', 'gt', 'lte', 'gte', 'bitmask'] as const;
export type ComparisonOperator = (typeof comparisonOperators)[number];

const highLevelOperator = ['calcPF', 'calcS', 'loadRate'] as const;
export type HighLevelOperator = (typeof highLevelOperator)[number];

export interface IComparison {
	operator: ComparisonOperator;
	// Value to compare to. String is reference to another RuleID.
	value: number | boolean | string;
}

export type Aggregate = 'min' | 'max' | 'avg' | 'last';

interface ITransformRuleBase {
	targetModuleId?: string; // target module Id defaults to #solution
	title?: string; // Display name
	unit?: string; // Unit of the param after conversion

	// Applying comparison converts result of rule into boolean value
	comparison?: IComparison;
}

/**
 * Derive value from input values based on defined operation
 */
export interface IDerivedValue extends ITransformRuleBase {
	kind: 'DerivedValue';

	/** Input values. References to value accessor keys or other derived values. */
	rules: string[];

	/**  If Operation is present it is used to calculate combined value for rule */
	operation?: LogicalOperator | ArithmeticOperator | HighLevelOperator;
}

/**
 * Value accessor picks parameter from Momentary values *
 */
export interface IValueAccessor extends ITransformRuleBase {
	kind: 'ValueAccessor';

	/** ID of the module */
	module: string;

	/** Parameter id */
	pid: string;

	/** Momentary values have min, max, avg and last. Select what to pick. */
	aggregate: Aggregate;
}

export type ITransformRule = IDerivedValue | IValueAccessor;

/**
 * Allows to hide views from left hand side navigation
 */
export interface IViews {
	signals?: boolean;
	settableParameters?: boolean;
}

export interface ITrendsViewConfiguration {
	quickSelect: ITrendQuickSelection[];
}

export enum TrendViewDataInterval {
	OneSecond = '1s',
	TenSeconds = '10s',
	ThirtySeconds = '30s',
	OneMinute = '1min',
	FiveMinutes = '5min',
	TenMinutes = '10min',
}

export interface ITrendQuickSelection {
	title: string;
	module: string; // Module id
	default?: boolean; // Use this as default selection
	params: string[]; // selected parameters. Up to 6
	interval: TrendViewDataInterval; // Valid keys intervals-array values
}

export interface IMomentaryValuesViewConfiguration {
	quickSelect: IMomentaryValuesQuickSelection[];
}
interface IMomentaryValuesQuickSelection {
	title: string;
	module: string;
	default?: boolean;
}

export interface IMainScreenWidgetsConfiguration {
	gridConfig: IGridConfig;
	gridItems: IGridItem[];
}

interface IGridConfig {
	columns: GridColumns;
	breakpoints: GridBreakpoints;
	layout: GridLayout;
}

interface IGridItem {
	source: 'params' | 'moduleInfo' | 'tripsalarms' | 'tradingConfig';
	itemId?: string;
	paramsConfig?: IParamsConfig;
	moduleInfoConfig?: IModuleInfoConfig;
	tripsalarmsConfig?: ITripAlarmConfig;
	tradingConfig?: ITradingConfigWidgetConfig;
}

export type GridBreakpoints = {[P: string]: number};
export type GridColumns = {[P: string]: number};
// Subset of react grid layout props
export type GridLayout = {
	[P: string]: {
		// Reference to ISolutionKPIEntry id or index of param
		i: string;
		// X position in grid units.
		x: number;
		// Y position in grid units.
		y: number;
		// Width in grid units.
		w: number;
		// Height in grid units.
		h: number;
	}[];
};

interface IGauge {
	type: string;
}

export interface IParamsConfig {
	param: IParamConfigEntry;
	gauge?: IGauge;
}

export interface IModuleInfoConfig {
	id: string;
	title: string;
}

export interface ITripAlarmConfig {
	title: string;
	// To include all modules leave moduleTypes empty and add
	// only one element "*" into moduleIds
	moduleIds?: string[];
	moduleTypes?: string[];
}

export interface ITradingConfigWidgetConfig {
	field: 'maximumOutputPower' | 'maximumEnergyCapacity';
}

export interface IParamConfigEntry {
	title: string;

	// Source module e.g PLC-1
	module: string;

	// Parameter ID (or array of IDs if options is present)
	pid: string | string[];

	// If options are present, we are dealing with state rather than plain value.
	options?: IOption[];

	// Create bitmask from options to conclude state(s) from value
	bitmask?: boolean;

	// Determines which value to use; defaults to 'last' if undefined
	aggregate?: 'min' | 'max' | 'avg' | 'last';
}

/**
 * Battery view does not use 'module' information, but shares rest of the fields.
 */
export type BatteryViewParamConfigEntry = Omit<IParamConfigEntry, 'module'>;

export interface IOption {
	option: number;
	title: string;
}

export interface IBMSConfig {
	systems: {
		title: string;
		module: string;
		batteryRacks: number;
	}[];
	config: {
		essSummary: BatteryViewParamConfigEntry[];
		batteryRackConfig: IBatteryRackConfig;
	};
}

export interface IBatteryRackConfig {
	status: {
		paramConfig: BatteryViewParamConfigEntry;
		connected: {
			expectedOption: number;
			expectedValue: boolean;
		};
		enabled: {
			expectedOption: number;
			expectedValue: boolean;
		};
	};
	overview: BatteryViewParamConfigEntry[];
	details: BatteryViewParamConfigEntry[];
	alarms: BatteryViewParamConfigEntry[];
}
