import { jsonMember, jsonObject } from 'typedjson';
import { Anchor } from "../Anchors/Anchor";
@jsonObject
export class Coordinates {
    @jsonMember(Number)
    public x: number;
    @jsonMember(Number)
    public y: number;

    public constructor(x: number,y: number) {
        this.x = x;
        this.y = y;
    }
}

@jsonObject
export abstract class Block {
    @jsonMember(Coordinates)
    public overrideCoordinates: Coordinates | null;
    @jsonMember(Anchor)
    public anchor: Anchor | undefined;
    @jsonMember(String)
    public svg: string | undefined;
    @jsonMember(Number)
    public size: number | undefined;
    @jsonMember(String)
    public symbolType: string | undefined;
    @jsonMember(String)
    public fill: string | undefined;


    public constructor(
        overrideCoordinates?: {x: number, y: number},
        svg?: string,
        size?: number,
        symbolType?: string,
        fill?: string) {

        this.overrideCoordinates = overrideCoordinates ? overrideCoordinates : null;
        this.anchor = undefined;
        this.svg = svg;
        this.size = size;
        this.symbolType = symbolType;
        this.fill = fill;
    }
}