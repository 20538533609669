import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {ResponsiveFontSizesOptions} from '@mui/material/styles/responsiveFontSizes';
import {mwPrimary, mwSecondary} from './palette';
import type {} from '@mui/x-data-grid/themeAugmentation';

const options: ResponsiveFontSizesOptions = {
	factor: 24,
	disableAlign: true,
	breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
};

// Custom light theme
let lTheme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		h3: {
			fontSize: '1.375rem',
			fontWeight: 'bold',
			color: mwPrimary.dark,
		},
		body1: {
			fontSize: '1rem',
		},
		body2: {
			fontSize: '0.93rem',
		},
		button: {
			fontSize: '15px',
			'&:hover': {
				backgroundColor: '#fff',
				color: '#3c52b2',
			},
		},
		navigationButton: {
			fontSize: '12px',
			fontVariant: 'full-width',
			textTransform: 'initial',
		},
		navigationIcon: {
			fontSize: '400px',
		},
	},
	palette: {
		mode: 'light',
		primary: mwPrimary,
		secondary: mwSecondary,
		background: {
			default: '#e7eaed',
		},
	},
	mixins: {
		MuiDataGrid: {
			// Header color
			containerBackground: '#f7f7f7',
		},
	},
});

// Custom dark theme
let dTheme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
				},
			},
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		h3: {
			fontSize: '1.375rem',
			fontWeight: 'bold',
			color: mwPrimary.light,
		},
		body1: {
			fontSize: '1rem',
		},
		body2: {
			fontSize: '0.93rem',
		},
		button: {
			fontSize: '15px',
			'&:hover': {
				backgroundColor: '#fff',
				color: '#3c52b2',
			},
		},
		navigationButton: {
			fontSize: '12px',
			fontVariant: 'full-width',
			textTransform: 'initial',
		},
		navigationIcon: {
			fontSize: '400px',
		},
	},
	palette: {
		mode: 'dark',
		primary: mwPrimary,
		secondary: mwSecondary,
		background: {
			default: '#181c3f',
		},
	},
	mixins: {
		MuiDataGrid: {
			// Header color
			containerBackground: '#616161',
		},
	},
});

export const lightTheme = responsiveFontSizes(lTheme, options);
export const darkTheme = responsiveFontSizes(dTheme, options);

// Add custom variants
declare module '@mui/material/styles' {
	interface TypographyVariants {
		navigationButton: React.CSSProperties;
		navigationIcon: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		navigationButton?: React.CSSProperties;
		navigationIcon?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		navigationButton: true;
		navigationIcon: true;
	}
}
