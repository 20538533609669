import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {setTransformRules} from '../momentaryValuesSlice';
import {enqueueError} from '../appSlice';
import {ITradingUIConfig, TransformRules} from '../uiConfig.types';

export interface ITradingSolutionConfigResponse {
	solution: string;
	solutionId: string;
	transformRules: TransformRules;
	tradingUiConfig: ITradingUIConfig;
}

interface uiConfigState {
	uiConfig: {
		[key: string]: Record<string, any> | undefined;
	};
}

const initialState: uiConfigState = {
	uiConfig: {},
};

export const uiConfigSlice = createSlice({
	name: 'trading/uiConfig',
	initialState,
	reducers: {
		setUIConfig: (state, action: PayloadAction<ITradingSolutionConfigResponse>) => {
			const id = action.payload.solutionId;
			state.uiConfig[id] = action.payload.tradingUiConfig;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {setUIConfig} = uiConfigSlice.actions;

/**
 * Loads Trading UI Configuration
 * @param solutionId
 * @returns Trading UI config + transform rules
 */
export const getSolutionConfigAsync =
	(solutionId: string): AppThunk<Promise<ITradingSolutionConfigResponse | undefined>> =>
	async (dispatch) => {
		try {
			const response = await GET<ITradingSolutionConfigResponse>(`/api/trading/solutionconfig/${solutionId}`);
			dispatch(setUIConfig(response));

			// Set transform rules. Resides in shared momentary values store
			dispatch(setTransformRules(response));
			return response;
		} catch (error) {
			dispatch(enqueueError('unable_to_load_solution_configuration', error));
			console.error('Unable to load Trading solution configuration', solutionId, error);
		}
	};

export const selectUIConfigBySolutionId = (state: RootState, solutionId: string) => state.trading.uiConfig.uiConfig[solutionId];

export default uiConfigSlice.reducer;
