import {createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {AppThunk, RootState} from '../../app/store';
import {DELETE, GET, POST} from '../../lib/httpClient';
import {AvailableMarkets} from '../../lib/reserveMarketConstants';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export const SlotDurations = [15, 60] as const;
export type SlotDuration = (typeof SlotDurations)[number];

export enum ScheduleStatus {
	Modified = 'modified',
	InSync = 'in_sync',
}

export enum AcknowledgementStatus {
	Manual = 'manual',
	Unacknowledged = 'unacknowledged',
	Acknowledged = 'acknowledged',
}

export interface ITrade {
	tradeId: string;
	startTime: string;
	endTime: string;
	market: AvailableMarkets;
	price: number;
	capacity: number;
	acknowledgementStatus: AcknowledgementStatus;
	scheduleStatus: ScheduleStatus;
}

interface TradesState {
	isLoading: boolean;
	hasError: boolean;
	trades: {
		[solutionId: string]: ITrade[];
	};
}

const initialState: TradesState = {
	isLoading: false,
	hasError: false,
	trades: {},
};

interface GetTradesSuccessPayload {
	solutionId: string;
	trades: ITrade[];
}

interface UpdateTradePayload {
	solutionId: string;
	trade: ITrade;
}

interface ICreateTradeResponse {
	trade: ITrade;
}

interface ICreateTradeReqBody {
	/**
	 * Target market
	 */
	market: string;

	/**
	 * ISO string (UTC)
	 */
	startTime: string;

	/**
	 * In minutes.
	 */
	duration: SlotDuration;

	/**
	 * Price
	 */
	price: number;

	/*
	 * Capacity
	 */
	capacity: number;
}

const tradesSlice = createSlice({
	name: 'trading/trades',
	initialState,
	reducers: {
		setTradesLoading: (state) => {
			state.isLoading = true;
			state.hasError = false;
		},

		getTradesSuccess: (state, action: PayloadAction<GetTradesSuccessPayload>) => {
			state.trades[action.payload.solutionId] = action.payload.trades;
			state.isLoading = false;
			state.hasError = false;
		},

		getTradesFail: (state, action: PayloadAction<string>) => {
			state.trades[action.payload] = new Array<ITrade>();
			state.isLoading = false;
			state.hasError = true;
		},

		addOrUpdateTrade: (state, action: PayloadAction<UpdateTradePayload>) => {
			const solutionId = action.payload.solutionId;
			const tradeId = action.payload.trade.tradeId;

			const trades = state.trades[solutionId];
			if (trades) {
				const index = trades.findIndex((trade) => trade.tradeId === tradeId);
				if (index > -1) {
					trades[index] = action.payload.trade;
				} else {
					trades.push(action.payload.trade);
				}
			}
		},

		removeTrade: (state, action: PayloadAction<{solutionId: string; tradeId: string}>) => {
			const {solutionId, tradeId} = action.payload;
			const trades = state.trades[solutionId];

			if (trades) {
				const index = trades.findIndex((trade) => trade.tradeId === tradeId);
				if (index > -1) {
					trades.splice(index, 1);
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.trades[action.payload];
		});
	},
});

const {setTradesLoading, getTradesSuccess, getTradesFail, addOrUpdateTrade, removeTrade} = tradesSlice.actions;

// This is called to fetch trades for a specific solution
export const getTradesAsync =
	(solutionId: string): AppThunk =>
	(dispatch) => {
		dispatch(setTradesLoading());

		GET<ITrade[]>(`/api/trading/trades/${solutionId}`)
			.then((response) => {
				dispatch(
					getTradesSuccess({
						solutionId: solutionId,
						trades: response,
					}),
				);
			})
			.catch((error) => {
				console.error('Unable to load trades', solutionId, error);
				dispatch(getTradesFail(solutionId));
				dispatch(enqueueError('unable_to_load_trades', error));
			});
	};

export const createTradeAsync =
	(solutionId: string, market: AvailableMarkets, startTime: string, duration: SlotDuration, price: number, capacity: number): AppThunk<Promise<ITrade | undefined>> =>
	async (dispatch) => {
		try {
			const response = await POST<ICreateTradeResponse>(`/api/trading/trades/${solutionId}`, {
				market: market,
				startTime: startTime,
				duration: duration,
				price: price,
				capacity: capacity,
			} as ICreateTradeReqBody);
			dispatch(
				addOrUpdateTrade({
					solutionId: solutionId,
					trade: response.trade,
				}),
			);
			return response.trade;
		} catch (error) {
			console.error('Unable to create trade', solutionId, error);
			dispatch(enqueueError('trade_unable_to_create', error));
		}
	};

export const deleteTradeAsync =
	(solutionId: string, tradeId: string): AppThunk<Promise<void>> =>
	async (dispatch) => {
		try {
			await DELETE(`/api/trading/trades/${solutionId}/trade/${tradeId}`);
			dispatch(
				removeTrade({
					solutionId: solutionId,
					tradeId: tradeId,
				}),
			);
			return;
		} catch (error) {
			console.error('Unable to remove trade', solutionId, error);
			dispatch(enqueueError('trade_unable_to_remove', error));
		}
	};
/**
 * Registers socket event handlers to update trades
 * @param dispatch
 * @param socket
 */
export const registerTradeEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('tradeUpdate', (updatePayload: UpdateTradePayload) => {
		dispatch(addOrUpdateTrade(updatePayload));
	});

	socket.on('tradeRemove', (removedTrade: {solutionId: string; tradeId: string}) => {
		dispatch(removeTrade(removedTrade));
	});
};

export const selectTradesIsLoading = (state: RootState) => {
	const tradesState = state.trading.trades;

	if (tradesState) {
		return tradesState.isLoading;
	}

	return false;
};

export const selectTradesHasError = (state: RootState) => {
	const tradesState = state.trading.trades;

	if (tradesState) {
		return tradesState.hasError;
	}

	return false;
};

export const selectTradesBySolutionId = (state: RootState, solutionId: string) => state.trading.trades.trades[solutionId];

export default tradesSlice.reducer;
