import { SupportedDirection } from "../Enumerations";
import { Anchor } from "../Anchors/Anchor";
import { jsonObject, jsonMember, jsonArrayMember} from 'typedjson';

// Structure that for line that can be drawn between two blocks.
// This structure can be used to form a link between diagram nodes.

@jsonObject
export abstract class Line {
    @jsonMember(Anchor)
    public start: Anchor | undefined;
    @jsonMember(Anchor)
    public end: Anchor | undefined;
    @jsonMember(Number)
    public direction: number | undefined;
    @jsonArrayMember(Object)
    public breakPoints: {x: number, y: number}[] | undefined;

    public constructor(
        start?: Anchor,
        end?: Anchor,
        direction?: number,
        breakPoints?: {x: number, y: number}[]) {
        
        this.start = start;
        this.end = end;
        this.direction = direction ? direction : SupportedDirection.Down as number;
        this.breakPoints = breakPoints;
    }
}