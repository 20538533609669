import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {ITradingConfig} from './tradingConfigSlice';

export interface IAdminSolution {
	// These fields are present for every solution
	id: string;
	displayName: string;
	type: string;

	// Only present, if solution already has trading config.
	tradingConfig?: ITradingConfig;
}

type AdminSolutions = {[key: string]: IAdminSolution};

interface SolutionState {
	loading: boolean;
	hasError: boolean;
	adminSolutions: AdminSolutions;
}

const initialState: SolutionState = {
	loading: false,
	hasError: false,
	adminSolutions: {},
};

export const adminSolutionsSlice = createSlice({
	name: 'trading/adminsolutions',
	initialState,
	reducers: {
		setAdminSolutionLoading: (state) => {
			state.loading = true;
		},
		getAdminSolutionsSuccess: (state, action: PayloadAction<AdminSolutions>) => {
			state.adminSolutions = action.payload;
			state.loading = false;
			state.hasError = false;
		},
		getAdminSolutionsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		updateAdminSolution: (state, action: PayloadAction<IAdminSolution>) => {
			const id = action.payload.id;
			state.adminSolutions[id] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

const {setAdminSolutionLoading, getAdminSolutionsSuccess, getAdminSolutionsFail, updateAdminSolution} = adminSolutionsSlice.actions;

export const getAdminSolutionsAsync = (): AppThunk => async (dispatch) => {
	dispatch(setAdminSolutionLoading());

	try {
		const adminSolutions = await GET<IAdminSolution[]>('/api/trading/adminsolutions');

		if (adminSolutions) {
			const sol: AdminSolutions = {};

			for (const s of adminSolutions) {
				sol[s.id] = s;
			}
			dispatch(getAdminSolutionsSuccess(sol));
		}
	} catch (error: any) {
		dispatch(getAdminSolutionsFail());
		dispatch(enqueueError('unable_to_load_solutions', error));
	}
};

// Update one admin solution entry
export const updateAdminSolutionEntry =
	(updatedAdminSolution: IAdminSolution): AppThunk =>
	(dispatch) => {
		dispatch(updateAdminSolution(updatedAdminSolution));
	};

export const selectAdminSolutionsRoot = (state: RootState) => state.trading.adminSolutions;

export const selectSolutionsLoading = (state: RootState) => state.trading.adminSolutions.loading;

export const selectAdminSolutionAsArray = createSelector([selectAdminSolutionsRoot], (adminSolutions) => {
	if (adminSolutions) {
		return Object.values(adminSolutions.adminSolutions);
	}
	return [];
});

const getSolutionId = (_state: RootState, id: string) => id;
export const selectAdminSolutionById = createSelector([selectAdminSolutionsRoot, getSolutionId], (adminSolutions, id: string) => adminSolutions.adminSolutions[id]);

export default adminSolutionsSlice.reducer;
