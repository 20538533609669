import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DisplaySlot} from 'src/components/CommandBar';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

// UICommand in backend
export interface ICommand {
	/**
	 * Unique command ID
	 */
	command: string;

	/**
	 * Command display name in the UI (button text)
	 */
	displayName: string;

	/**
	 * Display slot name in the UI
	 * Links command to certain place within the UI
	 */
	displaySlot: DisplaySlot;

	/**
	 * Colors:
	 * Green - Positive command
	 * Blue - Neutral command
	 * Orange - Warning command
	 * Red - Negative command
	 * Defaults to BLUE
	 */
	color?: 'GREEN' | 'BLUE' | 'ORANGE' | 'RED';

	/**
	 * If modules is present, user must be prompted to select from given modules
	 */
	modules: string[];
}

export interface ICommandsResponse {
	solution: string; // solution._id
	solutionId: string;
	commands: ICommand[];
}
export interface IExecuteCommandRequestBody {
	command: string;
	modules?: string[];
}

export interface IExecuteCommandResponse {
	solutionId: string;
	command: string;
	delivered: boolean;
	statusCode?: number;
	message?: string;
	errorMessage?: string;
}

interface ICommandsState {
	commands: {
		[key: string]: ICommand[];
	};
}

const initialState: ICommandsState = {
	commands: {},
};

export const commandsSlice = createSlice({
	name: 'engineering/commands',
	initialState,
	reducers: {
		setCommands: (state, action: PayloadAction<ICommandsResponse>) => {
			const solutionId = action.payload.solutionId;
			state.commands[solutionId] = action.payload.commands;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.commands[action.payload];
		});
	},
});

export const {setCommands} = commandsSlice.actions;

/**
 * Load commands applicable to solution and user's role
 * @param solutions
 * @returns
 */
export const getSolutionCommandsAsync =
	(solutions: String[]): AppThunk =>
	(dispatch) => {
		solutions.forEach((solutionId) => {
			GET<ICommandsResponse>(`/api/engineering/commands/${solutionId}`)
				.then((response) => {
					dispatch(setCommands(response));
				})
				.catch((error) => {
					dispatch(enqueueError('unable_to_load_commands', error));
					console.error('Unable to get commands', solutionId, error);
				});
		});
	};

/**
 * Execute command
 * @param solutionId Target solutions id
 * @param command Command name
 * @param moduleIds Target module Ids or undefined
 * @returns
 */
export const executeSolutionCommand = async (solutionId: String, command: ICommand, moduleIds: string[] | undefined) => {
	try {
		const body: IExecuteCommandRequestBody = {
			command: command.command,
			modules: moduleIds,
		};
		const res = await POST<IExecuteCommandResponse>(`/api/engineering/commands/${solutionId}/exec`, body);
		return res;
	} catch (error) {
		console.error('Unable to execute command', error);
		return undefined;
	}
};

export const selectCommandsRoot = (state: RootState) => state.engineering.commands;

export const selectCommandsBySolutionId = (state: RootState, solutionId: string): ICommand[] | undefined => {
	return state.engineering.commands.commands[solutionId];
};

export default commandsSlice.reducer;
