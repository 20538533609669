export const isToken = (input:string) => {
    const tokenRegexp = new RegExp(/^#\{.+\}#$/);
    return tokenRegexp.test(input);
}

// TOKEN AUTH
const defaultAuthVariables = {
	B2C_DB_TENANT_NAME: 'mwiseB2cDev',
    WEB_APP_NAME: 'mwise-dev',
    B2C_DB_WEB_APP_CLIENT_ID: '1babc654-8fe8-4688-9142-6215c2b01f1f',
};

const tokenAuthVariables = {
	B2C_DB_TENANT_NAME: 'meruswise',
    WEB_APP_NAME: 'mwise-production',
    B2C_DB_WEB_APP_CLIENT_ID: 'd77afa44-4684-432e-965b-d1b837f27ea2',
};

const authVariables = {
	B2C_DB_TENANT_NAME: isToken(tokenAuthVariables.B2C_DB_TENANT_NAME) ? defaultAuthVariables.B2C_DB_TENANT_NAME : tokenAuthVariables.B2C_DB_TENANT_NAME,
    WEB_APP_NAME: isToken(tokenAuthVariables.WEB_APP_NAME) ? defaultAuthVariables.WEB_APP_NAME : tokenAuthVariables.WEB_APP_NAME,
    B2C_DB_WEB_APP_CLIENT_ID: isToken(tokenAuthVariables.B2C_DB_WEB_APP_CLIENT_ID) ? defaultAuthVariables.B2C_DB_WEB_APP_CLIENT_ID : tokenAuthVariables.B2C_DB_WEB_APP_CLIENT_ID,
};

export {authVariables} ;