import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, InputLabel, TextField, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AvailableMarkets} from 'src/lib/reserveMarketConstants';
import {ITradingConfigDraft} from 'src/views/trading/Admin';

interface ITradingConfigFormDialogProps {
	solutionDisplayName: string;
	handleClose: () => void;
	handleOkClick: (newTradingConfig: ITradingConfigDraft) => void;
	/**
	 * Trading config to provide initial values. Optional.
	 */
	tradingConfig?: ITradingConfigDraft;
	updatingExistingConfig: boolean;
	edxPasswordSet: boolean;
}

export const createRegExp = (decimals: number) => {
	const regExpText = decimals === 0 ? `^\\d+$` : `^\\d+(\\.\\d{1,${decimals}})?$`;
	const decimalRegExp = new RegExp(regExpText);
	return decimalRegExp;
};

const numericInputValidation = createRegExp(3);

const isErroneousValue = (currentValue: string, min: number | undefined, max: number | undefined) => {
	// If input does not look like numeric value, do not try to parse it as such
	if (numericInputValidation.test(currentValue)) {
		const parsedValue = Number(currentValue);
		return (max !== undefined && parsedValue > max) || (min !== undefined && parsedValue < min);
	}
	return true;
};

const isErroneousString = (value: string, required: boolean) => {
	if (value.length !== value.trim().length) {
		return true;
	}
	if (required) {
		return value === '';
	}
	return false;
};

const TradingConfigFormDialog = (props: ITradingConfigFormDialogProps) => {
	const {t} = useTranslation();

	const {handleClose, handleOkClick, tradingConfig, updatingExistingConfig, edxPasswordSet} = props;

	// EIC code, maximumEnergyCapacity and maximumOutputPower are mandatory fields in trading config
	const [eicCode, setEicCode] = useState<string>(tradingConfig?.ecpConfiguration.eicCode || '');
	const [maximumEnergyCapacity, setMaximumEnergyCapacity] = useState<string>(tradingConfig?.maximumEnergyCapacity.toString() || '');
	const [maximumOutputPower, setMaximumOutputPower] = useState<string>(tradingConfig?.maximumOutputPower.toString() || '');

	const [enabledMarkets, setEnabledMarkets] = useState<AvailableMarkets[]>(tradingConfig?.enabledMarkets || []);

	// EDX-related variables
	const [edxHost, setEdxHost] = useState(tradingConfig?.ecpConfiguration.edxHost || '');
	const [edxPort, setEdxPort] = useState(tradingConfig?.ecpConfiguration.edxPort?.toString() || '');
	const [edxUsername, setEdxUsername] = useState(tradingConfig?.ecpConfiguration.edxUsername || '');

	// Password can only be set, but not viewed after it has been set, unless user is still editing draft.
	const [edxPassword, setEdxPassword] = useState(tradingConfig?.ecpConfiguration.edxPassword || '');
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const eicCodeError = useMemo(() => {
		// TODO: Regexp to validate EIC code?
		return eicCode?.length !== 16;
	}, [eicCode]);

	const maximumEnergyCapacityError = useMemo(() => {
		return maximumEnergyCapacity === '' ? true : isErroneousValue(maximumEnergyCapacity, 0.1, 1000);
	}, [maximumEnergyCapacity]);

	const maximumOutputPowerError = useMemo(() => {
		return maximumOutputPower === '' ? true : isErroneousValue(maximumOutputPower, 0.1, 1000);
	}, [maximumOutputPower]);

	const edxSettingsRequired = useMemo(() => {
		return enabledMarkets.length > 0;
	}, [enabledMarkets]);

	const edxHostError = useMemo(() => {
		return isErroneousString(edxHost, edxSettingsRequired);
	}, [edxHost, edxSettingsRequired]);

	const edxPortError = useMemo(() => {
		if (!edxSettingsRequired && edxPort === '') {
			return false;
		}
		return isErroneousValue(edxPort, 1, 65535);
	}, [edxPort, edxSettingsRequired]);

	const edxUsernameError = useMemo(() => {
		return isErroneousString(edxUsername, edxSettingsRequired);
	}, [edxUsername, edxSettingsRequired]);

	const edxPasswordError = useMemo(() => {
		return isErroneousString(edxPassword, edxSettingsRequired && !edxPasswordSet);
	}, [edxPassword, edxSettingsRequired, tradingConfig]);

	const handleMarketSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		const market = event.target.name as AvailableMarkets;
		setEnabledMarkets((prevMarkets) => {
			let newMarkets = [...prevMarkets];
			const index = newMarkets.indexOf(market);
			if (index > -1) {
				newMarkets.splice(index, 1);
			} else {
				newMarkets.push(market);
			}
			return newMarkets;
		});
	};

	const okButtonDisabled = useMemo(() => {
		return eicCodeError || maximumEnergyCapacityError || maximumOutputPowerError || edxHostError || edxPortError || edxUsernameError || edxPasswordError;
	}, [eicCodeError, maximumEnergyCapacityError, maximumOutputPowerError, edxPortError, edxHostError, edxUsernameError, edxPasswordError]);

	const confirmChanges = () => {
		// All values should be valid as OK button is disabled, if there is an error.
		let config: ITradingConfigDraft = {
			ecpConfiguration: {
				eicCode: eicCode,
			},
			maximumEnergyCapacity: Number(maximumEnergyCapacity),
			maximumOutputPower: Number(maximumOutputPower),
			enabledMarkets: enabledMarkets,
		};

		// During creation empty values are mapped to "undefined", meaning that they are not included in the post request body.
		// Edx settings can be unset when updating by setting value to "null".
		config.ecpConfiguration.edxHost = edxHost !== '' ? edxHost : updatingExistingConfig ? null : undefined;
		config.ecpConfiguration.edxPort = edxPort !== '' ? Number.parseInt(edxPort) : updatingExistingConfig ? null : undefined;
		config.ecpConfiguration.edxUsername = edxUsername !== '' ? edxUsername : updatingExistingConfig ? null : undefined;

		// Password will be omitted from create/update request, unless user has typed in new password.
		config.ecpConfiguration.edxPassword = edxPassword !== '' ? edxPassword : undefined;

		handleOkClick(config);
	};

	return (
		<div>
			<Dialog open={true} onClose={handleClose}>
				<DialogTitle>{props.solutionDisplayName}</DialogTitle>
				<DialogContent>
					<Typography variant="h6" sx={{mt: 2}}>
						{t('solution_display_ecp_configuration')}
					</Typography>
					<TextField required autoFocus margin="dense" id="eicCode" label={t('eic_code_header')} error={eicCodeError} fullWidth variant="standard" defaultValue={eicCode} onChange={(e) => setEicCode(e.target.value)} />
					<TextField margin="dense" required={edxSettingsRequired} id="eicHost" label={t('edx_host_header')} error={edxHostError} fullWidth variant="standard" defaultValue={edxHost} onChange={(e) => setEdxHost(e.target.value)} />
					<TextField margin="dense" required={edxSettingsRequired} id="eicPort" label={t('edx_port_header')} error={edxPortError} fullWidth variant="standard" defaultValue={edxPort} onChange={(e) => setEdxPort(e.target.value)} />
					<TextField
						margin="dense"
						required={edxSettingsRequired}
						id="eicUsername"
						label={t('edx_username_header')}
						error={edxUsernameError}
						fullWidth
						variant="standard"
						defaultValue={edxUsername}
						onChange={(e) => setEdxUsername(e.target.value)}
					/>

					<FormControl variant="standard" fullWidth sx={{mt: 1}}>
						<InputLabel htmlFor="eicPassword" error={edxPasswordError}>
							{t('edx_password_header') + (edxSettingsRequired ? ' *' : '')}{' '}
						</InputLabel>
						<Input
							fullWidth
							margin="dense"
							id="eicPassword"
							type={showPassword ? 'text' : 'password'}
							defaultValue={edxPassword}
							onChange={(e) => setEdxPassword(e.target.value)}
							error={edxPasswordError}
							endAdornment={
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>

					<Typography variant="h6" sx={{mt: 2}}>
						{t('solution_display_energy_storage_properties')}
					</Typography>
					<TextField
						required
						margin="dense"
						id="MaxCapacity"
						label={t('solution_display_maximum_energy_capacity') + ' [' + t('unit_mwh') + ']'}
						error={maximumEnergyCapacityError}
						fullWidth
						variant="standard"
						value={maximumEnergyCapacity}
						onChange={(e) => setMaximumEnergyCapacity(e.target.value)}
					/>
					<TextField
						required
						margin="dense"
						id="MaxOutputPower"
						label={t('solution_display_maximum_output_power') + ' [' + t('unit_mw') + ']'}
						error={maximumOutputPowerError}
						fullWidth
						variant="standard"
						value={maximumOutputPower}
						onChange={(e) => setMaximumOutputPower(e.target.value)}
					/>

					<Typography variant="h6" sx={{mt: 2}}>
						{t('solution_display_enabled_markets')}
					</Typography>
					<FormGroup>
						{Object.values(AvailableMarkets).map((market) => {
							return <FormControlLabel key={market} control={<Checkbox name={market} checked={enabledMarkets.indexOf(market) > -1} onChange={handleMarketSelection} />} label={market} />;
						})}
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}> {t('cancel')}</Button>
					<Button disabled={okButtonDisabled} onClick={confirmChanges}>
						{t('ok')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default TradingConfigFormDialog;
