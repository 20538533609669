import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../app/store';
import {requestVerificationCode, verifyChannel} from '../reducers/engineering/notificationChannelsSlice';

export interface NotificationChannelVerificationDialogProps {
	open: boolean;
	channelId: string;
	handleClose: () => void;
}

const NotificationChannelVerificationDialog = (props: NotificationChannelVerificationDialogProps) => {
	const {handleClose, channelId} = props;
	const {t} = useTranslation();

	// using typed appDispatch instead of regular
	// to get correct typing for the return value
	const dispatch = useAppDispatch();

	const [verificationCode, setVerificationCode] = useState<string>('');

	const [verificationError, setVerificationError] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(event.target.value);
	};

	const requestNewVerificationCode = () => {
		dispatch(requestVerificationCode(channelId));
	};

	const verify = async () => {
		const code = verificationCode.trim();
		const verified = await dispatch(verifyChannel(channelId, code));

		if (verified) {
			handleClose();
		} else {
			setVerificationError(true);
		}
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{t('verification_dialog_title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('notification_channel_verification_dialog_text')}</DialogContentText>
					<form noValidate autoComplete="off">
						<TextField autoComplete="off" error={verificationError} helperText={verificationError && t('verification_failed_text')} value={verificationCode} onChange={handleChange} id="name" label="Verification code" type="password" />
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={requestNewVerificationCode} color="primary">
						{t('resend_verification_code')}
					</Button>
					<Button onClick={handleClose} color="primary">
						{t('cancel')}
					</Button>

					<Button onClick={verify} color="primary" variant="contained">
						{t('verify_code')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default NotificationChannelVerificationDialog;
