import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {DateTime, Interval} from 'luxon';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {IDeviceSchedule} from 'src/reducers/engineering/otaFilesSlice';
import {selectSolutionAsArray} from '../../reducers/engineering/solutionsSlice';
import {useDateTimeLimits} from './DateTimeLimits';

interface IScheduleCreationProps {
	open: boolean;
	onClose: (schedules: IDeviceSchedule[]) => void;
}

interface IIoTDeviceRow {
	solutionName: string;
	solutionType: string;
	iotDeviceId: string;
}

const CreateSchedulesDialog = (props: IScheduleCreationProps) => {
	const {open, onClose} = props;
	const solutions = useSelector(selectSolutionAsArray);
	const {t} = useTranslation();
	const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
	const [desiredInstallTime, setDesiredInstallTime] = useState<DateTime | null>(DateTime.local().startOf('minute'));

	// These limits will update every 5 sec
	const {minDate, maxDate} = useDateTimeLimits();

	const desiredInstallTimeValid = useMemo(() => {
		const interval = Interval.fromDateTimes(minDate, maxDate);
		return desiredInstallTime !== null && desiredInstallTime.isValid && interval.contains(desiredInstallTime);
	}, [desiredInstallTime, minDate, maxDate]);

	const iotDevices = useMemo(() => {
		const rows: IIoTDeviceRow[] = [];
		for (const s of solutions) {
			for (const deviceId of s.iotDevices) {
				rows.push({
					solutionName: s.displayName,
					solutionType: s.type,
					iotDeviceId: deviceId,
				});
			}
		}
		return rows;
	}, [solutions]);

	const columns = useMemo<GridColDef[]>(
		() => [
			{
				field: 'solutionName',
				headerName: t('solution'),
				type: 'string',
				width: 150,
			},
			{
				field: 'solutionType',
				headerName: t('system_type'),
				type: 'string',
				width: 150,
			},
			{
				field: 'iotDeviceId',
				headerName: t('device'),
				type: 'string',
				flex: 1,
			},
		],
		[t],
	);

	const handleClose = () => {
		if (desiredInstallTime && desiredInstallTime.isValid) {
			// Using "!" because DateTime is ensured to be valid
			// .tUTC() as there is no other way to get ISO timestamp ending with 'Z'
			const scheduledInstallTime = desiredInstallTime.toUTC().toISO()!;
			const devices = [...selectedDevices];
			const schedules: IDeviceSchedule[] = devices.map((id) => {
				return {
					deviceId: id,
					scheduledInstallTime: scheduledInstallTime,
				};
			});
			setSelectedDevices([]);
			onClose(schedules);
		} else {
			setSelectedDevices([]);
			onClose([]);
		}
	};
	const handleCancel = () => {
		setSelectedDevices([]);
		onClose([]);
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="xl"
			PaperProps={{
				sx: {
					height: '90vh',
				},
			}}
		>
			<DialogTitle>{t('add_schedule')}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						height: '100%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box sx={{mt: 1}}>
						<MobileDateTimePicker
							slotProps={{
								textField: {
									variant: 'outlined',
									error: !desiredInstallTimeValid,
									helperText: desiredInstallTimeValid ? '' : t('desired_install_time_error'),
									inputProps: {
										'data-test': 'install_time',
									},
								},
							}}
							label={t('desired_install_time_select_label')}
							ampm={false}
							disableFuture={false}
							disablePast={true}
							minDate={minDate}
							maxDate={maxDate}
							value={desiredInstallTime}
							onChange={(date) => setDesiredInstallTime(date)}
							minutesStep={1}
							openTo="hours"
						/>
					</Box>
					<DataGrid
						className="iot_devices_grid"
						sx={{flexGrow: 1, mt: 1}}
						autoPageSize
						density="compact"
						checkboxSelection
						columns={columns}
						rows={iotDevices}
						getRowId={(row) => row.iotDeviceId}
						onRowSelectionModelChange={(selectedRows) => {
							setSelectedDevices(selectedRows as string[]);
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button data-test="cancel_button" onClick={handleCancel}>
					{t('cancel')}
				</Button>
				<Button data-test="ok_button" onClick={handleClose} disabled={!desiredInstallTimeValid || selectedDevices.length === 0}>
					{t('ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateSchedulesDialog;
