import {createSelector, createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export interface IEvent {
	_id: string;
	solutionId: string; // From what solution event orginates
	moduleId: string; // Module id as reported by the Edge device "Module3"
	eventId: string; // non-unique identifier
	name: string;
	time: Date; // ISO string
	type: string;
}

interface ITripalarmState {
	events: {
		[key: string]: IEvent[];
	};
}

const initialState: ITripalarmState = {
	events: {},
};

export interface IEventResponse {
	solutionId: string;
	moduleId: string;
	events: IEvent[];
	limitExceeded: boolean; // notify user there are more events we can return
}

export const eventsSlice = createSlice({
	name: 'engineering/events',
	initialState,
	reducers: {
		setEvents: (state, action: PayloadAction<IEventResponse>) => {
			// N.B. don't try this without immer
			const solutionId = action.payload.solutionId;
			state.events[solutionId] = action.payload.events;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.events[action.payload];
		});
	},
});

export const {setEvents} = eventsSlice.actions;

/**
 * Registers socket event handlers to update Events
 * @param dispatch
 * @param socket
 */
export const registerEventsEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('eventsUpdate', (eventUpdate: {solutionId: string; eventTime: string}) => {
		// TODO: Use eventTime in case we only want to fetch latest
		// event(s) that were just inserted.
		dispatch(getSolutionEventsAsync(eventUpdate.solutionId));
	});
};

// Get Initial list of event.
export const getSolutionEventsAsync =
	(solutionId: string): AppThunk =>
	(dispatch) => {
		GET<IEventResponse>(`/api/engineering/events/${solutionId}`)
			.then((response) => {
				dispatch(setEvents(response));
			})
			.catch((error) => {
				console.error('Unable to load events', solutionId, error);
				dispatch(enqueueError('unable_to_load_events', error));
			});
	};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEventsRoot = (state: RootState) => state.engineering.events;

/* Helper input selectors to make code more readable when passing arguments to selectors
  See links. Note that order / number of arguments passed to selector in Component side, must 
  match to input selectors arguments. 
  https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
  https://github.com/reduxjs/reselect/issues/427#issuecomment-539705481
  https://flufd.github.io/reselect-with-multiple-parameters/
*/
const getSolutionId = (_state: RootState, solutionId: string) => solutionId;

export const selectEventsBySolutionId = createSelector([selectEventsRoot, getSolutionId], (events, solutionId): IEvent[] | undefined => events.events[solutionId]);

export default eventsSlice.reducer;
