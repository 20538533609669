import {createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {clearSolutionDataAction} from '../app/actions';
import {AppThunk, RootState} from '../app/store';
import {GET} from '../lib/httpClient';
import {transform} from '../lib/mvTransform';
import {enqueueError} from './appSlice';
import {ITransformRule} from './uiConfig.types';

export interface IParamStats {
	min: number;
	max: number;
	avg: number;
	unit: string;
	last: number;
}

export interface IModuleParameters {
	startTime: string; // Start time of the bucket, as set by the IoT edge device
	endTime: string; // End time of the bucket, calculated from time + bucketsize
	values: Record<
		string, // Param name
		IParamStats // Param stats (webBucket in wisecomm-module)
	>;
}

export interface ITransformedValue {
	value: number | boolean;
	title?: string; // Likely not needed as widget UI conf has title
	unit?: string;
}

// This is updated when "web bucket" completes in wisecomm module. Defaults to 30sec
export interface IMomentaryValues {
	/**
	 * Object Id of a solution
	 */
	solution: string;

	/**
	 * Generated GUID of a solution
	 */
	solutionId: string;

	// Module1.values.Param1.avg
	momentaryvalues: Record<string, IModuleParameters>;

	// Transformed values calculated when receiving data
	transformedValues?: Record<string, Record<string, ITransformedValue>>;
}

export interface ITransformedValues {
	solutionId: string;
	transformedValues: Record<string, boolean | number | undefined>;
}

interface IMomentaryValuesState {
	momentaryValues: {
		[key: string]: IMomentaryValues;
	};
	transformedValues: {
		[key: string]: any;
	};
	transformRules: {
		[key: string]: Record<string, ITransformRule> | undefined;
	};
}

const initialState: IMomentaryValuesState = {
	momentaryValues: {},
	transformedValues: {},
	transformRules: {},
};

export const momentaryValuesSlice = createSlice({
	name: 'momentaryvalues',
	initialState,
	reducers: {
		// Updates or adds momentary values
		setMomentaryValuesEntry: (state, action: PayloadAction<IMomentaryValues>) => {
			// N.B. don't try this without immer
			const solutionId = action.payload.solutionId;
			state.momentaryValues[solutionId] = action.payload;
		},

		// Set when solution config is loaded
		// Transform rules is applied whenever there is update
		// to momentary values. Hence they need to be accessible from
		// both the trading and engineering side.
		setTransformRules: (state, action: PayloadAction<any>) => {
			const id = action.payload.solutionId;
			state.transformRules[id] = action.payload.transformRules;
		},
	},

	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.momentaryValues[action.payload];
			delete state.transformRules[action.payload];
		});
	},
});

export const {setMomentaryValuesEntry, setTransformRules} = momentaryValuesSlice.actions;

export const getSolutionMomentaryValuesAsync =
	(solutions: string[]): AppThunk =>
	async (dispatch, getState) => {
		solutions.forEach((solutionId) => {
			GET<IMomentaryValues>(`/api/engineering/momentaryvalues/${solutionId}`)
				.then((response) => {
					const transformRules = getState().momentaryValues.transformRules[solutionId];

					if (transformRules) {
						let transformedValues = transform(transformRules, response);
						response.transformedValues = transformedValues;
					}
					dispatch(setMomentaryValuesEntry(response));
				})
				.catch((error) => {
					dispatch(enqueueError('unable_to_load_solution_momentary_values', error));
					console.error('Unable to load momentary values', solutionId, error);
				});
		});
	};

export const getSolutionMomentaryValuesAsTraderAsync =
	(solutions: string[]): AppThunk =>
	async (dispatch, getState) => {
		solutions.forEach((solutionId) => {
			GET<IMomentaryValues>(`/api/trading/momentaryvalues/${solutionId}`)
				.then((response) => {
					const transformRules = getState().momentaryValues.transformRules[solutionId];

					if (transformRules) {
						let transformedValues = transform(transformRules, response);
						response.transformedValues = transformedValues;
					}
					dispatch(setMomentaryValuesEntry(response));
				})
				.catch((error) => {
					dispatch(enqueueError('unable_to_load_solution_momentary_values', error));
					console.error('Unable to load momentary values', solutionId, error);
				});
		});
	};

// This is called when websocket receives an updated momentaryValues document
export const syncSolutionMomentaryValuesAsync =
	(momentaryValues: IMomentaryValues): AppThunk =>
	async (dispatch, getState) => {
		const transformRules = getState().momentaryValues.transformRules[momentaryValues.solutionId];
		if (transformRules) {
			let transformedValues = transform(transformRules, momentaryValues);
			momentaryValues.transformedValues = transformedValues;
		}
		dispatch(setMomentaryValuesEntry(momentaryValues));
	};

/**
 * Register Momentary values related socket event handlers
 * @param dispatch
 * @param socket
 */
export const registerMomentaryValuesEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('momentaryValuesUpdate', (momentaryValues: IMomentaryValues) => {
		dispatch(syncSolutionMomentaryValuesAsync(momentaryValues));
	});
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMomentaryValuesRoot = (state: RootState) => state.momentaryValues;

export const selectMomentaryValuesBySolutionId = (state: RootState, solutionId: string): IMomentaryValues | undefined => state.momentaryValues.momentaryValues[solutionId];

export const selectMomentaryValuesBySolutionIdAndModuleId = (state: RootState, solutionId: string, moduleId: string) => {
	const solutionMomentaryValues = state.momentaryValues.momentaryValues[solutionId];
	if (solutionMomentaryValues) {
		return solutionMomentaryValues.momentaryvalues[moduleId];
	}
};

export const selectTransformRulesBySolutionId = (state: RootState, solutionId: string) => state.momentaryValues.transformRules[solutionId];

export default momentaryValuesSlice.reducer;
