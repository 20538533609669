import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'src/app/store';
import {DELETE, GET, POST, PUT} from 'src/lib/httpClient';
import {enqueueError} from '../appSlice';

export interface IOrganizationListResponse {
	organizations: Array<IOrganization & {depth: number}>;
}
export interface IOrganization {
	name: string;
	groupId: string;
	isRootOrg: boolean;
	isPartner: boolean;
	parent: string | null;
	_id: string;
}

export interface IManagementOrganizationTreeItem {
	topNode: boolean | undefined;
	depth: number;
	root: boolean;
	name: string;
	id: string;
	parentId: string | null;
	children: IManagementOrganizationTreeItem[];
}

export interface IManagementOrganization {
	name: string;
	parentId: string;
}

interface IOrganizationTreeResponse {
	tree: IManagementOrganizationTreeItem;
	orphans: IManagementOrganizationTreeItem[] | undefined;
}

interface IOrganizationsState {
	loading: boolean;
	hasError: boolean;
	organizations: Array<IOrganization & {depth: number}>;
	organizationTree: IManagementOrganizationTreeItem | undefined;
	orphanOrganizations: IManagementOrganizationTreeItem[] | undefined;
}

const initialState: IOrganizationsState = {
	loading: false,
	hasError: false,
	organizations: [],
	organizationTree: undefined,
	orphanOrganizations: undefined,
};

export type UpdateOrganizationType = Partial<Pick<IOrganization, 'name' | 'parent'>>;
export type CreateOrganizationType = UpdateOrganizationType;
export type DeleteOrganizationType = UpdateOrganizationType;

export const organizationsSlice = createSlice({
	name: 'management/organizations',
	initialState,
	reducers: {
		setOrganizationsLoading: (state) => {
			state.loading = true;
		},
		setOrganizations: (state, action: PayloadAction<IOrganizationListResponse>) => {
			state.organizations = action.payload.organizations;
			state.loading = false;
			state.hasError = false;
		},
		getOrganizationsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		setOrganisationTree: (state, action: PayloadAction<IOrganizationTreeResponse>) => {
			state.organizationTree = action.payload.tree;
			state.orphanOrganizations = action.payload.orphans;
		},
		removeOrganization: (state, action: PayloadAction<string>) => {
			state.organizations = state.organizations.filter((org) => org._id !== action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {setOrganizationsLoading, removeOrganization, setOrganizations, getOrganizationsFail, setOrganisationTree} = organizationsSlice.actions;

export const getOrganizationsAsync = (): AppThunk => (dispatch) => {
	dispatch(setOrganizationsLoading());
	GET<IOrganizationListResponse>(`/api/management/organizations/`)
		.then((response) => {
			dispatch(setOrganizations(response));
		})
		.catch((error) => {
			dispatch(enqueueError('unable_to_load_organizations', error));
			dispatch(getOrganizationsFail());
		});
};

export const getOrganizationsTreeAsync = (): AppThunk => (dispatch) => {
	GET<IOrganizationTreeResponse>(`/api/management/organizations/tree`)
		.then((response) => {
			response.tree.topNode = true;
			dispatch(setOrganisationTree(response));
		})
		.catch((error: any) => {
			dispatch(enqueueError('unable_to_load_organizations', error));
			console.error('Unable to load organization list for management: ', error);
		});
};

export const updateOrganization =
	(organizationId: string, update: UpdateOrganizationType): AppThunk<Promise<IOrganization>> =>
	async (dispatch) => {
		try {
			const updatedOrganization = await PUT<IOrganization>(`/api/management/organizations/${organizationId}`, update);
			dispatch(getOrganizationsAsync());
			dispatch(getOrganizationsTreeAsync());
			return updatedOrganization;
		} catch (error: any) {
			dispatch(enqueueError('unable_to_update_organization', error));
			throw error;
		}
	};

export const createOrganization =
	(input: CreateOrganizationType): AppThunk<Promise<IOrganization>> =>
	async (dispatch) => {
		try {
			const newOrganization = await POST<IOrganization>(`/api/management/organizations/`, input);
			dispatch(getOrganizationsAsync());
			dispatch(getOrganizationsTreeAsync());
			return newOrganization;
		} catch (error: any) {
			if (error.errorCode === 403) {
				dispatch(enqueueError('organization_name_already_reserved', error));
			} else {
				dispatch(enqueueError('unable_to_create_organization', error));
			}
			throw error;
		}
	};

export const deleteOrganization =
	(organizationId: string): AppThunk<Promise<IOrganization>> =>
	async (dispatch) => {
		try {
			const oldOrganization = await DELETE<IOrganization>(`/api/management/organizations/${organizationId}`);
			dispatch(removeOrganization(organizationId));
			dispatch(getOrganizationsTreeAsync());
			return oldOrganization;
		} catch (error: any) {
			if (error.errorCode === 403) {
				dispatch(enqueueError('unable_to_delete_organization', error));
			} else {
				dispatch(enqueueError('deleting_organization_failed', error));
			}
			throw error;
		}
	};

export const selectOrganizationsRoot = (state: RootState) => state.management.organizations;

export const selectOrgsAsArray = createSelector([selectOrganizationsRoot], (orgs) => {
	if (orgs) {
		return Object.values(orgs.organizations);
	}
	return [];
});

export const selectOrganizationsLoading = (state: RootState) => state.management.organizations.loading;

// Returns the organizations for management purposes (in tree format in this case).
export const getOrganizationsTree = createSelector([selectOrganizationsRoot], (organizations): IManagementOrganizationTreeItem | undefined => {
	return organizations.organizationTree;
});
// Same as above but for orphaned organizations. These can be multiple.
export const getOrphanedOrganizationTrees = createSelector([selectOrganizationsRoot], (organizations): IManagementOrganizationTreeItem[] | undefined => {
	return organizations.orphanOrganizations;
});

export const selectOrganizationById = (state: RootState, organizationId: string): IManagementOrganizationTreeItem | null => {
	const searchRecursively = (node: IManagementOrganizationTreeItem, id: string): IManagementOrganizationTreeItem | null => {
		if (node.id === id) {
			return node;
		} else {
			for (const child of node.children) {
				const found = searchRecursively(child, id);
				if (found) {
					return found;
				}
			}
		}
		return null;
	};

	return state.management.organizations.organizationTree ? searchRecursively(state.management.organizations.organizationTree, organizationId) : null;
};

export const selectChildrenByOrganizationId = (state: RootState, organizationId: string): IManagementOrganizationTreeItem[] => {
	const targetOrganization = selectOrganizationById(state, organizationId);
	if (targetOrganization) {
		return targetOrganization.children;
	}
	return [];
};

export default organizationsSlice.reducer;
