// MSAL imports
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from '@azure/msal-browser';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {SnackbarProvider} from 'notistack';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import App, {Loading} from './App';
import {store} from './app/store';
import {AuthError, msalConfig, tokenRequest} from './authConfig';
import './i18n';
import {setAccessTokenCb} from './lib/httpClient';
import {setSessionStartTime} from './reducers/appSlice';
import * as serviceWorker from './serviceWorker';

export const muiCache = createCache({
	key: 'mui',
	prepend: true,
});

export const msalInstance = new PublicClientApplication(msalConfig);

// This method will get called before every request to backend server
setAccessTokenCb(async () => {
	const account = msalInstance.getActiveAccount();
	if (!account) {
		throw new AuthError('User has not logged in yet!');
	}
	try {
		// Retrieve a non-expired access token from cache silently.
		const {accessToken} = await msalInstance.acquireTokenSilent({
			account: account,
			scopes: tokenRequest.scopes,
		});
		if (!accessToken) {
			throw new AuthError('Unable to acquire token!');
		}
		return accessToken;
	} catch (error) {
		// in case if silent token acquisition fails, fallback to an interactive method
		/*
		 https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
		 "More often, failures are due to the refresh token's 24-hour lifetime expiring 
		  and the browser blocking 3rd party cookies, which prevents the use of hidden iframes
		  to continue authenticating the user"
		*/
		const {accessToken} = await msalInstance.acquireTokenPopup({
			scopes: tokenRequest.scopes,
		});
		if (!accessToken) {
			throw new AuthError('Unable to acquire token in popup!');
		}
		return accessToken;
	}
});

msalInstance.addEventCallback(async (event: EventMessage) => {
	//console.log('####### EVENT', event);

	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		// Active account will persist even if user refreshes the page.
		msalInstance.setActiveAccount(account);
		store.dispatch(setSessionStartTime());
	}
});

const container = document.getElementById('root');
if (!container) {
	throw new Error('Root container not found');
}
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<CacheProvider value={muiCache}>
			<HelmetProvider>
				<Provider store={store}>
					<SnackbarProvider maxSnack={3}>
						<Suspense fallback={<Loading />}>
							<App msalPublicClientApplication={msalInstance} />
						</Suspense>
					</SnackbarProvider>
				</Provider>
			</HelmetProvider>
		</CacheProvider>
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
