import {Breadcrumbs, Link, createTheme, ThemeProvider} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Theme} from '@mui/material/styles';
import {mwToolbarPrimary, mwToolbarSecondary} from '../palette';
import {appBreadcrumbs} from '../reducers/appSlice';

const MenuBreadcrumbs = () => {
	const theme: Theme = createTheme({
		palette: {
			primary: mwToolbarPrimary,
			secondary: mwToolbarSecondary,
		},
		typography: {
			subtitle1: {
				fontFamily: 'Open Sans',
				fontSize: '1rem',
				fontWeight: 700,
				fontColor: 'white',
			},
		},
	});

	const navigate = useNavigate();

	const breadCrumbs = useSelector(appBreadcrumbs);

	return (
		<ThemeProvider theme={theme}>
			<Breadcrumbs aria-label="breadcrumb" color="secondary">
				{breadCrumbs.map((breadcrumb) => {
					return breadcrumb.path ? (
						<Link
							key={breadcrumb.title}
							variant="subtitle1"
							underline="hover"
							style={{cursor: 'pointer'}}
							color="secondary"
							onClick={() => {
								if (breadcrumb.path) {
									navigate(breadcrumb.path);
								}
							}}
						>
							{breadcrumb.title}
						</Link>
					) : (
						<Link key={breadcrumb.title} variant="subtitle1" underline="none" color="secondary">
							{breadcrumb.title}
						</Link>
					);
				})}
			</Breadcrumbs>
		</ThemeProvider>
	);
};

export default MenuBreadcrumbs;
