// MSAL imports
import {IPublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {CssBaseline, LinearProgress, ThemeProvider} from '@mui/material';
import React, {Suspense} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import AuthorizedRoute from './AuthorizedRoute';
import {persistor} from './app/store';
import AppMenu from './components/AppMenu';
import AppNotifications from './components/AppNotifications';
import ErrorBoundary from './components/ErrorBoundary';
import {EngineeringUserLevel, ManagementUserLevel, TradingUserLevel, showDevContent} from './reducers/appSlice';
import * as themes from './theme';
import Login from './views/Login';
import RoleSelection from './views/RoleSelection';
import {SolutionLayout} from './views/SolutionLayout';
import ToolLayout from './views/ToolLayout';
import Admin from './views/engineering/Admin';
import Notifications from './views/engineering/Notifications';
import TradingAdmin from './views/trading/Admin';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';

export const Loading = () => <LinearProgress color="secondary" />;

const Management = React.lazy(() => import('./views/management/Management'));
const ManagementView = () => (
	<Suspense fallback={<Loading />}>
		<Management />
	</Suspense>
);

// Use magic comment webpackChunkName set nice name for chunk
// webpackPrefetch so that browser knows to load this chunk in advance as it is likely needed
const Engineering = React.lazy(() => import('./views/engineering/Engineering' /* webpackChunkName: "Engineering", webpackPrefetch: true */));
const EngineeringView = () => (
	<Suspense fallback={<Loading />}>
		<Engineering />
	</Suspense>
);

const Trading = React.lazy(() => import('./views/trading/Trading' /* webpackChunkName: "Trading", webpackPrefetch: true */));
const TradingView = () => (
	<Suspense fallback={<Loading />}>
		<Trading />
	</Suspense>
);

const EngineeringSideNav = React.lazy(() => import('./components/EngineeringSideNavigation' /* webpackChunkName: "EngineeringSideNavigation", webpackPrefetch: true */));
const EngineeringSideNavigation = () => (
	<Suspense fallback={<Loading />}>
		<EngineeringSideNav />
	</Suspense>
);

const SolutionStatus = React.lazy(() => import('./components/SolutionStatus' /* webpackChunkName: "SolutionStatus", webpackPrefetch: true */));
const SolutionStatusView = () => (
	<Suspense fallback={<Loading />}>
		<SolutionStatus />
	</Suspense>
);

const Battery = React.lazy(() => import('./components/widgets/Battery' /* webpackChunkName: "Battery" */));
const BatteryInformationView = () => (
	<Suspense fallback={<Loading />}>
		<Battery />
	</Suspense>
);

const ModuleList = React.lazy(() => import('./components/ModuleList' /* webpackChunkName: "ModuleList", webpackPrefetch: true */));
const ModuleListView = () => (
	<Suspense fallback={<Loading />}>
		<ModuleList showSolutionColumn={false} />
	</Suspense>
);

const MomentaryValues = React.lazy(() => import('./components/MomentaryValues' /* webpackChunkName: "MomentaryValues", webpackPrefetch: true */));
const MomentaryValuesView = () => (
	<Suspense fallback={<Loading />}>
		<MomentaryValues />
	</Suspense>
);

const Trends = React.lazy(() => import('./components/Trends' /* webpackChunkName: "Trends" */));
const TrendsView = () => (
	<Suspense fallback={<Loading />}>
		<Trends />
	</Suspense>
);

const TripsAndAlarmsList = React.lazy(() => import('./components/TripsAndAlarmsList' /* webpackChunkName: "TripsAndAlarmsList", webpackPrefetch: true */));
const TripsAndAlarmsListView = () => (
	<Suspense fallback={<Loading />}>
		<TripsAndAlarmsList />
	</Suspense>
);

const EventList = React.lazy(() => import('./components/EventList' /* webpackChunkName: "EventList", webpackPrefetch: true */));
const EventListView = () => (
	<Suspense fallback={<Loading />}>
		<EventList />
	</Suspense>
);

const SignalFileList = React.lazy(() => import('./components/SignalFileList' /* webpackChunkName: "SignalFileList", webpackPrefetch: true */));
const SignalFileListView = () => (
	<Suspense fallback={<Loading />}>
		<SignalFileList />
	</Suspense>
);

const SettableParametersList = React.lazy(() => import('./components/settableParameters/SettableParametersList' /* webpackChunkName: "SettableParametersList" */));
const SettableParametersListView = () => (
	<Suspense fallback={<Loading />}>
		<SettableParametersList />
	</Suspense>
);

const SolutionEditor = React.lazy(() => import('./components/SolutionEditor' /* webpackChunkName: "SolutionEditor" */));
const SolutionEditorView = () => (
	<Suspense fallback={<Loading />}>
		<SolutionEditor />
	</Suspense>
);

const TradingSideNav = React.lazy(() => import('./components/TradingSideNavigation' /* webpackChunkName: "TradingSideNavigation", webpackPrefetch: true */));
const TradingSideNavigation = () => (
	<Suspense fallback={<Loading />}>
		<TradingSideNav />
	</Suspense>
);

const TradingDetails = React.lazy(() => import('./views/trading/Details' /* webpackChunkName: "TradingDetails" */));
const TradingDetailsView = () => (
	<Suspense fallback={<Loading />}>
		<TradingDetails />
	</Suspense>
);

const Bidding = React.lazy(() => import('./views/trading/Bidding' /* webpackChunkName: "TradingBidding" */));
const BiddingView = () => (
	<Suspense fallback={<Loading />}>
		<Bidding />
	</Suspense>
);

const Trades = React.lazy(() => import('./views/trading/Trades' /* webpackChunkName: "TradingTrades" */));
const TradesView = () => (
	<Suspense fallback={<Loading />}>
		<Trades />
	</Suspense>
);

const BillingMaterials = React.lazy(() => import('./views/trading/Billing' /* webpackChunkName: "TradingBillingMaterial" */));
const BillingMaterialView = () => (
	<Suspense fallback={<Loading />}>
		<BillingMaterials />
	</Suspense>
);

const Reporting = React.lazy(() => import('./views/trading/Reporting' /* webpackChunkName: "TradingReporting" */));
const ReportingView = () => (
	<Suspense fallback={<Loading />}>
		<Reporting />
	</Suspense>
);

type AppProps = {
	msalPublicClientApplication: IPublicClientApplication;
};

function App({msalPublicClientApplication}: AppProps) {
	const devContentBoolean: boolean = useSelector(showDevContent);

	return (
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={devContentBoolean ? themes.darkTheme : themes.lightTheme}>
				<CssBaseline />
				<MsalProvider instance={msalPublicClientApplication}>
					{/* Use LocalizationProvider to define date library adapter for x-date-pickers */}
					<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
						<BrowserRouter>
							<ErrorBoundary>
								<AppNotifications />
								<Routes>
									<Route path="/" element={<Login />} />
									<Route path="login" element={<Login />} />

									{/* ToolLayout is shared by all views, and checks if user is authenticated */}
									<Route element={<ToolLayout topbar={<AppMenu />} />}>
										{/* Role selection, or redirect if user has only one role */}
										<Route path="roles" element={<RoleSelection />} />

										{/* All views in management require at least MANAGEMENT_ADMIN role  */}
										<Route path="management" element={<AuthorizedRoute requiredManagementUserLevel={ManagementUserLevel.ADMIN} />}>
											<Route index={true} element={<ManagementView />} />
										</Route>

										{/* All views in engineering require at least ENGINEERING_OBSERVER role  */}
										<Route path="engineering" element={<AuthorizedRoute requiredEngineeringUserLevel={EngineeringUserLevel.OBSERVER} />}>
											<Route index={true} element={<Navigate to={'solutions'} replace={true} />} />

											<Route path="admin" element={<AuthorizedRoute requiredEngineeringUserLevel={EngineeringUserLevel.ADMIN} />}>
												<Route index={true} element={<Admin />} />
											</Route>

											<Route path="notifications" element={<Notifications />} />

											<Route path="solutions" element={<EngineeringView />} />

											<Route element={<SolutionLayout sidebar={<EngineeringSideNavigation />} />}>
												<Route path="solutions/:solutionId" element={<Navigate to={'status'} replace={true} />} />
												<Route path="solutions/:solutionId/status" element={<SolutionStatusView />} />
												<Route path="solutions/:solutionId/battery" element={<BatteryInformationView />} />
												<Route path="solutions/:solutionId/devices" element={<ModuleListView />} />
												<Route path="solutions/:solutionId/momentaryvalues" element={<MomentaryValuesView />} />
												<Route path="solutions/:solutionId/trends" element={<TrendsView />} />
												<Route path="solutions/:solutionId/alarmsandtrips" element={<TripsAndAlarmsListView />} />
												<Route path="solutions/:solutionId/logs" element={<EventListView />} />
												<Route path="solutions/:solutionId/signals" element={<SignalFileListView />} />
												<Route path="solutions/:solutionId/parameters" element={<SettableParametersListView />} />
												<Route path="solutions/:solutionId/edit" element={<SolutionEditorView />} />
											</Route>
										</Route>

										{/* All views in trading require at least TRADING_OPERATOR role  */}
										<Route path="trading" element={<AuthorizedRoute requiredTradingUserLevel={TradingUserLevel.OPERATOR} />}>
											<Route index={true} element={<Navigate to={'solutions'} replace={true} />} />

											<Route path="admin" element={<AuthorizedRoute requiredTradingUserLevel={TradingUserLevel.ADMIN} />}>
												<Route index={true} element={<TradingAdmin />} />
											</Route>

											<Route path="solutions" element={<TradingView />} />

											<Route element={<SolutionLayout sidebar={<TradingSideNavigation />} />}>
												<Route path="solutions/:solutionId" element={<Navigate to={'status'} replace={true} />} />
												<Route path="solutions/:solutionId/status" element={<TradingDetailsView />} />
												<Route path="solutions/:solutionId/bidding" element={<BiddingView />} />
												<Route path="solutions/:solutionId/trades" element={<TradesView />} />
												<Route path="solutions/:solutionId/billing" element={<BillingMaterialView />} />
												<Route path="solutions/:solutionId/reporting" element={<ReportingView />} />
											</Route>
										</Route>
									</Route>
								</Routes>
							</ErrorBoundary>
						</BrowserRouter>
					</LocalizationProvider>
				</MsalProvider>
			</ThemeProvider>
		</PersistGate>
	);
}

export default App;
