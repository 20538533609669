import {useIsAuthenticated} from '@azure/msal-react';
import CircleIcon from '@mui/icons-material/Brightness1';
import {AppBar, createTheme, Stack, Theme, ThemeProvider, Toolbar, Typography} from '@mui/material';
import {green, red} from '@mui/material/colors';
import {useSelector} from 'react-redux';
import {mwToolbarPrimary, mwToolbarSecondary} from '../palette';
import {showDevContent, socketConnected} from '../reducers/appSlice';
import orgIconTransparent from '../res/merus-logo-transparent.png';
import AccountMenu from './AccountMenu';
import MenuBreadcrumbs from './MenuBreadcrumbs';
import BuildInfo from './BuildInfo';

const theme: Theme = createTheme({
	palette: {
		primary: mwToolbarPrimary,
		secondary: mwToolbarSecondary,
	},
});

type CounterProps = {};

const AppMenu = (_props: CounterProps) => {
	const isAuthenticated: boolean = useIsAuthenticated();
	const isConnected = useSelector(socketConnected);
	const devContentBoolean: boolean = useSelector(showDevContent);

	if (!isAuthenticated) return null;

	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static" color="primary">
				<Toolbar>
					<img style={{marginRight: 15}} alt="logo" src={orgIconTransparent} height="40px" />
					<div style={{paddingLeft: '55px'}}>
						<MenuBreadcrumbs />
					</div>
					{devContentBoolean && (
						<Stack sx={{ml: 3}} direction={'row'} spacing={3} alignItems={'center'}>
							<CircleIcon style={{fontSize: 10, color: isConnected ? green[500] : red[500]}} />
							<BuildInfo />
						</Stack>
					)}

					<Typography style={{flexGrow: 1}}></Typography>
					<AccountMenu />
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
};

export default AppMenu;
