import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {DELETE, GET, POST} from '../../lib/httpClient';
import {enqueueError, enqueueNotification} from '../appSlice';
import {getNotificationRulesAsync} from './notificationRulesSlice';

export interface INotificationChannel {
	_id: string;
	kind: 'email' | 'sms';
	verified: boolean;
	user: string;
	title: string;
	email: string;
	number: string;
}

export interface INotificationRule {
	_id: string;
	active: boolean;
	channels: INotificationChannel[];
	kind: string;
	notificationLifetime: number;
	solutionId: string;
	title: string;

	user: string;
	solution: string;
}

interface INotificationChannelMap {
	[key: string]: INotificationChannel;
}

interface notificationChannelsState {
	loading: boolean;
	hasError: boolean;
	notificationChannels: INotificationChannelMap;
}

const initialState: notificationChannelsState = {
	loading: false,
	hasError: false,
	notificationChannels: {},
};

export const notificationChannelsSlice = createSlice({
	name: 'engineering/notificationChannels',
	initialState,
	reducers: {
		getNotificationChannels: (state) => {
			state.loading = true;
		},
		getNotificationChannelsSuccess: (state, action: PayloadAction<any>) => {
			state.notificationChannels = action.payload;
			state.loading = false;
			state.hasError = false;
		},
		getNotificationChannelsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		updateNotificationCannel: (state, action: PayloadAction<INotificationChannel>) => {
			const channel = action.payload;
			state.notificationChannels[channel._id] = channel;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {getNotificationChannels, getNotificationChannelsSuccess, getNotificationChannelsFail, updateNotificationCannel} = notificationChannelsSlice.actions;

export const requestVerificationCode =
	(channelId: string): AppThunk =>
	async (dispatch) => {
		try {
			const channel = await POST<INotificationChannel>(`/api/engineering/notificationchannels/requestverificationcode/${channelId}`, {});
			// Requesting new verification code will mark channel as unverified
			dispatch(updateNotificationCannel(channel));
			dispatch(enqueueNotification('verification_code_sent', 'success'));
		} catch (error: any) {
			console.log(error);
			dispatch(enqueueError('verification_code_req_failed', error));
		}
	};

export const verifyChannel =
	(channelId: string, verificationCode: string): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		try {
			const channel = await POST<INotificationChannel>(`/api/engineering/notificationchannels/verify/${channelId}`, {
				verificationCode: verificationCode,
			});
			dispatch(updateNotificationCannel(channel));
			if (channel.verified) {
				dispatch(enqueueNotification('notification_channel_verified', 'success'));
			}
			return channel.verified;
		} catch (error: any) {
			console.log(error);
			// If verification code does not match dialog will show error.
			// App notification is only for cases where backend call itself fails.
			dispatch(enqueueError('notification_channel_verification_req_failed', error));
			return false;
		}
	};

export const createNotificationChannel =
	(newChannel: Partial<INotificationChannel>): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		try {
			const channel = await POST<INotificationChannel>(`/api/engineering/notificationchannels/`, newChannel);
			dispatch(updateNotificationCannel(channel));
			dispatch(enqueueNotification('notification_channel_created', 'success'));
			return true;
		} catch (error: any) {
			dispatch(enqueueError('notification_channel_create_req_failed', error));
			console.log(error);
			return false;
		}
	};

export const deleteNotificationChannel =
	(channelId: string): AppThunk =>
	async (dispatch) => {
		try {
			const remainingChannels = await DELETE<INotificationChannel[]>(`/api/engineering/notificationchannels/${channelId}`);
			if (remainingChannels) {
				let channels: INotificationChannelMap = {};
				for (const nc of remainingChannels) {
					channels[nc._id] = nc;
				}
				dispatch(getNotificationChannelsSuccess(channels));
				// Have to fetch rules again in case channel was in use
				dispatch(getNotificationRulesAsync());
			}
		} catch (error: any) {
			console.log(error);
			dispatch(enqueueError('notification_channel_delete_req_failed', error));
		}
	};

export const getNotificationChannelsAsync = (): AppThunk => async (dispatch) => {
	dispatch(getNotificationChannels());

	try {
		const notificationChannels = await GET<INotificationChannel[]>('/api/engineering/notificationchannels');
		if (notificationChannels) {
			let channels: INotificationChannelMap = {};
			for (const nc of notificationChannels) {
				channels[nc._id] = nc;
			}
			dispatch(getNotificationChannelsSuccess(channels));
		}
	} catch (error: any) {
		console.log(error);
		dispatch(getNotificationChannelsFail());
		dispatch(enqueueError('notification_channel_list_req_failed', error));
	}
};

export const SelectNotificationChannelsRoot = (state: RootState) => state.engineering.notificationChannels;

const getChannelIds = (_state: RootState, channelIds: string[]) => channelIds;

export const selectNotificationChannelsAsArray = createSelector([SelectNotificationChannelsRoot], (notificationChannels) => {
	if (notificationChannels) return Object.values(notificationChannels.notificationChannels);
	return [];
});

export const selectVerifiedNotificationChannelsAsArray = createSelector([SelectNotificationChannelsRoot], (notificationChannels) => {
	if (notificationChannels) return Object.values(notificationChannels.notificationChannels).filter((c) => c.verified);
	return [];
});

export const selectChannelTitlesByIds = createSelector([SelectNotificationChannelsRoot, getChannelIds], (notificationChannels, channelIds) => {
	return channelIds.map((channelId) => notificationChannels.notificationChannels[channelId]?.title);
});

export default notificationChannelsSlice.reducer;
