import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {Box, ButtonBase, Dialog, DialogTitle, LinearProgress, Paper, Stack, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'src/app/store';
import {IUserRoles, enqueueNotification, queryAndSetUserRoles, setBreadcrumbs, userRoles} from 'src/reducers/appSlice';

const RoleSelection = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const [loadingRoles, setLoadingRoles] = useState(false);
	const [showRoleSelection, setShowRoleSelection] = useState(false);

	const roles = useSelector(userRoles);

	const checkRolesAndRedirect = useCallback(
		(role: IUserRoles) => {
			const roleCount = (role.tradingUserLevel ? 1 : 0) + (role.engineeringUserLevel ? 1 : 0) + (role.managementUserLevel ? 1 : 0);

			if (roleCount > 1) {
				setShowRoleSelection(true);
			} else if (role.managementUserLevel) {
				navigate('/management', {replace: true});
			} else if (role.engineeringUserLevel) {
				navigate('/engineering', {replace: true});
			} else if (role.tradingUserLevel) {
				navigate('/trading', {replace: true});
			} else {
				// User has no roles at all. Configuration error
				dispatch(enqueueNotification('user_profile_configuration_issue', 'warning'));
			}
		},
		[navigate, dispatch],
	);

	// Role selection is a transient view if user has only one role.
	// If user has multiple roles then a selection must be done before proceeding.
	useEffect(() => {
		// When entering role selection, clear previous breadcrumbs
		dispatch(setBreadcrumbs([]));

		// Do not reload roles, if they are already set
		if (roles?.loaded === true) {
			checkRolesAndRedirect(roles);
		} else {
			setLoadingRoles(true);
			dispatch(queryAndSetUserRoles()).then((newRoles: IUserRoles | undefined) => {
				setLoadingRoles(false);
				// If request to load roles was successful, "roles" will
				// change and this hook will be executed again. This time roles are loaded.
				// If not, display unable to load roles message.
				if (newRoles === undefined) {
					dispatch(enqueueNotification('user_profile_unable_to_load', 'error'));
				}
			});
		}
	}, [dispatch, checkRolesAndRedirect, roles, setLoadingRoles]);

	return (
		<Box>
			{loadingRoles && (
				<Dialog onClose={() => {}} open={true}>
					<DialogTitle>{t('loading_user_profile')}</DialogTitle>
					<LinearProgress />
				</Dialog>
			)}

			{showRoleSelection && (
				<Stack sx={{mt: 2, ml: 2}} direction={'row'} spacing={3}>
					{!!roles.managementUserLevel && (
						<Paper>
							<ButtonBase
								sx={{width: 200, height: 200}}
								onClick={() => {
									navigate(`/management`);
								}}
							>
								<Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
									<ManageAccountsIcon sx={{width: 150, height: 150}} color="primary" />
									<Typography variant="h3">{t('selection_management')}</Typography>
								</Stack>
							</ButtonBase>
						</Paper>
					)}
					{!!roles.tradingUserLevel && (
						<Paper>
							<ButtonBase
								sx={{width: 200, height: 200}}
								onClick={() => {
									navigate(`/trading`);
								}}
							>
								<Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
									<MonetizationOnIcon sx={{width: 150, height: 150}} color="primary" />
									<Typography variant="h3">{t('selection_trading')}</Typography>
								</Stack>
							</ButtonBase>
						</Paper>
					)}
					{!!roles.engineeringUserLevel && (
						<Paper>
							<ButtonBase
								sx={{width: 200, height: 200}}
								onClick={() => {
									navigate(`/engineering`);
								}}
							>
								<Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
									<EngineeringIcon sx={{width: 150, height: 150}} color="primary" />
									<Typography variant="h3">{t('selection_engineering')}</Typography>
								</Stack>
							</ButtonBase>
						</Paper>
					)}
				</Stack>
			)}
		</Box>
	);
};

export default RoleSelection;
