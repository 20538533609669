import {Button, DialogActions, DialogContent, DialogContentText, Dialog} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface IConfirmDialogProps {
	dialogText: String;
	open: boolean;
	isDisabled?: boolean;
	handleClose: () => void;
	handleConfirmClick: () => void;
	children?: React.ReactNode;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
	const {t} = useTranslation();
	const {dialogText, open, isDisabled, handleClose, handleConfirmClick, children} = props;

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogContent>
				<DialogContentText>
					{t('confirm_dialog_text')} {dialogText}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button data-test="dialog-cancel" onClick={handleClose}>
					{t('cancel')}
				</Button>
				<Button data-test="dialog-confirm" onClick={handleConfirmClick} disabled={isDisabled}>
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
