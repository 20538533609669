import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

interface ISignalFilesState {
	signalFiles: {
		[key: string]: ISignalFile[];
	};
}

const initialState: ISignalFilesState = {
	signalFiles: {},
};

export interface ISignalFile {
	name: string;
	module?: string;
	trigger?: string;
	created?: string;
	size?: number;
}

export interface IParsedSignalFilesResponse {
	solutionId: string;
	files: ISignalFile[];
}

export interface ISignalFileResponse {
	name: string;
	created?: string;
	size?: number;
}

export interface ISignalFilesResponse {
	solutionId: string;
	files: ISignalFileResponse[];
}

export const signalFilesSlice = createSlice({
	name: 'engineering/signalFiles',
	initialState,
	reducers: {
		setSignalFiles: (state, action: PayloadAction<IParsedSignalFilesResponse>) => {
			const solutionId = action.payload.solutionId;
			state.signalFiles[solutionId] = action.payload.files;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.signalFiles[action.payload];
		});
	},
});

export const {setSignalFiles} = signalFilesSlice.actions;

export const parseFilename = (name: string) => {
	const parts = name.substr(0, name.length - 4).split('_');

	if (parts.length < 3) {
		throw new Error('Invalid filename');
	}
	const [dateStr, module, ...rest] = parts;

	const created = DateTime.fromFormat(dateStr, `yyyy-MM-dd'T'HHmmssSSS'Z'`, {
		zone: 'utc',
	});

	if (!created.isValid) {
		throw new Error('Unable to parse date!');
	}
	const trigger = rest.join('_');

	return {
		module,
		trigger,
		created: created.toISO(),
	};
};

export const parseSignalFileInfo = (response: ISignalFilesResponse): IParsedSignalFilesResponse => {
	const parsedFiles: ISignalFile[] = [];

	for (const f of response.files) {
		// Try to parse needed information from signal file name.
		// Fall back to using created data from storage, in case it fails.
		try {
			const info = parseFilename(f.name);
			parsedFiles.push({
				name: f.name,
				size: f.size,
				created: info.created,
				module: info.module,
				trigger: info.trigger,
			});
		} catch (error) {
			console.warn(f.name, error);
			parsedFiles.push({
				name: f.name,
				size: f.size,
				created: f.created, // use date when file was uploaded.
			});
		}
	}
	return {
		solutionId: response.solutionId,
		files: parsedFiles,
	};
};

export const getSolutionSignalFilesAsync =
	(solutionId: string): AppThunk =>
	(dispatch) => {
		GET<ISignalFilesResponse>(`/api/engineering/signals/${solutionId}`)
			.then((response) => {
				// Better to parse signal file names when they are fetched than
				// parse them every time they are needed.
				const parsedResponse = parseSignalFileInfo(response);
				dispatch(setSignalFiles(parsedResponse));
			})
			.catch((error) => {
				console.error('Unable to load signal files', solutionId, error);
				dispatch(enqueueError('unable_to_load_signal_files', error));
			});
	};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSignalFilesRoot = (state: RootState) => state.engineering.signals;

const getSolutionId = (_state: RootState, solutionId: string) => solutionId;
export const selectSignalFilesBySolutionId = createSelector([selectSignalFilesRoot, getSolutionId], (signals, solutionId: string): ISignalFile[] | undefined => signals.signalFiles[solutionId]);

export default signalFilesSlice.reducer;
