import {DateTime, DateTimeFormatOptions} from 'luxon';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * useDateTimeFormatter hook returns dateTimeFormatter function that can be used to format
 * dates as desired.
 */
export const useDateTimeFormatter = (locale: string, format: DateTimeFormatOptions, timezone?: string) => {
	const {t} = useTranslation();

	const dateTimeFormatter = useCallback(
		(dateStr?: string) => {
			if (dateStr) {
				const dateTime = DateTime.fromISO(dateStr);
				if (dateTime.isValid) {
					if (timezone === undefined) {
						return dateTime.toLocaleString(format, {
							locale: locale,
						});
					} else {
						return dateTime.setZone(timezone).toLocaleString(format, {
							locale: locale,
						});
					}
				} else {
					return t('not_available_short');
				}
			} else {
				return t('not_available_short');
			}
		},
		[t, locale, format, timezone],
	);
	return dateTimeFormatter;
};

export const shortDateTimeFormat: DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
export const shortDateTimeFormatWithSeconds: DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
