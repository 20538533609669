import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export interface ISetParametersResponse {
	solutionId: string;
	delivered: boolean;
	statusCode?: number;
	message?: string;
	errorMessage?: string;
}

interface ISetParametersRequestBody {
	modules: string[];
	parameters: Record<string, number>;
}

export interface ISettableParameter {
	title: string;
	pid: string;
	options?: {
		option: number;
		title: string;
	}[];
	unit?: string;
	min?: number;
	max?: number;
	group: string; // settableParameter group key
	decimals?: number; // If how many decimals, 0 or not set -> integer. More than 0 decimal number
}

export interface ISettableParameterConfiguration {
	id: string;
	groups: {
		id: string;
		displayName: string;
		saveCommand?: string;
		writable: boolean;
	}[];
	parameters: ISettableParameter[];
}

export interface ISettableParameterUIConfig {
	configurations: ISettableParameterConfiguration[];
	modules: Record<
		string,
		{
			configuration: string; // key in configurations object
			values?: number[];
		}
	>;
}

interface ISettableParamsResponse {
	solution: string; // solution._id
	solutionId: string;
	configuration: ISettableParameterUIConfig;
}

interface ISettableParamsState {
	params: {
		[key: string]: ISettableParameterUIConfig;
	};
}

const initialState: ISettableParamsState = {
	params: {},
};

export const settableParamsSlice = createSlice({
	name: 'engineering/settableParameters',
	initialState,
	reducers: {
		setSettableParameters: (state, action: PayloadAction<ISettableParamsResponse>) => {
			// N.B. don't try this without immer
			const solutionId = action.payload.solutionId;
			state.params[solutionId] = action.payload.configuration;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.params[action.payload];
		});
	},
});

export const {setSettableParameters} = settableParamsSlice.actions;

/**
 * Load list of parameters user can set
 * @param solutions
 * @returns
 */
export const getSolutionSettableParametersAsync =
	(solutions: String[]): AppThunk =>
	(dispatch) => {
		solutions.forEach((solutionId) => {
			GET<ISettableParamsResponse>(`/api/engineering/settableparameters/${solutionId}`)
				.then((response) => {
					dispatch(setSettableParameters(response));
				})
				.catch((error) => {
					dispatch(enqueueError('unable_to_load_settable_parameters', error));
					console.error('Unable to get settable parameters', solutionId, error);
				});
		});
	};

/**
 * Set new parameter value
 * @param solutionId
 * @param moduleIds
 * @param pid
 * @param value
 * @returns
 */
export const parameterWriteRequest = async (solutionId: String, moduleIds: string[], pid: string, value: number) => {
	try {
		const parameterObj: Record<string, number> = {};
		parameterObj[pid] = value;

		const body: ISetParametersRequestBody = {
			modules: moduleIds,
			parameters: parameterObj,
		};

		return await POST<ISetParametersResponse>(`/api/engineering/settableparameters/${solutionId}/set`, body);
	} catch (error) {
		console.error('Unable to send parameter write request.', error);
		return undefined;
	}
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSettableParametersRoot = (state: RootState) => state.engineering.settableParameters;

export const selectConfigurationGroupsBySolutionId = (state: RootState, solutionId: string) => {
	return state.engineering.settableParameters.params[solutionId] || {};
};

export default settableParamsSlice.reducer;
