import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST} from '../../lib/httpClient';
import {IModule} from './solutionsSlice';
import {enqueueError, enqueueNotification} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export interface IApplyFirmwareResponse {
	solutionId: string;
	delivered: boolean;
	statusCode?: number;
	payload?: {
		modules: {
			module: string;
			status: 'incorrect_state' | 'pending' | 'upload_failed' | 'upload_succeeded';
		}[];
		updateStatus: 'update_not_started' | 'update_failed' | 'update_succeeded_partially' | 'update_succeeded';
	};
}

interface IApplyFirmwareRequestBody {
	firmware: string;
	modules: string[];
}

interface IDeleteFirmwareRequestBody {
	firmware: string[];
}

interface IFirmwareResponse {
	solutionId: string;
	iotDevices: string[];

	// For convenience provide list of solutions modules, firmware and their state
	modules: Pick<IModule, 'moduleId' | 'displayName' | 'firmware' | 'state'>[];

	// Available firmware
	firmware: {
		name: string;
		uuid: string;
		modules: {
			moduleId: string;
			requiredStates: string[];

			// Note: we can show warning based on this in UI, but state can change during or after rest API call.
			// Check DM response
			stateOK: boolean;
		}[];
		iotDevices: string[];
	}[];
}

interface IFirmwareState {
	firmware: {
		[key: string]: IFirmwareResponse;
	};
}

const initialState: IFirmwareState = {
	firmware: {},
};

export const firmwareSlice = createSlice({
	name: 'engineering/firmware',
	initialState,
	reducers: {
		setFirmware: (state, action: PayloadAction<IFirmwareResponse>) => {
			// N.B. don't try this without immer
			const solutionId = action.payload.solutionId;
			state.firmware[solutionId] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.firmware[action.payload];
		});
	},
});

export const {setFirmware} = firmwareSlice.actions;

/**
 * Load list of firmware that can be applied by the user
 * @param solutions
 * @returns
 */
export const getSolutionFirmware =
	(solutionId: String): AppThunk<Promise<IFirmwareResponse | undefined>> =>
	async (dispatch) => {
		try {
			const response = await GET<IFirmwareResponse>(`/api/engineering/firmware/${solutionId}`);
			dispatch(setFirmware(response));
			return response;
		} catch (error) {
			dispatch(enqueueError('fw_load_failed', error));
			console.error('Unable to load firmware.', solutionId, error);
		}
	};

/**
 * Apply firmware to selected devices
 * @param solutionId Target solution
 * @param firmwareUUID Firmware UUID
 * @param moduleIds  Modules to apply to
 * @returns
 */
export const applyFirmware =
	(solutionId: String, firmwareUUID: string, moduleIds: string[]): AppThunk<Promise<IApplyFirmwareResponse | undefined>> =>
	async (dispatch) => {
		try {
			const body: IApplyFirmwareRequestBody = {
				firmware: firmwareUUID,
				modules: moduleIds,
			};
			return await POST<IApplyFirmwareResponse>(`/api/engineering/firmware/${solutionId}/apply`, body);
		} catch (error: any) {
			console.error('Unable to apply firmware.', error);
			if (error?.errorCode === 409) {
				dispatch(enqueueNotification('apply_firmware_failed_update_in_progress', 'error'));
			} else {
				dispatch(enqueueError('apply_firmware_failed', error));
			}
			return undefined;
		}
	};

/**
 * Remove firmware from solution
 * @param solutionId Target solution
 * @param firmwareUUID[] Firmware UUIDs
 * @returns
 */
export const removeFirmware =
	(solutionId: String, firmwareUUID: string[]): AppThunk<Promise<IFirmwareResponse | undefined>> =>
	async (dispatch) => {
		try {
			const body: IDeleteFirmwareRequestBody = {
				firmware: firmwareUUID,
			};
			const response = await POST<IFirmwareResponse>(`/api/engineering/firmware/${solutionId}/remove`, body);
			// Update firmware list to match after deletion
			dispatch(setFirmware(response));
			return;
		} catch (error: any) {
			if (error && error.errorCode === 504) {
				dispatch(enqueueNotification('notification_command_delivery_failed', 'error'));
			} else {
				dispatch(enqueueError('fw_remove_failed', error));
			}
			console.error('Unable to remove firmware.', error);
			return undefined;
		}
	};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFirmwareRoot = (state: RootState) => state.engineering.firmware;

export const selectFirmwareBySolutionId = (state: RootState, solutionId: string): IFirmwareResponse | undefined => {
	return state.engineering.firmware.firmware[solutionId];
};

export default firmwareSlice.reducer;
