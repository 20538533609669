import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {setupSocketConnectionAsync} from '../../lib/socketClient';
import {enqueueError, enqueueNotification} from '../appSlice';

export interface ISolution {
	solutionId: string; // UUID of a solution
	displayName: string; // Human readable name for solution
	location: string;
	maximumOutputPower: number;
	maximumEnergyCapacity: number;
}

interface SolutionState {
	loading: boolean;
	hasError: boolean;
	solutions: {
		[key: string]: ISolution;
	};
}

const initialState: SolutionState = {
	loading: false,
	hasError: false,
	solutions: {},
};

export const solutionsSlice = createSlice({
	name: 'trading/solutions',
	initialState,
	reducers: {
		setSolutionLoading: (state) => {
			state.loading = true;
		},
		getSolutionsSuccess: (state, action: PayloadAction<any>) => {
			state.solutions = action.payload;
			state.loading = false;
			state.hasError = false;
		},
		getSolutionsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		/* Backend doesn't know how to send trading-related data updates yet (and the fields aren't editable either)
		   so this is not needed yet
		updateSolution: (state, action: PayloadAction<ISolution>) => {
			const id = action.payload.solutionId;
			state.solutions[id] = action.payload;
		},
		*/
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

const {setSolutionLoading, getSolutionsSuccess, getSolutionsFail /*, updateSolution*/} = solutionsSlice.actions;

export const getSolutionsAsync = (): AppThunk => async (dispatch) => {
	dispatch(setSolutionLoading());

	try {
		const solutions = await GET<ISolution[]>('/api/trading/solutions');
		if (solutions) {
			const sol: any = {};

			for (const s of solutions) {
				sol[s.solutionId] = s;
			}

			dispatch(getSolutionsSuccess(sol));

			dispatch(setupSocketConnectionAsync())
				.then(() => {})
				.catch((err) => {
					console.error('Unable to setup socket connection!', err);
				});
		}
	} catch (error: any) {
		dispatch(getSolutionsFail());
		dispatch(enqueueError('unable_to_load_solutions', error));
	}
};

// This is called when websocket receives an updated solution document
/*
export const syncSolutionAsync =
	(updatedSolution: any): AppThunk =>
	async (dispatch) => {
		dispatch(updateSolution(updatedSolution));
	};
*/

export const selectSolutionsRoot = (state: RootState) => state.trading.solutions;

export const selectSolutionsLoading = (state: RootState) => state.trading.solutions.loading;

export const selectSolutionAsArray = createSelector([selectSolutionsRoot], (solutions) => {
	if (solutions) {
		return Object.values(solutions.solutions);
	}
	return [];
});

const getSolutionId = (_state: RootState, solutionId: string) => solutionId;
export const selectSolutionById = createSelector([selectSolutionsRoot, getSolutionId], (solutions, solutionId: string) => solutions.solutions[solutionId]);

export default solutionsSlice.reducer;
