import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthError} from 'src/authConfig';
import {AppThunk, RootState} from '../app/store';
import {GET, NetworkError, RestApiError, UnderMaintenanceError} from '../lib/httpClient';

export type AppNotificationVariant = 'default' | 'error' | 'success' | 'warning' | 'info';

export enum AppNotificationAction {
	Reload = 'reload',
}
export interface IAppNotification {
	/**
	 * Notification key
	 */
	key: string;

	/**
	 * Variant determines color of the notification.
	 */
	variant: AppNotificationVariant;

	/**
	 * Message shown in the notification.
	 */
	message: string;

	/**
	 * If message should be translated.
	 */
	translateMessage: boolean;

	/**
	 * Optional error description.
	 **/
	errorDescription?: string;

	/**
	 * If error description should be translated.
	 */
	translateErrorDescription?: boolean;

	/**
	 * Optional action. Only fixed set of actions can be supported as
	 * notifications are stored in redux store.
	 */
	notificationAction?: {
		type: AppNotificationAction;
	};
}

export interface IBreadcrumbItem {
	title: string;
	path?: string;
}

// These "user levels" are frontend-only; backend uses explicit role names to determine if something is possible or not

export enum ManagementUserLevel {
	ADMIN = 3,
	//OPERATOR = 2,
	//OBSERVER = 1,
	NONE = 0,
}

export enum TradingUserLevel {
	ADMIN = 3,
	OPERATOR = 2,
	//OBSERVER = 1,
	NONE = 0,
}

export enum EngineeringUserLevel {
	ADMIN = 3,
	OPERATOR = 2,
	OBSERVER = 1,
	NONE = 0,
}

/**
 * User's roles
 *
 * Note!
 * Use double exclamation mark to convert role level (number) to boolean, if used with short circuit syntax "&&"
 * E.g. {!!role.trader && <TradingComponent/> } otherwise React will render number 0, instead of omitting it entirely.
 */
export interface IUserRoles {
	developer: boolean;
	managementUserLevel: ManagementUserLevel;
	tradingUserLevel: TradingUserLevel;
	engineeringUserLevel: EngineeringUserLevel;
	loaded: boolean;
}

const emptyRoles: IUserRoles = {
	developer: false,
	managementUserLevel: ManagementUserLevel.NONE,
	tradingUserLevel: TradingUserLevel.NONE,
	engineeringUserLevel: EngineeringUserLevel.NONE,
	loaded: false,
};

export interface ISolutionAndRole {
	solutionId: string;
	role: 'trading' | 'engineering';
}

// General app level settings/selection that we wish to persist over page reloads
interface appState {
	loading: boolean;
	notifications: IAppNotification[];
	showDevContent: boolean;
	userRoles: IUserRoles;
	sessionStartTime: number;
	sessionLengthInSeconds: number;
	solutionToListenTo: ISolutionAndRole | undefined;
	socketConnected: boolean;
	solutionNavigationIndex: number;
	solutionNavigationWideMode: boolean;
	selectedDeviceId: string;
	breadcrumbs: IBreadcrumbItem[];
}

const initialState: appState = {
	loading: false,
	notifications: [],
	showDevContent: false,
	userRoles: emptyRoles,
	sessionStartTime: 0,
	sessionLengthInSeconds: 0,
	solutionToListenTo: undefined,
	socketConnected: false,
	solutionNavigationIndex: 0,
	solutionNavigationWideMode: true,
	selectedDeviceId: '',
	breadcrumbs: [],
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		addNotification: (state, action: PayloadAction<IAppNotification>) => {
			state.notifications.push(action.payload);
		},
		removeNotification: (state, action: PayloadAction<string>) => {
			state.notifications = state.notifications.filter((n) => n.key !== action.payload);
		},
		clearNotifications: (state) => {
			state.notifications = [];
		},
		toggleDevContent: (state) => {
			state.showDevContent = !state.showDevContent;
		},
		setUserRoles: (state, action: PayloadAction<IUserRoles>) => {
			state.userRoles = action.payload;
		},
		setSessionStartTime: (state) => {
			state.sessionStartTime = Math.round(Date.now() / 1000);
		},
		setsessionLengthInSeconds: (state) => {
			state.sessionLengthInSeconds = Math.round(Date.now() / 1000) - state.sessionStartTime;
		},
		setSolutionToListen: (state, action: PayloadAction<ISolutionAndRole | undefined>) => {
			state.solutionToListenTo = action.payload;
		},
		setSocketConnected: (state, action: PayloadAction<boolean>) => {
			state.socketConnected = action.payload;
		},
		setSolutionNavigationIndex: (state, action: PayloadAction<number>) => {
			state.solutionNavigationIndex = action.payload;
		},
		toggleSolutionNavigationWideMode: (state) => {
			state.solutionNavigationWideMode = !state.solutionNavigationWideMode;
		},
		setSelectedDeviceId: (state, action: PayloadAction<string>) => {
			state.selectedDeviceId = action.payload;
		},
		setBreadcrumbs: (state, action: PayloadAction<IBreadcrumbItem[]>) => {
			state.breadcrumbs = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {
	setSocketConnected,
	setSolutionToListen,
	startLoading,
	stopLoading,
	addNotification,
	removeNotification,
	clearNotifications,
	toggleDevContent,
	setUserRoles,
	setSessionStartTime,
	setsessionLengthInSeconds,
	setSolutionNavigationIndex,
	toggleSolutionNavigationWideMode,
	setSelectedDeviceId,
	setBreadcrumbs,
} = appSlice.actions;

export const appLoading = (state: RootState) => state.app.loading;

export const showDevContent = (state: RootState): boolean => state.app.showDevContent;

export const userRoles = (state: RootState): IUserRoles => {
	return state.app.userRoles;
};

export const sessionLengthInSeconds = (state: RootState): number => {
	return state.app.sessionLengthInSeconds;
};

export const socketConnected = (state: RootState): boolean => state.app.socketConnected;

export const queryAndSetUserRoles = (): AppThunk<Promise<IUserRoles | undefined>> => async (dispatch) => {
	try {
		const response = await GET<string[]>(`/api/user/roles`);

		let managementUserLevel = ManagementUserLevel.NONE;
		let engineeringUserLevel = EngineeringUserLevel.NONE;
		let tradingUserLevel = TradingUserLevel.NONE;

		if (response.includes('MANAGEMENT_ADMIN')) {
			managementUserLevel = ManagementUserLevel.ADMIN;
		}

		if (response.includes('ENGINEERING_ADMIN')) {
			engineeringUserLevel = EngineeringUserLevel.ADMIN;
		} else if (response.includes('ENGINEERING_OPERATOR')) {
			engineeringUserLevel = EngineeringUserLevel.OPERATOR;
		} else if (response.includes('ENGINEERING_OBSERVER')) {
			engineeringUserLevel = EngineeringUserLevel.OBSERVER;
		}

		if (response.includes('TRADING_ADMIN')) {
			tradingUserLevel = TradingUserLevel.ADMIN;
		} else if (response.includes('TRADING_OPERATOR')) {
			tradingUserLevel = TradingUserLevel.OPERATOR;
		} /* else if (response.includes('TRADING_OBSERVER')) {
			engineerUserLevel = TradingUserLevel.OBSERVER;
		}*/

		const roles: IUserRoles = {
			developer: response.includes('DEVELOPER'),
			managementUserLevel: managementUserLevel,
			tradingUserLevel: tradingUserLevel,
			engineeringUserLevel: engineeringUserLevel,
			loaded: true,
		};

		dispatch(setUserRoles(roles));
		return roles;
	} catch (error) {
		dispatch(enqueueError('unable_to_load_roles', error));
		console.error('Unable to load roles.', error);
	}
};

/**
 * Adds new notification to list of notifications
 * @param message Translation file key, or message to be displayed
 * @param variant
 * @param translate Set to false to display raw message instead of translation.
 * @returns
 */
export const enqueueNotification =
	(message: string, variant: AppNotificationVariant, translate: boolean = true): AppThunk<string> =>
	(dispatch) => {
		const key = new Date().getTime() + ':' + Math.round(Math.random() * 1000);
		dispatch(
			addNotification({
				key: key,
				message: message,
				variant: variant,
				translateMessage: translate,
			}),
		);
		return key;
	};

/**
 * Enqueues new error notification.
 * @param attemptedOperation Attempted operation. Translation file key. E.g. 'unable_to_load_solutions'
 * @param error Any error object
 * @returns
 */
export const enqueueError =
	(attemptedOperation: string, error: any | Error | RestApiError | NetworkError | AuthError | UnderMaintenanceError): AppThunk<string> =>
	(dispatch) => {
		let errorDescription = '';
		let translateDescription = true;
		let action: IAppNotification['notificationAction'] | undefined;

		if (error instanceof NetworkError) {
			errorDescription = 'network_error';
		} else if (error instanceof AuthError) {
			// Shouldn't happen
			errorDescription = 'auth_error';
		} else if (error instanceof UnderMaintenanceError) {
			errorDescription = 'under_maintenance_error';
			action = {
				type: AppNotificationAction.Reload,
			};
		} else if (error instanceof RestApiError) {
			// Use "api_error_" prefix to allow translation of known error messages
			errorDescription = 'api_error_' + error.message;
		} else {
			errorDescription = `${error.name} ${error.message}`;
			translateDescription = false;
		}

		const key = new Date().getTime() + ':' + Math.round(Math.random() * 1000);
		dispatch(
			addNotification({
				key: key,
				message: attemptedOperation,
				errorDescription: errorDescription,
				variant: 'error',
				translateMessage: true,
				translateErrorDescription: translateDescription,
				notificationAction: action,
			}),
		);
		return key;
	};

export const appNotifications = (state: RootState) => {
	return state.app.notifications;
};

export const solutionNavigationIndex = (state: RootState) => {
	return state.app.solutionNavigationIndex;
};

export const solutionNavigationWideMode = (state: RootState) => {
	return state.app.solutionNavigationWideMode;
};

export const selectedDeviceId = (state: RootState) => {
	return state.app.selectedDeviceId;
};

export const appBreadcrumbs = (state: RootState) => {
	return state.app.breadcrumbs;
};

export default appSlice.reducer;
