import {useEffect, useState} from 'react';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';

export interface ISelectableOrganization {
	name: string;
	id: string;
}

export interface OrganizationSelectorProps {
	value: string | undefined;
	onChange: (newValue: string) => void;
	error: boolean | undefined;
	helperText: string | false | undefined;
	isLoading: boolean;
	organizations?: ISelectableOrganization[];
	label?: string;
}

const OrganizationSelector = (props: OrganizationSelectorProps) => {
	// Initial selected value
	const [selectedOrgName, setSelectedOrgName] = useState<string>('');

	const handleChange = (e: SelectChangeEvent<string>) => {
		const orgName = e.target.value;
		// Get the first ID that matches the selected organization name.
		const optionID: string | undefined = props.organizations?.find((org: ISelectableOrganization) => org.name === orgName)?.id;
		// Call the onchange function with the ID of the selected organization.
		if (props.onChange !== null) {
			setSelectedOrgName(orgName);
			props.onChange(optionID ? optionID : '');
		}
	};

	useEffect(() => {
		// Fetch organization's name with the ID
		const fetchOrgName = (id: string): string | undefined => {
			return props.organizations?.find((org: ISelectableOrganization) => org.id === id)?.name;
		};

		const initialValue: string | undefined = props.value ? fetchOrgName(props.value) : '';
		setSelectedOrgName(initialValue ? initialValue : '');
	}, [props.organizations, props.value, selectedOrgName]);

	return props.isLoading ? (
		<Box>Loading...</Box>
	) : (
		<Box sx={{}}>
			<FormControl sx={{minWidth: '180px'}} error={props.error}>
				<InputLabel id="organization-selector-label">{props.label}</InputLabel>
				<Select
					sx={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					label={props.label}
					value={selectedOrgName}
					onChange={handleChange}
					error={props.error}
				>
					{props.organizations?.map((orgOption: ISelectableOrganization) => {
						return (
							<MenuItem key={`id-${orgOption.id}`} value={orgOption.name}>
								{orgOption.name}
							</MenuItem>
						);
					})}
				</Select>
				{props.helperText && typeof props.helperText === 'string' && <FormHelperText>{props.helperText}</FormHelperText>}
			</FormControl>
		</Box>
	);
};

export default OrganizationSelector;
