import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import CircleIcon from '@mui/icons-material/Brightness1';
import {Button, Container, Typography, alpha} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {persistor} from 'src/app/store';
import {makeStyles} from 'tss-react/mui';
import {loginRequest} from '../authConfig';
import BackgroundImage from '../res/S2226998.jpg';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			flexGrow: 1,
		},
		viewport: {
			display: 'flex',
			minWidth: '100vw',
			minHeight: '100vh',
			backgroundImage: `url(${BackgroundImage})`,
			backgroundSize: 'cover',
			backgroundPosition: 'right',
		},
		container: {
			backgroundColor: alpha('#104b86', 0.5),
			marginTop: 'max(6vh, 40px)',
			[theme.breakpoints.up('md')]: {
				maxWidth: '50vw',
				marginLeft: 'max(4vw, 40px)',
				padding: '3vh 2vw',
				height: 'fit-content',
				verticalAlign: 'middle',
			},
			[theme.breakpoints.down('md')]: {
				width: '100%',
				padding: '3vh 2vw',
				height: '100%',
			},
		},
		containerHeader: {
			color: '#ffffff',
			fontWeight: 'bold',
			display: 'inline',
			lineHeight: '1',
		},
		containerText: {
			color: '#ffffff',
			display: 'inline-block',
			margin: '14px 0px',
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'left',
			paddingTop: '7rem',
		},
		bigButton: {
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(2),
			},
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(1),
			},
			lineHeight: '2.75',
			marginRight: '25px',
			display: 'inline',
		},
	};
});

const Login = () => {
	const {t} = useTranslation();
	const {instance} = useMsal();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [urlSearchParams] = useSearchParams();
	const isAuthenticated: boolean = useIsAuthenticated();

	// Use search params to create transition state that clears app data.
	// Callback from logout contains logout=true
	useEffect(() => {
		const logout = urlSearchParams.get('logout');

		if (logout === 'true') {
			// Flush any pending operations, to ensure they're written to disk
			persistor.flush().then(() => {
				// Purge the state currently on disk
				persistor.purge().then(() => {
					// Navigate back to plain login page to get clean login url
					navigate('/login');
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Once user has logged in, navigate to role selection page.
	// Role selection page menu has option to log out, so user can do that
	// even if backend is unreachable.
	useEffect(() => {
		if (isAuthenticated) {
			navigate('/roles', {replace: true});
		}
	}, [isAuthenticated, navigate]);

	return (
		<div className={classes.viewport}>
			<Container className={classes.container} maxWidth={false}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<Typography className={classes.containerHeader} variant="h2">
						{t('merus')}
					</Typography>
					<Typography className={classes.containerHeader} variant="h1" style={{lineHeight: '1'}}>
						{t('meruscope')}
					</Typography>
				</div>
				<Typography className={classes.containerHeader} variant="h4">
					{t('landing_slogan')}
				</Typography>
				<CircleIcon color="secondary" style={{margin: 'auto 5px', fontSize: 13, display: 'inline'}} />
				<div className={classes.buttonContainer}>
					<Button className={classes.bigButton} variant="contained" color="secondary" href="https://meruspower.com/services/merus-meruscope/" target="_blank">
						{t('landing_read_more')}
					</Button>
					<Button
						data-test="login-button"
						className={classes.bigButton}
						variant="contained"
						color="secondary"
						onClick={() => {
							instance.loginRedirect(loginRequest).catch((e) => {
								console.error(e);
							});
						}}
					>
						{t('landing_login')}
					</Button>
				</div>
			</Container>
		</div>
	);
};

export default Login;
