import {io, Socket} from 'socket.io-client';
import {registerBidEventHandlers} from 'src/reducers/trading/bidsSlice';
import {AppThunk} from '../app/store';
import {ISolutionAndRole, setSocketConnected, setSolutionToListen} from '../reducers/appSlice';
import {registerTripsAlarmsEventHandlers} from '../reducers/engineering/alarmsSlice';
import {registerEventsEventHandlers} from '../reducers/engineering/eventsSlice';
import {registerSolutionEventHandlers} from '../reducers/engineering/solutionsSlice';
import {registerMomentaryValuesEventHandlers} from '../reducers/momentaryValuesSlice';
import {isDevEnv} from './envUtils';
import {accessToken} from './httpClient';
import {registerTradeEventHandlers} from 'src/reducers/trading/tradesSlice';

let socket: Socket;

// Bypass react dev sever in dev env
const socketEndpoint = isDevEnv ? 'http://localhost:3001' : '/';

export const setupSocketConnectionAsync = (): AppThunk<Promise<void>> => async (dispatch) => {
	// Socket setup already done
	if (socket) {
		return;
	}
	socket = io(socketEndpoint, {
		transports: ['polling', 'websocket'],
		path: '/api/socket',
		extraHeaders: {
			Authorization: 'Bearer ' + (await accessToken()),
		},
	});

	socket.on('connect', () => {
		dispatch(setSocketConnected(true));
		dispatch(startListening());
	});

	socket.on('disconnect', () => {
		dispatch(setSocketConnected(false));
	});

	registerSolutionEventHandlers(dispatch, socket);

	registerMomentaryValuesEventHandlers(dispatch, socket);

	registerTripsAlarmsEventHandlers(dispatch, socket);

	registerEventsEventHandlers(dispatch, socket);

	registerBidEventHandlers(dispatch, socket);

	registerTradeEventHandlers(dispatch, socket);
};

export const disconnectSocket = (): AppThunk => (dispatch) => {
	if (socket) {
		socket.disconnect();
	}
	dispatch(setSocketConnected(false));
};

/**
 * Sets solution's id in store and starts listening to changes.
 * Storing solution ID to store enables socket connection to resume listening to changes,
 * when socket is reconnected.
 * @param solutionAndRole
 * @returns
 */
export const setSolutionAndStartListening =
	(solutionAndRole: ISolutionAndRole): AppThunk =>
	(dispatch) => {
		dispatch(setSolutionToListen(solutionAndRole));
		dispatch(startListening());
	};

export const unsetSolutionAndStopListening = (): AppThunk => (dispatch) => {
	dispatch(setSolutionToListen(undefined));
	dispatch(startListening());
	if (socket && socket.connected) {
		socket.emit('stopListeningSolution');
	}
};

export const startListening = (): AppThunk => (_dispatch, getState) => {
	const state = getState();
	const solutionAndRole = state.app.solutionToListenTo;
	if (socket && socket.connected && solutionAndRole) {
		socket.emit('startListeningSolution', solutionAndRole);
	}
};
