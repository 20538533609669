import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {clearSolutionDataAction} from '../../app/actions';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {setTransformRules} from '../momentaryValuesSlice';
import {IEngineeringUIConfig, TransformRules} from '../uiConfig.types';

export interface IEngineeringSolutionConfigResponse {
	solution: string;
	solutionId: string;
	uiConfig: IEngineeringUIConfig;
	transformRules: TransformRules;
}

interface UIConfigState {
	uiConfig: {
		[key: string]: IEngineeringUIConfig | undefined;
	};
}

const initialState: UIConfigState = {
	uiConfig: {},
};

export const uiConfigSlice = createSlice({
	name: 'engineering/uiConfig',
	initialState,
	reducers: {
		setUIConfig: (state, action: PayloadAction<IEngineeringSolutionConfigResponse>) => {
			const id = action.payload.solutionId;
			state.uiConfig[id] = action.payload.uiConfig;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.uiConfig[action.payload];
		});
	},
});

export const {setUIConfig} = uiConfigSlice.actions;

/**
 * Loads Engineering UI Configuration
 * Transform rules are share between trading and engineering.
 * @param solutionId
 * @returns Engineering UI config + transform rules
 */
export const getSolutionConfigAsync =
	(solutionId: string): AppThunk<Promise<IEngineeringSolutionConfigResponse | undefined>> =>
	async (dispatch) => {
		try {
			const response = await GET<IEngineeringSolutionConfigResponse>(`/api/engineering/solutionconfig/${solutionId}`);
			dispatch(setUIConfig(response));
			dispatch(setTransformRules(response));

			return response;
		} catch (error) {
			dispatch(enqueueError('unable_to_load_solution_configuration', error));
			console.error('Unable to load Engineering solution configuration', solutionId, error);
		}
	};

export const selectUIConfigBySolutionId = (state: RootState, solutionId: string) => state.engineering.uiConfig.uiConfig[solutionId];

export default uiConfigSlice.reducer;
