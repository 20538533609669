import {useMemo} from 'react';
import {useMsal, useAccount} from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

const AccountInfo = () => {
	const {t} = useTranslation();
	const {accounts} = useMsal();
	const account = useAccount(accounts[0] || {});

	const accountInfo = useMemo(() => {
		let name: string = 'N/A';
		let email: string = 'N/A';

		if (account && account.name) {
			name = account.name.split(' ')[0];
			email = account.username;
		}

		return {name, email};
	}, [account]);

	return (
		<div>
			<Typography variant="subtitle2">
				{t('logged_as')}: {accountInfo.name}
			</Typography>
			<Typography variant="caption">{accountInfo.email}</Typography>
		</div>
	);
};

export default AccountInfo;
