import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {DateTime, Interval} from 'luxon';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IDeviceScheduleUpdate, ISchedule} from 'src/reducers/engineering/otaFilesSlice';
import {useDateTimeLimits} from './DateTimeLimits';

interface IEditScheduleProps {
	solutionName?: string;
	schedule: ISchedule;
	open: boolean;
	onClose: (newSchedule: IDeviceScheduleUpdate | undefined) => void;
}

const EditScheduleDialog = (props: IEditScheduleProps) => {
	const {open, onClose, schedule} = props;
	const {t} = useTranslation();
	const [desiredInstallTime, setDesiredInstallTime] = useState<DateTime | null>(schedule.desiredInstallTime === null ? DateTime.local().startOf('minute').plus({minutes: 5}) : DateTime.fromISO(schedule.desiredInstallTime));

	const {minDate, maxDate} = useDateTimeLimits();

	const handleClose = () => {
		if (desiredInstallTime && desiredInstallTime.isValid) {
			// desiredInstallTime is ensured to be valid
			const scheduledInstallTime = desiredInstallTime.toUTC().toISO()!;
			const newSchedule: IDeviceScheduleUpdate = {
				scheduleId: schedule._id!,
				scheduledInstallTime: scheduledInstallTime,
			};
			onClose(newSchedule);
		} else {
			onClose(undefined);
		}
	};

	const desiredInstallTimeValid = useMemo(() => {
		const interval = Interval.fromDateTimes(minDate, maxDate);
		return desiredInstallTime !== null && desiredInstallTime.isValid && interval.contains(desiredInstallTime);
	}, [desiredInstallTime, minDate, maxDate]);

	const handleCancel = () => {
		onClose(undefined);
	};

	return (
		<Dialog open={open}>
			<DialogTitle>{t('reschedule-iot-file')}</DialogTitle>
			<DialogContent>
				<Box
					component="form"
					sx={{
						width: 500,
						height: 200,
					}}
				>
					<Stack direction={'column'} spacing={3} sx={{m: 2}}>
						<MobileDateTimePicker
							slotProps={{
								textField: {
									variant: 'outlined',
									error: !desiredInstallTimeValid,
									helperText: desiredInstallTimeValid ? '' : t('desired_install_time_error'),
									inputProps: {
										'data-test': 'new_install_time',
									},
								},
							}}
							label={t('desired_install_time_select_label')}
							ampm={false}
							disableFuture={false}
							disablePast={true}
							minDate={minDate}
							maxDate={maxDate}
							value={desiredInstallTime}
							onChange={(date) => setDesiredInstallTime(date)}
							minutesStep={1}
							openTo="hours"
						/>
					</Stack>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button data-test="cancel_button" onClick={handleCancel}>
					{t('cancel')}
				</Button>
				<Button data-test="ok_button" disabled={!desiredInstallTimeValid} onClick={handleClose}>
					{t('ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditScheduleDialog;
