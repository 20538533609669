import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST} from '../../lib/httpClient';
import {enqueueNotification, enqueueError} from '../appSlice';
import {IOrganization} from './organizationSlice';

export interface IUser {
	id: string;
	email: string;
	displayName?: string;
	organization: IOrganization;
}

type Users = {[key: string]: IUser};

interface UsersState {
	loading: boolean;
	hasError: boolean;
	users: Users;
}

const initialState: UsersState = {
	loading: false,
	hasError: false,
	users: {},
};

interface IOrganizationSwitch {
	id: string;
	newOrgId: string;
}

export const userOrganizationSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUsersLoading: (state) => {
			state.loading = true;
		},
		getUsersSuccess: (state, action: PayloadAction<Users>) => {
			state.users = action.payload;
			state.loading = false;
			state.hasError = false;
		},
		getUsersFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		updateUsersOrganization: (state, action: PayloadAction<IUser>) => {
			// N.B. don't try this without immer
			const id = action.payload.id;
			state.users[id] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

const {setUsersLoading, getUsersSuccess, getUsersFail, updateUsersOrganization} = userOrganizationSlice.actions;

export const getUsersAsync = (): AppThunk => async (dispatch) => {
	dispatch(setUsersLoading());

	try {
		const users = await GET<IUser[]>('/api/management/users/');

		if (users) {
			const user: Users = {};

			for (const u of users) {
				user[u.id] = u;
			}
			dispatch(getUsersSuccess(user));
		}
	} catch (error: any) {
		dispatch(getUsersFail());
		dispatch(enqueueError('unable_to_load_users', error));
	}
};

export const selectUsersRoot = (state: RootState) => state.management.users;

export const selectUsersLoading = (state: RootState) => state.management.users.loading;

export const selectUsersAsArray = createSelector([selectUsersRoot], (users) => {
	if (users) {
		return Object.values(users.users);
	}
	return [];
});

export const updateUsersOrganizationAsync =
	({id, newOrgId}: IOrganizationSwitch): AppThunk =>
	async (dispatch) => {
		try {
			const updatedUser = await POST<IUser>('/api/management/users/user-org-switch', {id, newOrgId});
			dispatch(updateUsersOrganization(updatedUser));
			dispatch(enqueueNotification('user_updated', 'success'));
		} catch (error) {
			dispatch(enqueueError('unable_to_update_user', error));
		}
	};

export default userOrganizationSlice.reducer;
