import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {DELETE, GET, POST, PUT} from '../../lib/httpClient';
import {enqueueError, enqueueNotification} from '../appSlice';

export interface INotificationRule {
	_id: string;
	active: boolean;
	channels: string[];
	kind: 'alarm' | 'solution_state' | 'momentary_values';
	notificationLifetime: 30;
	solutionId: string;
	title: string;
	trigger: {
		alarmType?: 'trip' | 'alarm' | 'trip_or_alarm';
		leniency?: number;
		value?: 'avg' | 'min' | 'max' | 'last';
		comparison?: {
			type?: 'lessthan' | 'morethan' | 'outsideOf' | 'lessthanorequal' | 'greaterthanorequal';
			limit1?: number;
			limit2?: number;
		};
		paramName?: string;
		moduleId?: string;
	};

	user: string;
}

interface INotificationRuleMap {
	[key: string]: INotificationRule;
}

interface notificationRulesState {
	loading: boolean;
	hasError: boolean;
	notificationRules: INotificationRuleMap;
}

const initialState: notificationRulesState = {
	loading: false,
	hasError: false,
	notificationRules: {},
};

export const notificationRulesSlice = createSlice({
	name: 'engineering/notificationRules',
	initialState,
	reducers: {
		getNotificationRules: (state) => {
			state.loading = true;
		},
		getNotificationRulesSuccess: (state, action: PayloadAction<any>) => {
			state.notificationRules = action.payload;
			state.loading = false;
			state.hasError = false;
		},
		getNotificationRulesFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		setNotificationRule: (state, action: PayloadAction<INotificationRule>) => {
			const rule = action.payload;
			state.notificationRules[rule._id] = rule;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {getNotificationRules, getNotificationRulesSuccess, getNotificationRulesFail, setNotificationRule} = notificationRulesSlice.actions;

export const createNotificationRule =
	(newRule: Partial<INotificationRule>): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		try {
			const rule = await POST<INotificationRule>(`/api/engineering/rules`, newRule);
			dispatch(setNotificationRule(rule));
			dispatch(enqueueNotification('notification_rule_created', 'success'));
			return true;
		} catch (error: any) {
			dispatch(enqueueError('notification_rule_create_req_failed', error));
			console.log(error);
			return false;
		}
	};

export const updateNotificationRule =
	(ruleId: string, updatedRule: Partial<INotificationRule>): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		try {
			const rule = await PUT<INotificationRule>(`/api/engineering/rules/${ruleId}`, updatedRule);
			dispatch(setNotificationRule(rule));
			dispatch(enqueueNotification('notification_rule_updated', 'success'));
			return true;
		} catch (error: any) {
			dispatch(enqueueError('notification_rule_update_req_failed', error));
			console.log(error);
			return false;
		}
	};

export const deleteNotificationRule =
	(ruleId: string): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		try {
			// Delete returns list of remaining rules
			const notificationRules = await DELETE<INotificationRule[]>(`/api/engineering/rules/${ruleId}`);
			if (notificationRules) {
				let rules: INotificationRuleMap = {};

				for (const nr of notificationRules) {
					rules[nr._id] = nr;
				}
				dispatch(getNotificationRulesSuccess(rules));
			}
			dispatch(enqueueNotification('notification_rule_deleted', 'success'));
			return true;
		} catch (error: any) {
			dispatch(enqueueError('notification_rule_delete_req_failed', error));
			console.log(error);
			return false;
		}
	};

export const getNotificationRulesAsync = (): AppThunk => async (dispatch) => {
	dispatch(getNotificationRules());

	try {
		const notificationRules = await GET<INotificationRule[]>('/api/engineering/rules');
		if (notificationRules) {
			let notiRules: INotificationRuleMap = {};

			for (const nr of notificationRules) {
				notiRules[nr._id] = nr;
			}
			dispatch(getNotificationRulesSuccess(notiRules));
		}
	} catch (error) {
		console.log(error);
		dispatch(enqueueError('notification_rule_list_req_failed', error));
		dispatch(getNotificationRulesFail());
	}
};

export const SelectNotificationRulesRoot = (state: RootState) => state.engineering.notificationRules;

export const selectNotificationRulesAsArray = createSelector([SelectNotificationRulesRoot], (notificationRules) => {
	if (notificationRules) return Object.values(notificationRules.notificationRules);
	return [];
});

export default notificationRulesSlice.reducer;
