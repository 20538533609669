import {Box} from '@mui/material';
import {useEffect, useState} from 'react';

const BuildInfo = () => {
	const [buildInfo, setBuildInfo] = useState<string>('');

	useEffect(() => {
		// Extract build number from meta tag
		const scopeBuildMetaElement = document.head.querySelector(`meta[name='scope-build']`);
		const build = scopeBuildMetaElement?.getAttribute('content');

		if (!build || build === '{{scopeBuild}}') {
			setBuildInfo('N/A');
		} else {
			setBuildInfo(build);
		}
		// Disabling lint, run only onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Box sx={{maxWidth: '10ch', overflow: 'hidden', textOverflow: 'ellipsis'}}>{buildInfo}</Box>;
};

export default BuildInfo;
