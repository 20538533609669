import {createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {DateTime, Interval} from 'luxon';
import {Socket} from 'socket.io-client';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST, PUT} from '../../lib/httpClient';
import {AvailableMarkets} from '../../lib/reserveMarketConstants';
import {enqueueError, enqueueNotification} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export enum BidStatus {
	Unposted = 'unposted',
	Synchronizing = 'synchronizing',
	Removed = 'removed',
	Modified = 'modified',
	InSync = 'in_sync',
}

/**
 * Capacity and time related rules of a market.
 * This pseudo generic format allows sharing market business rules with frontend.
 */
export interface IReserveMarketBusinessRule {
	/**
	 * Market these business rules apply to
	 */
	market: AvailableMarkets;

	/**
	 * Timezone of trading day. In FCR market trading day is one day in CET time zone.
	 */
	timezone: string;

	/**
	 * Relative to bid start time.
	 * When user can start bidding. In FCR market this is 31 days before bid start time.
	 * Defined duration is subtracted from bid start time.
	 * ISO-8601 Duration "P31D"
	 */
	gateOpens: string;

	/**
	 * Gate closure is relative to trading day.
	 * FCR Gate closes on previous trading day at 17:30 CET (18:30 EET)
	 * Defined duration is subtracted from start of trading day bid belongs to.
	 * ISO-8601 Duration "PT6H30M"
	 */
	gateCloses: string;

	/**
	 * Minium allowed bid capacity
	 */
	minCapacity: number;

	/**
	 * Maximum allowed bid capacity
	 */
	maxCapacity: number;

	/**
	 * Decimal places defines smallest change supported by the market.
	 * 1 dp =>  0.1 MW
	 */
	decimalPlaces: number;
}

interface IReserveMarketBusinessRuleResponse {
	reserveMarketBusinessRules: IReserveMarketBusinessRule[];
}

export enum RequestStatus {
	New = 'new',
	Sent = 'sent',
	Completed = 'completed',
	Canceled = 'canceled',
	DeliveryFailed = 'delivery_failed',
	ValidationFailed = 'validation_failed',
}
export interface IBid {
	_id: string;
	solution: string;
	solutionId: string;
	status: BidStatus;
	startTime: string; // ISO string
	endTime: string; // ISO string
	market: AvailableMarkets;
	confirmedBid:
		| {
				/** capacity (MW) */
				capacity: number;

				/** Price (€/MW,h) */
				price: number;
		  }
		| undefined;
	requests: ISyncRequest[];
}

export interface ISyncRequest {
	_id: string;
	sentAt?: string; // ISO string
	createdAt: string;
	updatedAt: string;
	requestStatus: RequestStatus;

	/** Offered capacity (MW) */
	capacity: number;

	/** Offered price (€/MW,h) */
	price: number;
}

export interface ICreateBidBody {
	market: AvailableMarkets;
	startTime: string; // ISO string
	duration: number; // 60
	price: number;
	capacity: number;
}

interface IBidResponse {
	bid: IBid;
}

interface IDeleteBidResponse {
	bid: IBid | undefined;
}

interface IBidsResponse {
	bids: IBid[];
}

interface IBidsAndInterval {
	interval: Interval;
	// Bids that take place selected time interval
	bids: IBid[] | undefined;
}

interface BidsState {
	marketBusinessRules: IReserveMarketBusinessRule[] | undefined;
	bids: {
		// Bids per solution
		[solutionId: string]: IBidsAndInterval;
	};
}

const initialState: BidsState = {
	marketBusinessRules: undefined,
	bids: {},
};

export const bidsSlice = createSlice({
	name: 'trading/bids',
	initialState,
	reducers: {
		setBidsAndInterval: (state, action: PayloadAction<{solutionId: string; interval: Interval; bids: IBid[]}>) => {
			const {solutionId, interval, bids} = action.payload;
			state.bids[solutionId] = {
				interval: interval,
				bids: bids,
			};
		},

		/**
		 * Replaces bid, if bid already exist in store.
		 * If bid does not exists, but start time is within selected interval,
		 * it will be added.
		 */
		replaceOrAddBid: (state, action: PayloadAction<IBid>) => {
			const newOrUpdatedBid = action.payload;
			const bidAndInterval = state.bids[newOrUpdatedBid.solutionId];

			if (bidAndInterval) {
				let {bids, interval} = bidAndInterval;

				if (bids !== undefined) {
					const index = bids.findIndex((bid) => bid._id === newOrUpdatedBid._id);
					if (index > -1) {
						// Handle soft deleted bids
						if (newOrUpdatedBid.status === BidStatus.Removed) {
							bids.splice(index, 1);
						} else {
							bids[index] = newOrUpdatedBid;
						}
					} else {
						if (interval.contains(DateTime.fromISO(newOrUpdatedBid.startTime))) {
							bids.push(newOrUpdatedBid);
						} else {
							console.log('BID DOES NOT BELONG TO SELECTED RANGE');
						}
					}
				} else {
					console.log('BID update received, but initial bid list is not yet loaded.');
				}
			}
		},

		removeBid: (state, action: PayloadAction<{solutionId: string; bidId: string}>) => {
			const {solutionId, bidId} = action.payload;
			const bidAndInterval = state.bids[solutionId];

			if (bidAndInterval) {
				let {bids} = bidAndInterval;

				if (bids !== undefined) {
					const index = bids.findIndex((bid) => bid._id === bidId);
					if (index > -1) {
						bids.splice(index, 1);
					}
				}
			}
		},

		setMarketBusinessRules: (state, action: PayloadAction<IReserveMarketBusinessRule[]>) => {
			state.marketBusinessRules = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.bids[action.payload];
		});
	},
});

const {setBidsAndInterval, replaceOrAddBid, removeBid, setMarketBusinessRules} = bidsSlice.actions;

/**
 * Load market business rules
 * @returns
 */
export const loadMarketBusinessRules = (): AppThunk<Promise<IReserveMarketBusinessRule[] | undefined>> => async (dispatch) => {
	try {
		const response = await GET<IReserveMarketBusinessRuleResponse>(`/api/trading/bids/reservemarketbusinessrules`);
		dispatch(setMarketBusinessRules(response.reserveMarketBusinessRules));
		return response.reserveMarketBusinessRules;
	} catch (error: any) {
		console.error('Unable to load market business rules', error);
		dispatch(enqueueError('bid_unable_to_load_business_rules', error));
	}
};

/**
 * Get Initial list of bids and inspection interval for the bids view
 * @param solutionId
 * @param interval
 * @returns
 */
export const loadBidsAndSetIntervalAsync =
	(solutionId: string, interval: Interval): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		const start = interval.start?.toUTC().toISO();
		const end = interval.end?.toUTC().toISO();
		try {
			const response = await GET<IBidsResponse>(`/api/trading/bids/${solutionId}?startTime=${start}&endTime=${end}`);
			dispatch(
				setBidsAndInterval({
					bids: response.bids,
					solutionId: solutionId,
					interval: interval,
				}),
			);
			return true;
		} catch (error: any) {
			console.error('Unable to load bids', solutionId, error);
			dispatch(enqueueError('bid_unable_to_load_bids', error));
			return false;
		}
	};

/**
 * Registers socket event handlers to update bids
 * @param dispatch
 * @param socket
 */
export const registerBidEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('bidUpdate', (bid: IBid) => {
		dispatch(replaceOrAddBid(bid));
	});
	socket.on('bidRemove', (removedBid: {solutionId: string; bidId: string}) => {
		dispatch(removeBid(removedBid));
	});
};

export const createBidAsync =
	(solutionId: string, market: AvailableMarkets, startTime: string, price: number, capacity: number): AppThunk<Promise<IBid | undefined>> =>
	async (dispatch) => {
		try {
			const response = await POST<IBidResponse>(`/api/trading/bids/${solutionId}`, {
				capacity: capacity,
				duration: 60,
				market: market,
				price: price,
				startTime: startTime,
			} as ICreateBidBody);
			dispatch(replaceOrAddBid(response.bid));
			return response.bid;
		} catch (error) {
			console.error('Unable to create bid', solutionId, error);
			dispatch(enqueueError('bid_unable_to_create_bid', error));
		}
	};

/**
 * Send request in "new" state. If request was sent successfully, request status will be set to "sent" and bid to "synchronizing"
 * If sending fails, there should not be any changes.
 * @param solutionId
 * @param bidId
 * @param requestId
 * @returns
 */
export const sendBidRequestAsync =
	(solutionId: string, bidId: string, requestId: string): AppThunk<Promise<IBid | undefined>> =>
	async (dispatch) => {
		try {
			const response = await POST<IBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/request/${requestId}/send`, {});
			if (response.bid.status !== BidStatus.Synchronizing) {
				dispatch(enqueueNotification('bid_sync_start_failed', 'error'));
			}
			dispatch(replaceOrAddBid(response.bid));
			return response.bid;
		} catch (error) {
			console.error('Unable to send request', solutionId, error);
			dispatch(enqueueError('bid_unable_to_start_sync', error));
		}
	};

/**
 * Delete bid. Deleting bid will set existing "new" request capacity to zero or
 * add "new" request with zero capacity. Bid is marked as removed only after it has been successfully synchronized to fingrid.
 * @param solutionId
 * @param bidId
 * @param requestId
 * @returns
 */
export const deleteBidAsync =
	(solutionId: string, bidId: string): AppThunk =>
	(dispatch) => {
		POST<IDeleteBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/delete`, {})
			.then((response) => {
				if (response.bid) {
					dispatch(replaceOrAddBid(response.bid));
				} else {
					dispatch(
						removeBid({
							solutionId: solutionId,
							bidId: bidId,
						}),
					);
				}
			})
			.catch((error) => {
				console.error('Unable to delete', solutionId, error);
				dispatch(enqueueError('bid_unable_to_remove', error));
			});
	};

/**
 * Updates price and capacity of a request in "new" state.
 * If request does not exist, use createBidRequestAsync to create one.
 * @param solutionId
 * @param bidId
 * @param requestId
 * @param price
 * @param capacity
 * @returns
 */
export const updateBidRequestAsync =
	(solutionId: string, bidId: string, requestId: string, price: number, capacity: number): AppThunk<Promise<IBid | undefined>> =>
	async (dispatch) => {
		try {
			const response = await PUT<IBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/request/${requestId}`, {
				price: price,
				capacity: capacity,
			});
			dispatch(replaceOrAddBid(response.bid));
			return response.bid;
		} catch (error) {
			console.error('Unable to update request', solutionId, error);
			dispatch(enqueueError('bid_unable_to_edit', error));
		}
	};

/**
 * Creates new request in "new" state. Should only be used, if there is no "new" request already.
 * @param solutionId
 * @param bidId
 * @param price
 * @param capacity
 * @returns
 */
export const createBidRequestAsync =
	(solutionId: string, bidId: string, price: number, capacity: number): AppThunk<Promise<IBid | undefined>> =>
	async (dispatch) => {
		try {
			const response = await POST<IBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/request/`, {
				price: price,
				capacity: capacity,
			});
			dispatch(replaceOrAddBid(response.bid));
			return response.bid;
		} catch (error) {
			console.error('Unable to create request', solutionId, error);
			dispatch(enqueueError('bid_unable_to_edit', error));
		}
	};

/**
 * Request in "sent" -state can be canceled.
 * @param solutionId
 * @param bidId
 * @param requestId
 * @returns
 */
export const cancelBidRequestAsync =
	(solutionId: string, bidId: string, requestId: string): AppThunk =>
	(dispatch) => {
		POST<IBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/request/${requestId}/cancel`, {})
			.then((response) => {
				dispatch(replaceOrAddBid(response.bid));
			})
			.catch((error) => {
				console.error('Unable to cancel request', solutionId, requestId, error);
				dispatch(enqueueError('bid_unable_to_cancel', error));
			});
	};

/**
 * Request in "new" -state can be discarded.
 * @param solutionId
 * @param bidId
 * @param requestId
 * @returns
 */
export const discardBidRequestAsync =
	(solutionId: string, bidId: string, requestId: string): AppThunk =>
	(dispatch) => {
		POST<IBidResponse>(`/api/trading/bids/${solutionId}/bid/${bidId}/request/${requestId}/discard`, {})
			.then((response) => {
				dispatch(replaceOrAddBid(response.bid));
			})
			.catch((error) => {
				console.error('Unable to discard request', solutionId, requestId, error);
				dispatch(enqueueError('bid_unable_to_discard', error));
			});
	};

export const selectBidsBySolutionId = (state: RootState, solutionId: string): IBidsAndInterval | undefined => state.trading.bids.bids[solutionId];

export const selectMarketBusinessRules = (state: RootState) => state.trading.bids.marketBusinessRules;

export default bidsSlice.reducer;
