import {LoadingButton} from '@mui/lab';
import {Box, Button, FormControlLabel, FormGroup, Stack, Switch, Theme, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {Formik} from 'formik';
import {DateTime} from 'luxon';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {downloadFile} from 'src/lib/httpClient';
import {makeStyles} from 'tss-react/mui';
import * as yup from 'yup';
import {clearSolutionDataAction} from '../app/actions';
import {RootState, useAppDispatch} from '../app/store';
import {DELETE} from '../lib/httpClient';
import {EngineeringUserLevel, IUserRoles, enqueueError, enqueueNotification, setSelectedDeviceId, showDevContent, userRoles} from '../reducers/appSlice';
import {getOrganizationsAsync, selectOrganizations, selectOrganizationsLoading} from '../reducers/engineering/organizationsSlice';
import {ISolution, addSolutionAsync, removeSolution, selectSolutionById, updateSolutionAsync} from '../reducers/engineering/solutionsSlice';
import ConfirmDialog from './ConfirmDialog';
import OrganizationSelector from './OrganizationSelector';

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			'& .MuiTextField-root': {
				margin: theme.spacing(1),
				width: 200,
			},
		},
	};
});

const SolutionEditor = () => {
	const {classes} = useStyles();
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const navigate = useNavigate();
	const {solutionId} = useParams();
	const roles: IUserRoles = useSelector(userRoles);
	const [showRemoveSolutionConfirmDialog, setShowRemoveSolutionConfirmDialog] = useState<boolean>(false);
	const [removeSolutionConfirmationText, setRemoveSolutionConfirmationText] = useState<string>('');
	const devContentBoolean: boolean = useSelector(showDevContent);

	const userIsAdmin: boolean = roles.engineeringUserLevel === EngineeringUserLevel.ADMIN;
	const userIsObserver: boolean = roles.engineeringUserLevel === EngineeringUserLevel.OBSERVER;

	const isLoading = useSelector(selectOrganizationsLoading);

	const organizations = useSelector((state: RootState) => selectOrganizations(state));

	useEffect(() => {
		userIsAdmin && dispatch(getOrganizationsAsync());
	}, [userIsAdmin, dispatch]);

	// This is a bit of a hack: indexing the solution collection with an empty key returns "undefined"
	let solution: ISolution | undefined = useSelector((state: RootState) => selectSolutionById(state, solutionId ? solutionId : ''));

	// Form initial values
	const initialValues = useMemo(() => {
		return {
			displayName: solution ? solution.displayName : '',
			location: solution ? solution.location : '',
			type: solution ? solution.type : '',
			organization: solution ? solution.organization._id : undefined,
			expirationDate: solution && solution.expirationDate ? new Date(solution.expirationDate) : undefined,
			redundancy: false,
		};
	}, [solution]);

	// Snackbar
	const handleShowSnackbar = (solutionId: string, displayName: string, displayText: string) => {
		enqueueSnackbar(t(displayText, {Solution: displayName}), {
			variant: 'success',
			action: (
				<>
					<Button color="inherit" size="small" onClick={() => handleSnackbarButtonClick(solutionId)}>
						{t('go_to_page', {page: displayName})}
					</Button>
					<Button color="inherit" size="small" onClick={() => closeSnackbar()}>
						{t('dismiss')}
					</Button>
				</>
			),
			autoHideDuration: 7000,
		});
	};

	const handleSnackbarButtonClick = (newSolutionId: string) => {
		dispatch(setSelectedDeviceId(''));
		closeSnackbar();
		navigate(`/engineering/solutions/${newSolutionId}/status`);
	};

	// Submit logic
	const onSave = async (obj: any) => {
		if (solution) {
			// Redundancy is only used when creating the solution.
			delete obj.redundancy;
			const updatedSolutionId = await dispatch(updateSolutionAsync(solution.id, obj));
			if (updatedSolutionId) {
				handleShowSnackbar(solution.id, obj.displayName, 'solution_updated');
			}
		} else {
			const newSolutionId = await dispatch(addSolutionAsync(obj));
			if (newSolutionId) {
				handleShowSnackbar(newSolutionId, obj.displayName, 'solution_created');
			}
		}
	};

	// Either a solution ID is available in the route, and in that case we are editing an existing solution
	// or it is not available, in which case we are creating a new solution from Admin view
	const editMode = solutionId !== undefined;

	const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
	const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

	const downloadConfig = useCallback(
		async (solutionId: string) => {
			const dlUrl = `/api/engineering/solutions/${solutionId}/openvpnconfig`;

			setDownloadInProgress(true);

			downloadFile(dlUrl)
				.catch((error) => {
					console.log('Download failed', error);
					dispatch(enqueueNotification('download_failed', 'error'));
				})
				.finally(() => setDownloadInProgress(false));
		},
		[dispatch],
	);

	const deleteSolutionHandler = async (solutionId: string) => {
		setDeleteInProgress(true);
		try {
			await DELETE<ISolution>(`/api/engineering/solutions/${solutionId}`);
			dispatch(removeSolution(solutionId));
			dispatch(clearSolutionDataAction(solutionId));
			dispatch(enqueueNotification('solution_deletion_success', 'success'));

			// Replaces top item on the stack
			window.history.replaceState({}, '', '/engineering');
			navigate('/engineering');
		} catch (error) {
			dispatch(enqueueError('solution_deletion_failed', error));
		} finally {
			setDeleteInProgress(false);
		}
	};

	return (
		<Box>
			<Formik
				initialValues={{
					displayName: initialValues.displayName,
					location: initialValues.location,
					type: initialValues.type,
					organization: initialValues.organization,
					expirationDate: initialValues.expirationDate,
					redundancy: initialValues.redundancy,
				}}
				validationSchema={yup.object({
					displayName: yup.string().min(2, t('solution_name_check')).required(t('solution_name_required')),
					location: yup.string().min(2, t('solution_location_check')).required(t('solution_location_required')),
					type: yup.string().min(2, t('solution_type_check')).required(t('solution_type_required')),
					organization: yup.string().length(24, t('solution_organization_check')).required(t('solution_organization_required')),
					expirationDate: yup.date().nullable(),
					redundancy: yup.boolean(),
				})}
				onSubmit={(values) => {
					return onSave(values);
				}}
				validationOnChange={true}
				validationOnBlur={true}
			>
				{(formik) => {
					// Formik helpers
					const getFormikError = (formik: any, fieldName: string): boolean => {
						if (formik.touched[fieldName] && formik.errors[fieldName]) {
							return true;
						}
						return false;
					};

					const getFormikHelperText = (formik: any, fieldName: string): string | false => {
						if (formik.touched[fieldName] && formik.errors[fieldName]) {
							return formik.errors[fieldName];
						}
						return false;
					};

					/* The Datepicker only understands a valid DateTime value or 'null'; setting the value to 'undefined' causes it to select the current date automatically */
					const fromDateToDateTime = (date: Date | undefined): DateTime | null => {
						if (date === undefined) {
							return null;
						}
						return DateTime.fromJSDate(date);
					};

					const setExpirationDateHelper = (formik: any, dateTime: DateTime | null) => {
						if (dateTime === null) {
							formik.setFieldValue('expirationDate', null);
						} else {
							const theDate = dateTime.toJSDate();
							theDate.setHours(0, 0, 0, 0);
							formik.setFieldValue('expirationDate', theDate);
						}
					};

					return (
						<form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
							{!editMode && <Typography variant="h4">{t('create_new_solution')}</Typography>}
							<Stack>
								<Stack spacing={0} direction="row">
									<TextField
										disabled={userIsObserver}
										fullWidth
										label={t('solution_name_label')}
										{...formik.getFieldProps('displayName')}
										error={getFormikError(formik, 'displayName')}
										helperText={getFormikHelperText(formik, 'displayName')}
									/>
									<TextField disabled={userIsObserver} fullWidth label={t('solution_location_label')} {...formik.getFieldProps('location')} error={getFormikError(formik, 'location')} helperText={getFormikHelperText(formik, 'location')} />
									<TextField disabled={userIsObserver} fullWidth label={t('solution_type_label')} {...formik.getFieldProps('type')} error={getFormikError(formik, 'type')} helperText={getFormikHelperText(formik, 'type')} />

									{userIsAdmin ? (
										<Box>
											<Stack spacing={1} direction="row" alignItems={'center'}>
												<MobileDatePicker
													// Since the datepicker renders its own input field, we can pass the error and helperText to it. Although, the picker itself should rule out any invalid dates.
													slots={{
														textField: (props) => {
															return <TextField {...props} error={getFormikError(formik, 'expirationDate')} helperText={getFormikHelperText(formik, 'expirationDate')} />;
														},
													}}
													label={t('solution_display_expiration_date')}
													//format="DD/MM/yyyy"
													slotProps={{
														actionBar: {
															actions: ['clear', 'cancel', 'accept'],
														},
													}}
													// Since we want to handle the date as a Date object, we need to convert it to a Moment object when setting the value.
													value={fromDateToDateTime(formik.values.expirationDate)}
													// The datepicker will return a Moment object, which we need to convert back to a Date object.
													onChange={(value) => setExpirationDateHelper(formik, value)}
													openTo="day"
												/>
												<OrganizationSelector
													// Props here to carry initial value
													{...formik.getFieldProps('organization')}
													// This component has to accept error and helperText as props, to show them inside.
													error={getFormikError(formik, 'organization')}
													helperText={getFormikHelperText(formik, 'organization')}
													// Custom onChange callback.
													onChange={(newValue: string) => {
														formik.setFieldValue('organization', newValue);
													}}
													organizations={organizations}
													isLoading={isLoading}
													label={t('owner_organization')}
												/>
												{!editMode && (
													<FormGroup>
														<FormControlLabel
															control={
																<Switch
																	defaultChecked={false}
																	value={formik.values.redundancy}
																	onChange={(_event, checked: boolean) => {
																		formik.setFieldValue('redundancy', checked);
																	}}
																/>
															}
															label="Enable redundancy"
														/>
													</FormGroup>
												)}
											</Stack>
										</Box>
									) : null}
								</Stack>
								<Stack direction={'row'}>
									{!userIsObserver ? (
										<Stack direction={'row'} sx={{padding: '20px 10px 10px 10px'}}>
											<Button sx={{marginTop: '3px', marginRight: '5px'}} hidden={!userIsAdmin} color="primary" size="small" type="submit" variant="contained">
												{solution ? t('save_solution_button') : t('create_new_solution_button')}
											</Button>
											<Button sx={{marginTop: '3px', marginRight: '5px'}} hidden={!userIsAdmin} color="primary" size="small" type="reset" variant="contained" onClick={() => formik.resetForm()} disabled={!formik.dirty}>
												{t('cancel_solution_button')}
											</Button>

											{devContentBoolean && solutionId ? (
												<LoadingButton
													sx={{marginTop: '3px', marginRight: '5px'}}
													hidden={!userIsAdmin}
													color="primary"
													size="small"
													type="reset"
													variant={'contained'}
													disabled={!solution || deleteInProgress}
													loading={deleteInProgress}
													onClick={() => setShowRemoveSolutionConfirmDialog(true)}
												>
													{t('delete_solution_button')}
												</LoadingButton>
											) : (
												<></>
											)}
											{roles.engineeringUserLevel === EngineeringUserLevel.ADMIN && editMode === true ? (
												<LoadingButton sx={{marginTop: '3px', marginRight: '5px'}} variant="contained" disabled={downloadInProgress} loading={downloadInProgress} color="primary" onClick={() => downloadConfig(solutionId!)}>
													{t('generate_openvpn_config_button')}
												</LoadingButton>
											) : null}
										</Stack>
									) : null}
								</Stack>
							</Stack>
						</form>
					);
				}}
			</Formik>

			{showRemoveSolutionConfirmDialog && (
				<ConfirmDialog
					dialogText={t('solution_remove_confirm_question', {Solution: solution.displayName})}
					open={true}
					isDisabled={removeSolutionConfirmationText !== solution?.displayName}
					handleClose={() => {
						setShowRemoveSolutionConfirmDialog(false);
					}}
					handleConfirmClick={() => {
						deleteSolutionHandler(solutionId!);
						setShowRemoveSolutionConfirmDialog(false);
					}}
				>
					<Stack flexDirection={'row'} justifyContent={'center'} marginTop={'10px'}>
						<Stack>
							<Typography textAlign={'center'} fontWeight={'bold'} marginBottom={'10px'}>
								{solution.displayName}
							</Typography>
							<TextField onChange={(e) => setRemoveSolutionConfirmationText(e.target.value)} />
						</Stack>
					</Stack>
				</ConfirmDialog>
			)}
		</Box>
	);
};

export default SolutionEditor;
