import {Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {Theme} from '@mui/material/styles';

import {makeStyles} from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import React, {useState, useMemo, useEffect, ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../app/store';
import {selectVerifiedNotificationChannelsAsArray} from '../reducers/engineering/notificationChannelsSlice';
import {createNotificationRule, INotificationRule} from '../reducers/engineering/notificationRulesSlice';
import {selectSolutionAsArray} from '../reducers/engineering/solutionsSlice';
import {IMomentaryValues, getSolutionMomentaryValuesAsync, selectMomentaryValuesBySolutionId} from '../reducers/momentaryValuesSlice';
import {getModuleType, ModuleType} from '../lib/staticValues';
import {IModule, selectSolutionById} from '../reducers/engineering/solutionsSlice';
import {RootState} from '../app/store';

import Joi from 'joi';

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			'& .MuiTextField-root': {
				width: 200,
			},
		},
		formControl: {
			minWidth: 100,
			maxWidth: 200,
		},
	};
});

const mongoId = new RegExp(/^[0-9a-fA-F]{24}$/);
const validLimitValue = new RegExp(/^\d+(\.\d{1,2})?$/);
const MAX_DECIMALS = 2;

// Check only parts that user is able to set
const createNotificationRuleValidation = Joi.object<Partial<INotificationRule>>({
	title: Joi.string().trim().required().min(1).max(128),
	channels: Joi.array().min(1).max(10).items(Joi.string().regex(mongoId)).required(),
});

interface IValidationError {
	[key: string]: boolean;
}

interface INotificationRuleFormProps {}

interface IMomentaryValuesTrigger {
	moduleId: string; // target module
	paramName: string; // name of parameter
	value: 'avg' | 'min' | 'max' | 'last'; // momentary values update (30 sec) has min,max, and avg values. Select what to use
	comparison: {
		type: 'lessthan' | 'morethan' | 'outsideOf' | 'lessthanorequal' | 'greaterthanorequal'; // less and more compared to limit1, outside of limit 1 & 2
		limit1: number;
		limit2?: number;
	};
}

const extractModulesFromMomentaryValues = (momentaryValues: IMomentaryValues | undefined) => {
	const mv = momentaryValues?.momentaryvalues;
	return mv ? Object.keys(mv) : [];
};

const extractParametersFromMomentaryValues = (momentaryValues: IMomentaryValues | undefined, module: IModule | undefined) => {
	if (!momentaryValues || !module) {
		return [];
	}
	const moduleParams = momentaryValues.momentaryvalues[module.moduleId];

	if (!moduleParams) {
		return [];
	}

	const params: {name: string; unit: string}[] = []; // maps parameter id to unit

	// Get parameters names and units
	for (const [pId, v] of Object.entries(moduleParams.values)) {
		params.push({
			name: pId,
			unit: v.unit,
		});
	}

	const exclusionListByType: {
		type: ModuleType;
		excluded: RegExp;
	}[] = [
		{type: 'module_type_ahf', excluded: new RegExp(/([IU]h\d{1,2})|(Load current [dq]-axis \(dc-component\))/)},
		{type: 'module_type_statcom', excluded: new RegExp(/([IU]h\d{1,2})|(Load Current [dq]-axis \(dc-component\))/)},
	];

	const staticModuleType = getModuleType(module.type);
	const excludedParams = exclusionListByType.find((exclusionItem) => exclusionItem.type === staticModuleType);

	if (excludedParams) {
		const shownParams = params.filter((parameter) => {
			const isExcluded = excludedParams.excluded.test(parameter.name);
			return isExcluded === false;
		});
		return shownParams;
	}
	return params;
};

const NotificationRuleForm = (props: INotificationRuleFormProps) => {
	const {classes} = useStyles();
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const channels = useSelector(selectVerifiedNotificationChannelsAsArray);
	const solutions = useSelector(selectSolutionAsArray);

	const [solutionId, setSolutionId] = useState<string>(solutions && solutions.length > 0 ? solutions[0].id : '');
	const [channelIds, setChannelIds] = useState<string[]>(channels[0] ? [channels[0]._id] : []);
	const [active, setActive] = useState<boolean>(true);
	const [ruleName, setRuleName] = useState<string>('');
	const [trigger, setTrigger] = useState<'trip' | 'alarm' | 'trip_or_alarm' | 'solution_state' | 'parameter_value'>('trip');
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [validationErrors, setValidationErrors] = useState<IValidationError>({});
	const [isLimit1ValueInvalid, setIsLimit1ValueInvalid] = useState<boolean>(false);
	const [isLimit2ValueInvalid, setIsLimit2ValueInvalid] = useState<boolean>(false);

	const momentaryValues = useSelector((state: RootState) => selectMomentaryValuesBySolutionId(state, solutionId));
	const modules = useMemo(() => extractModulesFromMomentaryValues(momentaryValues), [momentaryValues]);
	const [selectedModule, setSelectedModule] = React.useState<IModule>();
	const parameters = useMemo(() => extractParametersFromMomentaryValues(momentaryValues, selectedModule), [momentaryValues, selectedModule]);
	const [limitValues, setLimitValues] = useState<{limit1: string; limit2: string}>({
		limit1: '',
		limit2: '',
	});
	const [notificationRule, setNotificationRule] = useState<IMomentaryValuesTrigger>({
		moduleId: '',
		paramName: '',
		value: 'avg',
		comparison: {
			type: 'lessthan',
			limit1: 0,
			limit2: 0,
		},
	});

	const momentaryComparisonTypes: string[] = ['lessthan', 'morethan', 'outsideOf', 'lessthanorequal', 'greaterthanorequal'];
	const comparisonStrings: string[] = [
		t('notification_comparison_lessthan'),
		t('notification_comparison_morethan'),
		t('notification_comparison_outsideOf'),
		t('notification_comparison_lessthanorequal'),
		t('notification_comparison_greaterthanorequal'),
	];
	const momentaryAggregateValues: string[] = ['avg', 'min', 'max', 'last'];
	const aggregationStrings: string[] = [t('notification_aggregate_avg'), t('notification_aggregate_min'), t('notification_aggregate_max'), t('notification_aggregate_last')];

	let solution = useSelector((state: RootState) => selectSolutionById(state, solutionId ? solutionId : ''));

	const clearValidationError = (key: string) => {
		if (validationErrors[key]) {
			const ve = {...validationErrors};
			delete ve[key];
			setValidationErrors(ve);
		}
	};

	const decimalPointHelperText = useMemo(() => {
		return t('param_decimal_max', {decimals: MAX_DECIMALS});
	}, [t]);

	const checkLimitValueIsInvalid = (value: string, validation: RegExp) => {
		if (validation.test(value)) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (trigger === 'parameter_value') {
			dispatch(getSolutionMomentaryValuesAsync([solutionId]));
		}
	}, [dispatch, trigger, solution, solutionId]);

	const validate = (rule: Partial<INotificationRule>) => {
		// set abortEarly to false to get all validation errors
		// set allowUnknown to true as we are only validating partial object
		const result = createNotificationRuleValidation.validate(rule, {abortEarly: false, allowUnknown: true});
		if (result.error) {
			const err = result.error.details.reduce((prev, err) => {
				const key = err.path[0] as string; // path refers to key in validation schema
				prev[key] = true;
				return prev;
			}, {} as IValidationError);
			setValidationErrors(err);
			return false;
		}
		return true;
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		switch (event.target.name) {
			case 'rule-active-checkbox':
				setActive(!active);
				break;
			case 'rule-name':
				setRuleName(value);
				clearValidationError('title');
				break;
			case 'rule-solution-select':
				setSolutionId(value);
				break;
			case 'rule-channel-select':
				setChannelIds(value);
				clearValidationError('channels');
				break;
			case 'rule-trigger-select':
				setTrigger(value);
				break;
		}
	};

	const createRule = async () => {
		let rule: Partial<INotificationRule> = {};
		switch (trigger) {
			case 'solution_state':
				rule = {
					active: true,
					channels: channelIds,
					notificationLifetime: 30,
					solutionId: solutionId,
					title: ruleName,
					kind: 'solution_state',
					trigger: {
						leniency: 20,
					},
				};
				break;
			case 'trip':
			case 'alarm':
			case 'trip_or_alarm':
				rule = {
					active: true,
					channels: channelIds,
					notificationLifetime: 30,
					solutionId: solutionId,
					title: ruleName,
					kind: 'alarm',
					trigger: {
						alarmType: trigger,
					},
				};
				break;
			case 'parameter_value':
				rule = {
					active: true,
					channels: channelIds,
					notificationLifetime: 30,
					solutionId: solutionId,
					title: ruleName,
					kind: 'momentary_values',
					trigger: {
						value: notificationRule.value,
						comparison: {
							type: notificationRule.comparison.type,
							limit1: parseFloat(limitValues.limit1),
							limit2: notificationRule.comparison.type === 'outsideOf' ? parseFloat(limitValues.limit2) : undefined,
						},
						paramName: notificationRule.paramName,
						moduleId: notificationRule.moduleId,
					},
				};
		}

		if (validate(rule) && !isLimit1ValueInvalid && !isLimit2ValueInvalid) {
			setButtonDisabled(true);
			await dispatch(createNotificationRule(rule));
			setButtonDisabled(false);
		}
	};

	const handleMomentaryAggregateValueChange = (event: SelectChangeEvent) => {
		const updatedNotificationRule = {...notificationRule};
		switch (event.target.value) {
			case 'avg':
				updatedNotificationRule.value = 'avg';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'min':
				updatedNotificationRule.value = 'min';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'max':
				updatedNotificationRule.value = 'max';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'last':
				updatedNotificationRule.value = 'last';
				setNotificationRule(updatedNotificationRule);
				break;
			default:
				break;
		}
	};

	const handleComparisonTypeChange = (event: SelectChangeEvent) => {
		const updatedNotificationRule = {...notificationRule};
		if (event.target.value !== 'outsideOf') {
			setIsLimit2ValueInvalid(false);

			setLimitValues((limits) => ({...limits, limit2: ''}));
		} else {
			if (updatedNotificationRule.comparison.limit2 !== undefined) {
				const isInvalid = checkLimitValueIsInvalid(updatedNotificationRule.comparison.limit2.toString(), validLimitValue);
				setIsLimit2ValueInvalid(isInvalid);
			} else {
				setLimitValues((limits) => ({...limits, limit2: ''}));
			}
		}
		switch (event.target.value) {
			case 'lessthan':
				updatedNotificationRule.comparison.type = 'lessthan';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'morethan':
				updatedNotificationRule.comparison.type = 'morethan';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'outsideOf':
				updatedNotificationRule.comparison.type = 'outsideOf';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'lessthanorequal':
				updatedNotificationRule.comparison.type = 'lessthanorequal';
				setNotificationRule(updatedNotificationRule);
				break;
			case 'greaterthanorequal':
				updatedNotificationRule.comparison.type = 'greaterthanorequal';
				setNotificationRule(updatedNotificationRule);
				break;
			default:
				break;
		}
	};

	const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
		const updatedLimitValues = {...limitValues};
		const isInvalid = checkLimitValueIsInvalid(event.target.value, validLimitValue);
		switch (event.target.name) {
			case 'limit1':
				setIsLimit1ValueInvalid(isInvalid);
				break;
			case 'limit2':
				setIsLimit2ValueInvalid(isInvalid);
				break;
			default:
				break;
		}
		switch (event.target.name) {
			case 'limit1':
				updatedLimitValues.limit1 = event.target.value;
				setLimitValues(updatedLimitValues);
				break;
			case 'limit2':
				updatedLimitValues.limit2 = event.target.value;
				setLimitValues(updatedLimitValues);
				break;
			default:
				break;
		}
	};

	const handleModuleSelection = (event: SelectChangeEvent) => {
		const newModule = solution.modules[event.target.value];
		setSelectedModule(newModule);
		const updatedNotificationRule = {...notificationRule};
		updatedNotificationRule.moduleId = newModule.moduleId;
		updatedNotificationRule.paramName = '';
		setNotificationRule(updatedNotificationRule);
	};

	const handleParameterChange = (event: SelectChangeEvent) => {
		const updatedNotificationRule = {...notificationRule};
		updatedNotificationRule.paramName = event.target.value;
		setNotificationRule(updatedNotificationRule);
	};

	return (
		<form className={classes.root} noValidate autoComplete="off">
			<Grid container justifyContent="space-evenly">
				<Grid container direction={'row'} justifyContent={'inherit'}>
					<Grid item>
						<TextField name="rule-name" label={t('notification_rule_name')} value={ruleName} onChange={handleChange} error={validationErrors['title']} variant="standard" />
					</Grid>

					<Grid item>
						<FormControl className={classes.formControl}>
							<InputLabel id="rule-solution-select-label">{t('notification_rule_solution')}</InputLabel>
							<Select labelId="rule-solution-select-label" name="rule-solution-select" value={solutionId} label="Solution" onChange={handleChange} variant="standard">
								{solutions.map((solution) => (
									<MenuItem key={solution.id} value={solution.id}>
										{solution.displayName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item>
						<FormControl className={classes.formControl}>
							<InputLabel id="rule-channel-select-label">{t('notification_rule_channels')}</InputLabel>
							<Select labelId="rule-channel-select-label" multiple name="rule-channel-select" value={channelIds} label="Channel" onChange={handleChange} error={validationErrors['channels']} variant="standard">
								{channels.length === 0 && (
									<MenuItem disabled value="">
										{t('no_verified_channels')}
									</MenuItem>
								)}
								{channels.map((channel) => (
									<MenuItem key={channel._id} value={channel._id}>
										{channel.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl className={classes.formControl}>
							<InputLabel id="rule-trigger-select-label">{t('notification_rule_trigger')}</InputLabel>
							<Select labelId="rule-trigger-select-label" name="rule-trigger-select" value={trigger} label="Trigger condition" onChange={handleChange} variant="standard">
								<MenuItem value="trip">{t('alarm_type_trip')}</MenuItem>
								<MenuItem value="alarm">{t('alarm_type_alarm')}</MenuItem>
								<MenuItem value="trip_or_alarm">{t('alarm_type_trip_or_alarm')}</MenuItem>
								<MenuItem value="solution_state">{t('alarm_type_solution_state')}</MenuItem>
								<MenuItem value="parameter_value">{t('alarm_type_parameter_value')}</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<Button variant="contained" color="primary" onClick={createRule} disabled={buttonDisabled}>
							{t('create_rule_button')}
						</Button>
					</Grid>
				</Grid>
				{trigger === 'parameter_value' ? (
					<Grid container direction={'row'} justifyContent={'inherit'} marginTop={'20px'}>
						<Grid item>
							{/* Select Module */}
							<FormControl variant="outlined">
								<InputLabel id="module-select-label">{t('module')}</InputLabel>
								<Select label={t('module_select_label')} variant="standard" labelId="module-select-label" id="module-select" style={{width: '150px'}} value={selectedModule?.moduleId || ''} onChange={handleModuleSelection}>
									{modules.map((module) => (
										<MenuItem key={module} value={module}>
											{module}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							{/* Select Parameter */}
							<FormControl variant="outlined">
								<InputLabel id="parameter-select-label">{t('parameter_select_label')}</InputLabel>
								<Select
									label={t('parameter_select_label')}
									style={{width: '150px'}}
									variant="standard"
									labelId="parameter-select-label"
									id="parameter-select"
									value={notificationRule.paramName}
									onChange={handleParameterChange}
									MenuProps={{
										autoFocus: false,
									}}
								>
									{parameters.map((p) => {
										return (
											<MenuItem key={p.name} value={p.name}>
												{p.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl variant="outlined">
								<InputLabel id="momentary-aggregate-value-label" sx={{overflow: 'initial', left: '-13px'}}>
									{t('momentary_aggregate_value_select_label')}
								</InputLabel>
								<Select
									label={t('momentary_aggregate_value_select_label')}
									variant="standard"
									labelId="momentary-aggregate-value-label"
									id="momentary-aggregate-value-select"
									value={notificationRule.value}
									onChange={handleMomentaryAggregateValueChange}
								>
									{momentaryAggregateValues.map((aggregate, index) => (
										<MenuItem key={aggregate} value={aggregate}>
											{aggregationStrings[index]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl variant="outlined">
								<InputLabel id="momentary-comparison-type-label" sx={{overflow: 'initial', left: '-13px'}}>
									{t('momentary_comparison_type_select_label')}
								</InputLabel>
								<Select
									label={t('momentary_comparison_type_select_label')}
									variant="standard"
									labelId="momentary-comparison-type-label"
									id="momentary-comparison-type-select"
									value={notificationRule.comparison.type}
									onChange={handleComparisonTypeChange}
								>
									{momentaryComparisonTypes.map((comparison, index) => (
										<MenuItem key={comparison} value={comparison}>
											{comparisonStrings[index]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<Grid container direction={'column'}>
								<TextField
									inputProps={{style: {padding: '3px'}}}
									InputLabelProps={{shrink: true}}
									type="text"
									name="limit1"
									value={limitValues.limit1}
									variant="outlined"
									label={notificationRule.comparison.type === 'outsideOf' ? t('notification_limit1_lower_label') : t('notification_limit1_label')}
									error={isLimit1ValueInvalid}
									helperText={isLimit1ValueInvalid ? decimalPointHelperText : ''}
									onChange={handleLimitChange}
								>
									{limitValues.limit1}
								</TextField>
								{notificationRule.comparison.type === 'outsideOf' ? (
									<TextField
										sx={{marginTop: '7px'}}
										inputProps={{style: {padding: '3px'}}}
										InputLabelProps={{shrink: true}}
										type="text"
										name="limit2"
										value={limitValues.limit2}
										variant="outlined"
										label={t('notification_limit2_label')}
										error={isLimit2ValueInvalid}
										helperText={isLimit2ValueInvalid ? decimalPointHelperText : ''}
										onChange={handleLimitChange}
									>
										{limitValues.limit2}
									</TextField>
								) : (
									<></>
								)}
							</Grid>
						</Grid>
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</form>
	);
};

export default NotificationRuleForm;
