/**
 * Provides mapping to translations
 */
export enum EventTypes {
	Alarm = 'Alarm',
	Command = 'Command',
	Event = 'Event',
	Parameter = 'Parameter',
	State = 'State',
	Trip = 'Trip',
}

export const ModuleTypes = ['module_type_ahf', 'module_type_statcom', 'module_type_cooling_unit', 'cooling_unit', 'unknown'] as const;
export type ModuleType = typeof ModuleTypes[number];

export const getModuleType = (type: string): ModuleType => {
	let moduleType: ModuleType;
	switch (type) {
		case 'A2':
			moduleType = 'module_type_ahf';
			break;
		case 'M2000':
			moduleType = 'module_type_statcom';
			break;
		case 'CoolingUnit':
			moduleType = 'cooling_unit';
			break;
		default:
			moduleType = 'unknown';
			break;
	}
	return moduleType;
};

export const bgColor: {[key: string]: string} = {
	Running: '#388e3c',
	Trip: 'error.main',
	Alarm: 'yellow',
	'IGBTs ready to run': '#b2ff59',
	Disabled: 'info.main',
	'No State': 'text.disabled',
	Discharging: 'warning.main',
};
