import {createSelector, createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

export interface ITripAlarm {
	type: 'alarm' | 'trip'; // alarmOrTrip: Tells is this alarm or trip
	parameterId: string; // ParameterID: ID of alarm or trip  // TODO: Rename? Uniqueness inside solution?
	moduleId: string; // ModuleID: ID of module in which the alarm or trip has occurred
	timestamp: Date; // Timestamp of t/a
	active: boolean; // Active: True if the trip is still active, false if it is not, but not yet acked.
	bitNumber: number; // The bitnumber of the active alarm if this is a bits datatype parameter.
}

export interface ITripAlarmListEntry extends ITripAlarm {
	solutionDisplayName: string; // parent solution display name
	solutionId: string;
}

interface ITripalarmState {
	alarms: {
		[key: string]: ITripAlarm[];
	};
}

const initialState: ITripalarmState = {
	alarms: {},
};

export interface ITripAlarmResponse {
	solutionId: string;
	alarms: ITripAlarm[];
}

export const alarmsSlice = createSlice({
	name: 'engineering/alarms',
	initialState,
	reducers: {
		setTripsAlarms: (state, action: PayloadAction<ITripAlarmResponse>) => {
			// N.B. don't try this without immer
			const solutionId = action.payload.solutionId;
			state.alarms[solutionId] = action.payload.alarms;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.alarms[action.payload];
		});
	},
});

export const {setTripsAlarms} = alarmsSlice.actions;

/**
 * Registers socket event handlers to update Trips & Alarms
 * @param dispatch
 * @param socket
 */
export const registerTripsAlarmsEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('alarmsUpdate', (tripsAlarms: ITripAlarmResponse) => {
		dispatch(setTripsAlarms(tripsAlarms));
	});
};

export const getSolutionAlarmsAsync =
	(solutions: String[]): AppThunk =>
	(dispatch) => {
		solutions.forEach((solutionId) => {
			GET<ITripAlarmResponse>(`/api/engineering/alarms/${solutionId}`)
				.then((response) => {
					dispatch(setTripsAlarms(response));
				})
				.catch((error) => {
					dispatch(enqueueError('unable_to_load_alarms', error));
					console.error('Unable to load alarms', solutionId, error);
				});
		});
	};

/*
// This is called when websocket receives an updated momentaryValues document
export const syncSolutionMomentaryValuesAsync = (momentaryValues: IMomentaryValues): AppThunk => async (dispatch) => {
	dispatch(setMomentaryValuesEntry(momentaryValues));
};
*/

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAlarmsRoot = (state: RootState) => state.engineering.alarms;

const getSolutionId = (_state: RootState, solutionId: string) => solutionId;
const getModuleId = (_state: RootState, _solutionId: string, moduleId: string) => moduleId;

export const selectAlarmsBySolutionId = createSelector([selectAlarmsRoot, getSolutionId], (alarms, solutionId): ITripAlarm[] | undefined => {
	return alarms.alarms[solutionId];
});

export const selectAlarmsBySolutionIdAndModuleId = createSelector([selectAlarmsRoot, getSolutionId, getModuleId], (alarms, solutionId, moduleId) => {
	return (alarms.alarms[solutionId] || []).filter((e) => e.moduleId === moduleId);
});

const solutions = (state: RootState) => state.engineering.solutions;

export const selectAllAlarms = createSelector([selectAlarmsRoot, solutions], (alarmsRoot, solutionsRoot) => {
	let unAckAlarms: ITripAlarmListEntry[] = [];

	for (const [solutionId, alarms] of Object.entries(alarmsRoot.alarms)) {
		for (const alarm of alarms) {
			// Include all alarms and then append display name from solutions list
			unAckAlarms.push(
				Object.assign({}, alarm, {
					solutionDisplayName: solutionsRoot.solutions[solutionId].displayName,
					solutionId: solutionId,
				}),
			);
		}
	}
	return unAckAlarms;
});

export default alarmsSlice.reducer;
