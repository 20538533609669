import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {Box, Chip, LinearProgress, Paper, Stack, Tooltip} from '@mui/material';
import {DataGrid, GridActionsCellItem, GridColDef, GridSlots, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'src/app/store';
import TradingConfigFormDialog from 'src/components/TradingConfigFormDialog';
import {AvailableMarkets} from 'src/lib/reserveMarketConstants';
import {IBreadcrumbItem, queryAndSetUserRoles, setBreadcrumbs} from 'src/reducers/appSlice';
import {getAdminSolutionsAsync, selectAdminSolutionAsArray, updateAdminSolutionEntry} from 'src/reducers/trading/adminSolutionsSlice';
import {selectSolutionsLoading} from 'src/reducers/trading/solutionsSlice';
import {IInitialTradingConfig, ITradingConfigResponse, createTradingConfigAsync, updateTradingConfigAsync} from 'src/reducers/trading/tradingConfigSlice';
import {topBarHeight} from '../ToolLayout';

const QuickSearchToolbar = () => {
	const {t} = useTranslation();

	return (
		<Box sx={{mt: 1, p: 1}}>
			<Stack direction={{xs: 'column', sm: 'row'}} alignItems="center" justifyContent={'start'} spacing={2}>
				<GridToolbarQuickFilter
					variant="outlined"
					size="small"
					debounceMs={500}
					name={'find_solution'}
					margin={'none'}
					placeholder={t('find_solution')}
					inputProps={{
						autoComplete: 'none',
					}}
				/>
			</Stack>
		</Box>
	);
};

export interface ITradingConfigDraft {
	maximumEnergyCapacity: number;
	maximumOutputPower: number;
	ecpConfiguration: {
		eicCode: string;
		edxHost?: string | null;
		edxPort?: number | null;
		edxUsername?: string | null;
		edxPassword?: string | null;
	};
	enabledMarkets?: AvailableMarkets[];
}

export interface IAdminSolutionListEntry {
	id: string;
	displayName: string;
	type: string;
	edxPasswordSet: boolean;
	tradingConfig?: ITradingConfigDraft;
	hasExistingTradingConfig: boolean;
	hasChanges: boolean;
}

const TradingAdmin = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	// List of solutions and trading configurations
	const adminSolutions = useSelector(selectAdminSolutionAsArray);
	const adminSolutionListLoading = useSelector(selectSolutionsLoading);

	// Map of changes user has done to each solution's trading config
	const [updatedConfigs, setUpdatedConfigs] = useState(new Map<string, ITradingConfigDraft>());

	// Id of selected solution. Updated when user clicks edit button.
	const [selectedSolutionId, setSelectedSolutionId] = useState<string | undefined>();

	// Disable actions while trading config is being updated / created.
	const [updateInProgress, setUpdateInProgress] = useState(false);

	// Load admin solution list when view is mounted
	useEffect(() => {
		dispatch(queryAndSetUserRoles());
		dispatch(getAdminSolutionsAsync());
	}, [dispatch]);

	useEffect(() => {
		let crumbs: IBreadcrumbItem[] = [
			{
				title: t('trading_view_title'),
				path: '/trading',
			},
			{
				title: t('administration_view_title'),
			},
		];

		dispatch(setBreadcrumbs(crumbs));

		return () => {
			dispatch(setBreadcrumbs([]));
		};
	}, [t, dispatch]);

	// Combine user made changes to Admin solution list
	const rows = useMemo(() => {
		return adminSolutions.map((s) => {
			const changes = updatedConfigs.get(s.id);

			const hasChanges = changes !== undefined;

			let entry: IAdminSolutionListEntry = {
				id: s.id,
				type: s.type,
				displayName: s.displayName,
				hasChanges: hasChanges,
				hasExistingTradingConfig: s.tradingConfig !== undefined,
				edxPasswordSet: s.tradingConfig?.ecpConfiguration.edxPasswordSet === true,
				tradingConfig: hasChanges ? changes : s.tradingConfig,
			};

			return entry;
		});
	}, [adminSolutions, updatedConfigs]);

	const selectedRow = useMemo(() => {
		return rows.find((r) => r.id === selectedSolutionId);
	}, [selectedSolutionId, rows]);

	const handleSaveClick = useCallback(
		(solutionId: string) => async () => {
			const changes = updatedConfigs.get(solutionId);
			const row = rows.find((r) => r.id === solutionId);

			if (row && changes) {
				setUpdateInProgress(true);

				let response: ITradingConfigResponse | undefined;
				if (row.hasExistingTradingConfig) {
					response = await dispatch(updateTradingConfigAsync(solutionId, changes));
				} else {
					// TradingConfigFormDialog ensures that there are no "null" values in draft, when creating initial config.
					const initialTradingConfig = changes as IInitialTradingConfig;
					response = await dispatch(createTradingConfigAsync(solutionId, initialTradingConfig));
				}

				if (response) {
					// Remove changes only after we have received the response.
					// This allows user to retry using same values, if something goes wrong.
					setUpdatedConfigs((map) => {
						map.delete(solutionId);
						return new Map(map);
					});

					// We could either reload all admin solutions (getAdminSolutionsAsync)
					// or use response to craft update manually to avoid extra call.
					dispatch(
						updateAdminSolutionEntry({
							id: row.id,
							displayName: row.displayName,
							type: row.type,
							tradingConfig: {
								ecpConfiguration: response.ecpConfiguration,
								maximumEnergyCapacity: response.maximumEnergyCapacity,
								maximumOutputPower: response.maximumOutputPower,
								enabledMarkets: response.enabledMarkets,
								optibidIdentifier: response.optibidIdentifier,
							},
						}),
					);
				}
				setUpdateInProgress(false);
			}
		},
		[rows, updatedConfigs, dispatch],
	);

	const handleEditClick = useCallback(
		(solutionId: string) => () => {
			setSelectedSolutionId(solutionId);
		},
		[],
	);

	const handleDiscard = useCallback(
		(solutionId: string) => () => {
			setUpdatedConfigs((map) => {
				map.delete(solutionId);
				return new Map(map);
			});
		},
		[],
	);

	const handleClose = () => {
		setSelectedSolutionId(undefined);
	};

	const handleOkClick = (updatedTradingConfig: ITradingConfigDraft) => {
		if (selectedSolutionId) {
			setUpdatedConfigs((map) => new Map(map.set(selectedSolutionId, updatedTradingConfig)));
		}
		// Complete editing by deselecting solution. Dialog is closed
		setSelectedSolutionId(undefined);
	};

	const columns = useMemo<GridColDef<IAdminSolutionListEntry>[]>(
		() => [
			{field: 'displayName', headerName: t('solution_display_name'), flex: 1},
			{field: 'type', headerName: 'Type', width: 100},
			{
				field: 'eicCode',
				headerName: t('eic_code_header'),
				width: 180,
				valueGetter: (_value, row) => {
					return row.tradingConfig?.ecpConfiguration.eicCode || 'N/A';
				},
			},
			{
				field: 'maximumEnergyCapacity',
				headerName: `${t('solution_display_maximum_energy_capacity')} (${t('unit_mwh')})`,
				width: 200,
				valueGetter: (_value, row) => {
					const value = row.tradingConfig?.maximumEnergyCapacity;
					return value === undefined ? 'N/A' : value;
				},
			},
			{
				field: 'maximumOutputPower',
				headerName: `${t('solution_display_maximum_output_power')} (${t('unit_mw')})`,
				width: 180,
				valueGetter: (_value, row) => {
					const value = row.tradingConfig?.maximumOutputPower;
					return value === undefined ? 'N/A' : value;
				},
			},
			{
				field: 'markets',
				headerName: `${t('solution_display_enabled_markets')}`,
				flex: 2,
				renderCell: (params) => {
					const markets = params.row.tradingConfig?.enabledMarkets || [];
					// Use available markets as sorting reference
					const orderedMarkets = Object.values(AvailableMarkets).filter((m) => markets?.includes(m as AvailableMarkets));
					return (
						<>
							{orderedMarkets.map((m) => (
								<Chip sx={{ml: 1}} key={m} label={m} />
							))}
						</>
					);
				},
			},
			{
				field: 'actions',
				type: 'actions',
				getActions: (params) => {
					const addOrEditIcon =
						params.row.tradingConfig === undefined ? (
							<Tooltip title={t('solution_tooltip_add_trading_config')}>
								<AddIcon />
							</Tooltip>
						) : (
							<Tooltip title={t('solution_tooltip_edit_trading_config')}>
								<EditIcon />
							</Tooltip>
						);

					const saveIcon = (
						<Tooltip title={t('solution_tooltip_save_trading_config')}>
							<SaveIcon />
						</Tooltip>
					);

					const revertIcon = (
						<Tooltip title={t('solution_tooltip_revert_trading_config')}>
							<CancelIcon />
						</Tooltip>
					);

					const actions = [
						<GridActionsCellItem icon={addOrEditIcon} disabled={updateInProgress} color="primary" onClick={handleEditClick(params.row.id)} label="Edit" />,
						<GridActionsCellItem icon={saveIcon} disabled={!params.row.hasChanges || updateInProgress} color="primary" onClick={handleSaveClick(params.row.id)} label="Save" />,
						<GridActionsCellItem icon={revertIcon} disabled={!params.row.hasChanges || updateInProgress} color="primary" onClick={handleDiscard(params.row.id)} label="Cancel" />,
					];

					return actions;
				},
			},
		],
		[t, handleSaveClick, handleDiscard, handleEditClick, updateInProgress],
	);

	return (
		<Box sx={{width: '100%', height: `calc(100vh - ${topBarHeight})`}}>
			<Helmet>
				<title>{t('trading_view_title')}</title>
			</Helmet>
			<Paper sx={{width: '100%', height: '100%', p: 0}}>
				<DataGrid
					editMode="row"
					loading={adminSolutionListLoading}
					slots={{
						toolbar: QuickSearchToolbar,
						loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
					}}
					getRowId={(row) => row.id}
					rows={rows}
					columns={columns}
					autoPageSize={true}
				/>
			</Paper>
			{selectedRow && (
				<TradingConfigFormDialog
					solutionDisplayName={selectedRow.displayName}
					updatingExistingConfig={selectedRow.hasExistingTradingConfig}
					handleClose={handleClose}
					handleOkClick={handleOkClick}
					tradingConfig={selectedRow.tradingConfig}
					edxPasswordSet={selectedRow.edxPasswordSet}
				/>
			)}
		</Box>
	);
};

export default TradingAdmin;
