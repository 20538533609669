import {combineReducers} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import userReducer from '../reducers/management/usersSlice';
import organizationReducer from '../reducers/management/organizationSlice';

const managementReducer = combineReducers({
	users: userReducer,
	organizations: organizationReducer,
});

const persistConfig = {
	key: 'management',
	version: 1,
	storage,
	blacklist: [
		// These stores are cleared when user refreshes the page
		'organizations',
		'users',
	],
};

export default persistReducer(persistConfig, managementReducer);
