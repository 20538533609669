import {Autocomplete, Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField} from '@mui/material';
import {Box} from '@mui/system';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {shortDateTimeFormat, useDateTimeFormatter} from 'src/hooks/useDateTimeFormatter';
import {IOTAFile, getOTAFiles, getSelectedOTAFileId, selectAllOTAFiles, setSelectedOTAFileId} from 'src/reducers/engineering/otaFilesSlice';
import {useAppDispatch} from '../../app/store';
import OTASchedules from './OTASchedules';

const AutoCompleteListBody = (props: any) => {
	return (
		<Paper elevation={2}>
			<List dense {...props}></List>
		</Paper>
	);
};
const OTAScheduling = () => {
	const {t} = useTranslation();
	const otaFiles = useSelector(selectAllOTAFiles);
	const selectedOTAFileId = useSelector(getSelectedOTAFileId);
	const dispatch = useAppDispatch();

	// Get list of OTA files
	useEffect(() => {
		dispatch(getOTAFiles());
	}, [dispatch]);

	const dateTimeFormatter = useDateTimeFormatter('en-GB', shortDateTimeFormat);

	const selectedOTAFile = useMemo(() => {
		const file = otaFiles?.find((f) => f._id === selectedOTAFileId);
		return file;
	}, [selectedOTAFileId, otaFiles]);

	return (
		<Box
			sx={{
				height: '100%',
				p: 0,
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
				minWidth: 0,
			}}
		>
			<Box sx={{mt: 1}}>
				<Autocomplete
					id="ota-file-selection"
					openOnFocus
					disablePortal
					value={selectedOTAFile || null}
					options={otaFiles || []}
					sx={{width: '400px'}}
					getOptionLabel={(option) => option.filename}
					PaperComponent={AutoCompleteListBody}
					renderOption={(props, option) => (
						<ListItem {...props} key={option._id}>
							<ListItemAvatar>
								<Badge variant="dot" overlap="circular" invisible={option.schedules.every((s) => s.archived)} color="success" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
									<Avatar>
										<InsertDriveFileIcon />
									</Avatar>
								</Badge>
							</ListItemAvatar>
							<ListItemText primary={option.filename} secondary={dateTimeFormatter(option.created)} />
						</ListItem>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('select_ota_file')}
							placeholder={t('search_ota_file')}
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password', // disable autocomplete and autofill
							}}
						/>
					)}
					onChange={(_event: any, newValue, reason) => {
						if (reason === 'selectOption') {
							const value = newValue as IOTAFile;
							dispatch(setSelectedOTAFileId(value._id));
						} else if (reason === 'clear') {
							dispatch(setSelectedOTAFileId(undefined));
						}
					}}
				/>
			</Box>
			<Box sx={{display: 'flex', flexGrow: 1, mt: 1, minHeight: 0, minWidth: 0}}>{selectedOTAFile && <OTASchedules otaFile={selectedOTAFile} />}</Box>
		</Box>
	);
};
export default OTAScheduling;
