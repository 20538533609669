import {combineReducers} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import solutionsReducer from '../reducers/trading/solutionsSlice';
import uiConfigReducer from '../reducers/trading/uiConfigSlice';
import tradingConfigReducer from '../reducers/trading/tradingConfigSlice';
import bidsReducer from '../reducers/trading/bidsSlice';
import adminSolutionsReducer from '../reducers/trading/adminSolutionsSlice';
import tradesReducer from '../reducers/trading/tradesSlice';
import bidRecommendationsReducer from 'src/reducers/trading/bidRecommendationsSlice';
import reportingReducer from 'src/reducers/trading/reportingSlice';

const tradingReducer = combineReducers({
	solutions: solutionsReducer,
	uiConfig: uiConfigReducer,
	tradingConfig: tradingConfigReducer,
	bids: bidsReducer,
	adminSolutions: adminSolutionsReducer,
	trades: tradesReducer,
	bidRecommendations: bidRecommendationsReducer,
	reporting: reportingReducer,
});

const persistConfig = {
	key: 'trading',
	version: 1,
	storage,
	blacklist: [
		// These stores are cleared when user refreshes the page
	],
};

export default persistReducer(persistConfig, tradingReducer);
