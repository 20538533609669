import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LensIcon from '@mui/icons-material/Lens';
import {createTheme, ListItemIcon, ListItemText, Stack, Switch, ThemeProvider, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Theme} from '@mui/material/styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {makeStyles, withStyles} from 'tss-react/mui';
import {store, useAppDispatch} from '../app/store';
import {EngineeringUserLevel, TradingUserLevel, setsessionLengthInSeconds, showDevContent, toggleDevContent, userRoles} from '../reducers/appSlice';
import AccountInfo from './AccountInfo';
import {InteractionStatus} from '@azure/msal-browser';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useMatch, useNavigate} from 'react-router-dom';
import {loginRequest} from '../authConfig';
import {disconnectSocket} from '../lib/socketClient';
import {accountCirclePrimary, accountCircleSecondary} from '../palette';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';

const theme: Theme = createTheme({
	palette: {
		primary: accountCirclePrimary,
		secondary: accountCircleSecondary,
	},
});

const StyledMenu = withStyles(Menu, (theme) => ({
	paper: {
		border: '1px solid #d3d4d5',
	},
}));

const StyledMenuItem = withStyles(MenuItem, (theme) => ({
	root: {
		'& .MuiListItemText-primary': {
			color: theme.palette.text.primary,
		},
		'& .MuiListItemIcon-root': {
			color: theme.palette.text.primary,
		},
	},
}));

const useStyles = makeStyles()((theme: Theme) => {
	return {
		devSwitch: {
			paddingTop: '15px',
			paddingLeft: '15px',
		},
	};
});

export type AccountMenuProps = {};

const AccountMenu = (props: AccountMenuProps) => {
	const dispatch = useAppDispatch();
	const {classes} = useStyles();

	const {t} = useTranslation();
	const navigate = useNavigate();
	const roles = useSelector(userRoles);

	const isEngineeringPath = useMatch('/engineering/*');
	const isTradingPath = useMatch('/trading/*');

	const isAuthenticated: boolean = useIsAuthenticated();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const devContentBoolean: boolean = useSelector(showDevContent);

	const handleDevSwitchChange = () => {
		dispatch(toggleDevContent());
	};

	/** Shows role selection in menu, if user has more than one role */
	const showRoleSelection = useMemo(() => {
		const roleLevels = [roles.engineeringUserLevel, roles.tradingUserLevel, roles.managementUserLevel];
		const enabledRoles = roleLevels.filter((level) => level > 0);
		return enabledRoles.length > 1;
	}, [roles]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			store.dispatch(setsessionLengthInSeconds());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div>
			<ThemeProvider theme={theme}>
				<IconButton size="large" data-test="account_menu" aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
					<LensIcon color="primary" sx={{fontSize: '2.1rem', position: 'absolute', zIndex: 1}} />
					<AccountCircle color="secondary" sx={{fontSize: '2.2rem', position: 'absolute', zIndex: 2}} />
				</IconButton>
			</ThemeProvider>
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{isAuthenticated ? (
					<StyledMenuItem>
						<AccountInfo />
					</StyledMenuItem>
				) : null}

				{isAuthenticated ? (
					<StyledMenuItem>
						{roles.developer ? (
							<div className={classes.devSwitch}>
								<Typography variant="subtitle2">{t('dev_content')}</Typography>
								<Switch color="primary" checked={devContentBoolean} onClick={handleDevSwitchChange} />
							</div>
						) : null}
					</StyledMenuItem>
				) : null}

				{/* Show only in engineering, and if user has any engineering role  */}
				{isEngineeringPath && !!roles.engineeringUserLevel && (
					<StyledMenuItem
						data-test="notification_menu_item"
						onClick={() => {
							navigate('/engineering/notifications');
							handleClose();
						}}
					>
						<ListItemIcon>
							<NotificationsIcon />
						</ListItemIcon>
						<ListItemText>{t('notifications_view_title')}</ListItemText>
					</StyledMenuItem>
				)}

				{/* Show only in engineering, and if user has the engineering admin role  */}
				{isEngineeringPath && roles.engineeringUserLevel === EngineeringUserLevel.ADMIN && (
					<StyledMenuItem
						data-test="admin_menu_item"
						onClick={() => {
							navigate('/engineering/admin');
							handleClose();
						}}
					>
						<ListItemIcon>
							<AdminPanelSettingsIcon />
						</ListItemIcon>
						<ListItemText>{t('administration_view_title')}</ListItemText>
					</StyledMenuItem>
				)}
				{/* Show only in trading, and if user has the trading admin role */}
				{isTradingPath && roles.tradingUserLevel === TradingUserLevel.ADMIN && (
					<StyledMenuItem
						data-test="trading_admin_menu_item"
						onClick={() => {
							navigate('/trading/admin');
							handleClose();
						}}
					>
						<ListItemIcon>
							<AdminPanelSettingsIcon />
						</ListItemIcon>
						<ListItemText>{t('administration_view_title')}</ListItemText>
					</StyledMenuItem>
				)}

				{/* Show role switch menu item, if user has both roles */}
				{showRoleSelection && (
					<StyledMenuItem
						data-test="role_selector_menu_item"
						onClick={() => {
							navigate('/roles');
							handleClose();
						}}
					>
						<ListItemIcon>
							<SwapHorizontalCircleIcon />
						</ListItemIcon>
						<ListItemText>{t('switch_roles')}</ListItemText>
					</StyledMenuItem>
				)}
				<SignInSignOutMenuItem />
			</StyledMenu>
		</div>
	);
};

export default AccountMenu;

const SignInSignOutMenuItem = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {instance, inProgress} = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.error(e);
		});
	};

	const handleLogout = async () => {
		dispatch(disconnectSocket());
		// After logout user is redirected back to login view
		// See postLogoutRedirectUri in authConfig
		await instance.logoutRedirect();
	};

	if (isAuthenticated) {
		return (
			<StyledMenuItem onClick={handleLogout}>
				<ListItemIcon>
					<MeetingRoomIcon />
				</ListItemIcon>
				<ListItemText>{t('sign_out')}</ListItemText>
			</StyledMenuItem>
		);
	} else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
		// inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
		return (
			<StyledMenuItem onClick={handleLogin}>
				<ListItemIcon>
					<ExitToAppIcon />
				</ListItemIcon>
				<ListItemText>{t('sign_in')}</ListItemText>
			</StyledMenuItem>
		);
	} else {
		return null;
	}
};
