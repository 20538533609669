import { jsonObject, jsonMember, toJson } from 'typedjson';
import {v4 as uuidv4} from 'uuid';


@toJson
@jsonObject
export abstract class Anchor {
    @jsonMember(String)
    public id: string | undefined;

    public constructor(id? :string) {
        this.id =  id ? id : uuidv4();
    }
}