import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import {EngineeringUserLevel, ManagementUserLevel, TradingUserLevel, userRoles} from './reducers/appSlice';

interface AuthorizedRouteProps {
	requiredManagementUserLevel?: ManagementUserLevel;
	requiredTradingUserLevel?: TradingUserLevel;
	requiredEngineeringUserLevel?: EngineeringUserLevel;
	redirectPath?: string;
}

/**
 * Authorized route
 * Checks if user has required user level in order to access the specified component
 * @param props
 * @returns
 */
const AuthorizedRoute = (props: AuthorizedRouteProps) => {
	const roles = useSelector(userRoles);

	let authorized = false;

	if (props.requiredTradingUserLevel !== undefined) {
		authorized = roles.tradingUserLevel >= props.requiredTradingUserLevel;
	}
	if (props.requiredEngineeringUserLevel !== undefined) {
		authorized = roles.engineeringUserLevel >= props.requiredEngineeringUserLevel;
	}
	if (props.requiredManagementUserLevel !== undefined) {
		authorized = roles.managementUserLevel >= props.requiredManagementUserLevel;
	}

	if (!authorized) {
		return <Navigate to="/roles" replace={true} />;
	}

	return <Outlet />;
};

export default AuthorizedRoute;
