import {Box, Paper, Tab, Tabs} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from 'src/app/store';
import OTAFileList from 'src/components/ota-files/OTAFilesList';
import OTAScheduling from 'src/components/ota-files/OTAScheduling';
import {setSelectedOTAFileId} from 'src/reducers/engineering/otaFilesSlice';
import SolutionEditor from 'src/components/SolutionEditor';
import {IBreadcrumbItem, setBreadcrumbs} from 'src/reducers/appSlice';
import {topBarHeight} from '../ToolLayout';

export const AdminTabs = ['create_solution', 'upload_files', 'scheduling'] as const;
export type AdminTabId = (typeof AdminTabs)[number];

type AdminProps = {};

const a11yProps = (index: any) => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
};

interface TabPanelProps {
	children?: React.ReactNode;
	value: AdminTabId;
	index: AdminTabId;
}

const TabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props;

	return (
		<Box sx={{flexGrow: 1, minHeight: 0, minWidth: 0}} role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && children}
		</Box>
	);
};

const Admin = (props: AdminProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [selectedTab, setSelectedTab] = React.useState<AdminTabId>('upload_files');

	useEffect(() => {
		let crumbs: IBreadcrumbItem[] = [
			{
				title: t('home_view_title'),
				path: '/engineering',
			},
			{
				title: t('administration_view_title'),
			},
		];

		dispatch(setBreadcrumbs(crumbs));

		return () => {
			dispatch(setBreadcrumbs([]));
		};
	}, [t, dispatch]);

	const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: AdminTabId) => {
		setSelectedTab(newValue);
	};
	const fileLinkClicked = (id: string) => {
		dispatch(setSelectedOTAFileId(id));
		setSelectedTab('scheduling');
	};

	// This route is wrapped inside AuthorizedRoute that checks that user has to have engineering admin role
	return (
		<Paper sx={{height: `calc(100vh - ${topBarHeight})`, width: '100%', p: 3, display: 'flex', flexDirection: 'column'}}>
			<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
				<Tabs value={selectedTab} onChange={handleTabChange} aria-label="Admin operations">
					<Tab value={'upload_files'} data-test="upload_files" label={t('upload_files')} {...a11yProps('upload_files')} />
					<Tab value={'scheduling'} data-test="scheduling" label={t('scheduling')} {...a11yProps('scheduling')} />
					<Tab value={'create_solution'} data-test="create_solution" label={t('create_solution')} {...a11yProps('upload_files')} />
				</Tabs>
			</Box>

			<TabPanel value={selectedTab} index={'upload_files'}>
				<OTAFileList onFileLinkClick={fileLinkClicked} />
			</TabPanel>
			<TabPanel value={selectedTab} index={'scheduling'}>
				<OTAScheduling />
			</TabPanel>
			<TabPanel value={selectedTab} index={'create_solution'}>
				<SolutionEditor />
			</TabPanel>
		</Paper>
	);
};

export default Admin;
