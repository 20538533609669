import {Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {Delete} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {deleteNotificationChannel, getNotificationChannelsAsync, INotificationChannel, selectNotificationChannelsAsArray} from '../reducers/engineering/notificationChannelsSlice';
import NotificationChannelVerificationDialog from './NotificationChannelVerificationDialog';
import {useAppDispatch} from 'src/app/store';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& > *': {
				borderBottom: 'unset',
			},
		},
	};
});

interface RowProps {
	channel: INotificationChannel;
}

function Row(props: RowProps) {
	const {channel} = props;
	const {classes} = useStyles();
	const dispatch = useAppDispatch();
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [showVerificationDialog, setShowVerificationDialog] = React.useState(false);

	const handleDelete = async () => {
		setButtonDisabled(true);
		await dispatch(deleteNotificationChannel(channel._id));
		setButtonDisabled(false);
	};

	const handleClose = () => {
		setShowVerificationDialog(false);
	};

	return (
		<React.Fragment>
			<TableRow className={classes.root} hover>
				<TableCell>
					<Checkbox
						value={channel.verified}
						checked={channel.verified}
						onClick={() => {
							if (!channel.verified) {
								setShowVerificationDialog(true);
							}
						}}
					/>

					{showVerificationDialog && <NotificationChannelVerificationDialog open={showVerificationDialog} handleClose={handleClose} channelId={channel._id} />}
				</TableCell>
				<TableCell>{channel.title}</TableCell>
				<TableCell>{channel.kind}</TableCell>
				<TableCell>{channel.kind === 'email' ? channel.email : channel.number}</TableCell>

				<TableCell>
					<IconButton aria-label="delete" size="small" disabled={buttonDisabled} onClick={handleDelete}>
						<Delete />
					</IconButton>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

interface NotificationChannelListProps {}

const NotificationChannelList = (props: NotificationChannelListProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		// Perform initial load of solution data
		// TODO: cache results (RTK Query?)
		dispatch(getNotificationChannelsAsync());
	}, [dispatch]);

	const channels = useSelector(selectNotificationChannelsAsArray);

	return (
		<TableContainer component={Paper}>
			<Table aria-label={t('solution_overview_table')}>
				<TableHead>
					<TableRow>
						<TableCell>{t('verified')}</TableCell>
						<TableCell>{t('notification_channel_name')}</TableCell>
						<TableCell>{t('notification_channel_type')}</TableCell>
						<TableCell>{t('notification_channel_address')}</TableCell>
						<TableCell>{t('notification_delete')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{channels.map((channel) => (
						<Row key={channel._id} channel={channel} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default NotificationChannelList;
