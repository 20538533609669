import {combineReducers} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import alarmsReducer from '../reducers/engineering/alarmsSlice';
import organizationsReducer from '../reducers/engineering/organizationsSlice';
import eventsReducer from '../reducers/engineering/eventsSlice';
import momentaryValuesReducer from '../reducers/momentaryValuesSlice';
import notificationChannelsReducer from '../reducers/engineering/notificationChannelsSlice';
import notificationRulesReducer from '../reducers/engineering/notificationRulesSlice';
import settableParametersReducer from '../reducers/engineering/settableParamsSlice';
import signalFilesReducer from '../reducers/engineering/signalFilesSlice';
import singleLineDiagramReducer from '../reducers/engineering/singleLineDiagramsSlice';
import uiConfigReducer from '../reducers/engineering/uiConfigSlice';
import solutionsReducer from '../reducers/engineering/solutionsSlice';
import otaFilesReducer from '../reducers/engineering/otaFilesSlice';
import commandsReducer from '../reducers/engineering/commandsSlice';
import firmwareReducer from '../reducers/engineering/firmwareSlice';

const engineeringReducer = combineReducers({
	solutions: solutionsReducer,
	momentaryValues: momentaryValuesReducer,
	alarms: alarmsReducer,
	events: eventsReducer,
	signals: signalFilesReducer,
	singleLineDiagrams: singleLineDiagramReducer,
	uiConfig: uiConfigReducer,
	notificationChannels: notificationChannelsReducer,
	notificationRules: notificationRulesReducer,
	settableParameters: settableParametersReducer,
	otaFiles: otaFilesReducer,
	commands: commandsReducer,
	firmware: firmwareReducer,
	organizations: organizationsReducer,
});

const persistConfig = {
	key: 'engineering',
	version: 1,
	storage,
	blacklist: [
		// These stores are cleared when user refreshes the page
		'alarms',
		'events',
		'signals',
		'uiConfig',
		'settableParameters',
		'otaFiles',
		'commands',
		'firmware',
		'organizations',
	],
};

export default persistReducer(persistConfig, engineeringReducer);
