
export enum SupportedDirection {
    Up = 1,
    Down = 2,
    Left = 3,
    Right = 4,
}

export enum SupportedDimension {
    Horizontal = 0,
    Vertical = 1,
}