import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from 'src/app/actions';

export enum HistoricalDataReportType {
	FCR = 'FCR',
}

type HistoricalReportMetadata = {
	[key in HistoricalDataReportType]?: {
		newestRowTimestamp: string;
		oldestRowTimestamp: string;
	} | null; // null, if there are no rows in DB.
};

interface IReportMetadataResponse {
	solutionId: string;
	// Reports do not contain keys, if reporting is not configured
	reports: HistoricalReportMetadata;
}

interface IReportingState {
	reports: {
		[solutionId: string]: HistoricalReportMetadata;
	};
}

const initialState: IReportingState = {
	reports: {},
};

const reportingSlice = createSlice({
	name: 'reporting/reports',
	initialState,
	reducers: {
		setReports: (state, action: PayloadAction<IReportMetadataResponse>) => {
			state.reports[action.payload.solutionId] = action.payload.reports;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.reports[action.payload];
		});
	},
});

const {setReports} = reportingSlice.actions;

export const loadReportingMetadata =
	(solutionId: string): AppThunk<Promise<IReportMetadataResponse | undefined>> =>
	async (dispatch) => {
		try {
			const response = await GET<IReportMetadataResponse>(`/api/trading/reporting/${solutionId}/reports`);
			dispatch(setReports(response));
			return response;
		} catch (error) {
			console.error('Unable to fetch reports', solutionId, error);
			dispatch(enqueueError('reporting_unable_to_load_reports', error));
		}
	};

export const selectReportMetadataBySolutionId = (state: RootState, solutionId: string) => state.trading.reporting.reports[solutionId] as HistoricalReportMetadata | undefined;

export default reportingSlice.reducer;
