import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Button, Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridRowId, GridRowParams} from '@mui/x-data-grid';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shortDateTimeFormat, useDateTimeFormatter} from 'src/hooks/useDateTimeFormatter';
import {downloadFile} from 'src/lib/httpClient';
import {enqueueNotification} from 'src/reducers/appSlice';
import {IDeviceSchedule, IDeviceScheduleUpdate, IOTAFile, ISchedule, createOtaFileSchedule, getOTAFile, updateOtaFileSchedule} from 'src/reducers/engineering/otaFilesSlice';
import {makeStyles} from 'tss-react/mui';
import {useAppDispatch} from '../../app/store';
import ConfirmDialog from '../ConfirmDialog';
import CreateSchedulesDialog from './CreateSchedulesDialog';
import EditScheduleDialog from './EditScheduleDialog';

const useStyles = makeStyles()((theme) => {
	return {
		scheduleTitle: {
			color: theme.palette.primary.main,
			fontSize: '1.5rem',
		},
		scheduleFilename: {
			color: theme.palette.primary.main,
			fontSize: '1.5rem',
			maxWidth: '500px',
		},
	};
});

interface IScheduleListProps {
	otaFile: IOTAFile;
}
const OTASchedules = (props: IScheduleListProps) => {
	const {t} = useTranslation();
	const {otaFile} = props;
	const {classes} = useStyles();

	const [showCreateSchedulesDialog, setShowAddScheduleDialog] = useState<boolean>(false);
	const [showDeleteScheduleDialog, setShowDeleteScheduleDialog] = useState<boolean>(false);
	const [showEditScheduleDialog, setShowEditScheduleDialog] = useState<boolean>(false);
	const [selectedSchedule, setSelectedSchedule] = useState<ISchedule | null>(null);

	const logFileDownloadRef = useRef<HTMLAnchorElement | null>(null);

	const dispatch = useAppDispatch();

	const dateTimeFormatter = useDateTimeFormatter('en-GB', shortDateTimeFormat);

	const refreshSchedules = () => {
		dispatch(getOTAFile(otaFile._id));
	};

	const addSchedules = (schedules: IDeviceSchedule[]) => {
		if (schedules.length > 0) {
			dispatch(createOtaFileSchedule(otaFile._id, schedules));
		}
	};

	const updateSchedule = (update: IDeviceScheduleUpdate) => {
		dispatch(updateOtaFileSchedule(otaFile._id, [update]));
	};

	const selectSchedule = useCallback(
		(id: GridRowId, mode: 'edit' | 'delete') => () => {
			const targetSchedule = otaFile.schedules.find((s) => s._id === id);
			if (targetSchedule) {
				setSelectedSchedule(targetSchedule);
				if (mode === 'edit') {
					setShowEditScheduleDialog(true);
				} else {
					setShowDeleteScheduleDialog(true);
				}
			}
		},
		[otaFile],
	);

	const downloadInstallLog = useCallback(
		(scheduleId: string, filename: string) => {
			const dlUrl = `/api/engineering/otafiles/${otaFile._id}/schedule/${scheduleId}/log`;
			downloadFile(dlUrl, filename).catch((error) => {
				console.log('Download failed', error);
				dispatch(enqueueNotification('download_failed', 'error'));
			});
		},
		[otaFile],
	);

	const columns = useMemo<GridColDef<ISchedule>[]>(
		() => [
			{
				field: 'solutionDisplayName',
				headerName: t('solution'),
				valueGetter: (_value, row) => row.solution?.displayName || '',
				type: 'string',
				flex: 1,
			},
			{
				field: 'deviceId',
				headerName: t('device'),
				type: 'string',
				width: 400,
			},
			{
				field: 'desiredInstallTime',
				headerName: t('desired_install_time'),
				valueFormatter: dateTimeFormatter,
				type: 'dateTime',
				width: 250,
			},

			{
				field: 'scheduledInstallTime',
				headerName: t('scheduled_install_time'),
				valueFormatter: dateTimeFormatter,
				type: 'dateTime',
				width: 250,
			},
			{
				field: 'status',
				headerName: t('status'),
				valueGetter: (_value, row) => t(row.status || 'not_available_short'),
				type: 'string',
				width: 200,
			},
			{
				field: 'reason',
				headerName: t('ota_fail_reason'),
				renderCell: (params: GridCellParams) => {
					if (params.row.status === 'install_failed' && params.row.reason === `${params.row._id}_log.zip`) {
						return (
							<span
								style={{cursor: 'pointer', color: 'blue', textDecorationLine: 'underline'}}
								onClick={() => {
									downloadInstallLog(params.row._id, params.row.reason);
								}}
							>
								{t('download_logs')}
							</span>
						);
					} else {
						return params.row.reason || '';
					}
				},
				type: 'string',
				width: 250,
			},
			{
				field: 'actions',
				type: 'actions',
				getActions: (params: GridRowParams) => [
					<GridActionsCellItem data-test={'delete-schedule_' + params.id} icon={<DeleteIcon />} disabled={params.row.archived} onClick={selectSchedule(params.id, 'delete')} label="Delete" />,
					<GridActionsCellItem data-test={'edit-schedule_' + params.id} icon={<EditIcon />} disabled={params.row.archived} onClick={selectSchedule(params.id, 'edit')} label="Edit" />,
				],
			},
		],
		[t, selectSchedule],
	);
	return (
		<Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', minHeight: 0, minWidth: 0}}>
			<Stack sx={{width: '100%', mb: 1}} direction={'row'} alignItems="center" justifyContent={'space-between'}>
				<Typography variant="body1" noWrap className={classes.scheduleTitle}>
					{t('schedules')}
				</Typography>
				<Typography variant="body1" noWrap className={classes.scheduleFilename}>
					{otaFile.filename}
				</Typography>

				<Box>
					<Button
						data-test="add_schedule"
						size="small"
						variant="contained"
						startIcon={<AddAlarmIcon />}
						onClick={() => {
							setShowAddScheduleDialog(true);
						}}
					>
						{t('add_schedule')}
					</Button>
					<Button
						data-test="refresh_schedules"
						sx={{ml: 1}}
						size="small"
						variant="contained"
						startIcon={<RefreshIcon />}
						onClick={() => {
							refreshSchedules();
						}}
					>
						{t('refresh_schedules')}
					</Button>
				</Box>
			</Stack>
			<DataGrid
				className="ota_schedules"
				rows={otaFile.schedules}
				getRowId={(row: any) => row._id}
				columns={columns}
				autoPageSize
				onRowClick={(params) => {
					//onSelect(params.id as string);
				}}
			/>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content*/}
			<a ref={logFileDownloadRef} hidden id="file-download" />
			{showCreateSchedulesDialog && (
				<CreateSchedulesDialog
					open={true}
					onClose={(selectedDevices) => {
						setShowAddScheduleDialog(false);
						addSchedules(selectedDevices);
					}}
				/>
			)}
			{showEditScheduleDialog && selectedSchedule && (
				<EditScheduleDialog
					open={true}
					schedule={selectedSchedule}
					onClose={(scheduleUpdate) => {
						if (scheduleUpdate !== undefined) {
							updateSchedule(scheduleUpdate);
						}
						setShowEditScheduleDialog(false);
						setSelectedSchedule(null);
					}}
				/>
			)}
			{showDeleteScheduleDialog && selectedSchedule && (
				<ConfirmDialog
					dialogText={t('delete_schedule')}
					open={true}
					handleClose={() => {
						setShowDeleteScheduleDialog(false);
						setSelectedSchedule(null);
					}}
					handleConfirmClick={() => {
						if (selectedSchedule) {
							updateSchedule({
								scheduleId: selectedSchedule._id!,
								scheduledInstallTime: null,
							});
						}
						setShowDeleteScheduleDialog(false);
						setSelectedSchedule(null);
					}}
				/>
			)}
		</Box>
	);
};
export default OTASchedules;
