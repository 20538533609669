// the hook
import {Button, CircularProgress, CircularProgressProps, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {enqueueNotification} from 'src/reducers/appSlice';
import {uploadOTAFileWithProgress} from 'src/reducers/engineering/otaFilesSlice';
import {makeStyles} from 'tss-react/mui';
import {useAppDispatch} from '../../app/store';

// Make sure to sync this with Backend
const FILE_MAX_SIZE = 1024 * 1024 * 1024; // bytes ~1GB

const CircularProgressWithLabel = (props: CircularProgressProps & {value: number}) => {
	const value = Math.round(props.value);
	return (
		<Box sx={{position: 'relative', display: 'inline-flex'}}>
			<CircularProgress variant={value < 100 ? 'determinate' : 'indeterminate'} {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant="h3" component="div" color="text.secondary">{`${value}%`}</Typography>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles()((theme) => {
	return {
		uploadTitle: {
			color: theme.palette.primary.main,
			fontSize: '1.5rem',
		},
		uploadIcon: {
			fontSize: '3rem',
			color: theme.palette.primary.main,
		},
	};
});

const validateFilename = (filename: string) => {
	const filenameRegExp = new RegExp('^[\\w\\d\\-\\.() ]{1,196}\\.(deb|zip)$');
	return filenameRegExp.test(filename) ? true : false;
};

const FileSelect = () => {
	const {t} = useTranslation();
	const {classes} = useStyles();

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [progress, setProgress] = useState<number>(0);
	const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
	const [filename, setFilename] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const cancelUploadRef = useRef<AbortController | null>(null);
	const fileNameRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		// Cancel upload when navigating away
		return () => {
			onCancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [selectedFileType, setSelectedFileType] = useState<string>('');
	const fileTypes = useMemo(() => {
		return [
			{
				type: 'deb',
				displayName: t('file_type_debian'),
			},
			{
				type: 'firmware',
				displayName: t('file_type_firmware'),
			},
		];
	}, [t]);

	const isFilenameValid = useMemo(() => {
		if (filename !== null) {
			return validateFilename(filename);
		}
		return false;
	}, [filename]);

	const dispatch = useAppDispatch();

	const validateSelectedFile = (file: File) => {
		if (file.size > FILE_MAX_SIZE) {
			dispatch(enqueueNotification('ota_file_size_exceeds_limit', 'error'));
			reset();
			return;
		}
		if (!validateFilename(file.name)) {
			dispatch(enqueueNotification('ota_file_wrong_name', 'error'));
			reset();
			return;
		}
		setSelectedFile(file);
		setFilename(file.name);

		// Select default file type based on extension
		if (file.name.endsWith('.deb')) {
			setSelectedFileType('deb');
		} else if (file.name.endsWith('.zip')) {
			setSelectedFileType('firmware');
		}
	};

	const handleFileSelect = (event: any) => {
		const file = event.target.files[0] as File;
		validateSelectedFile(file);
	};

	const onDragOver = (e: any) => {
		// Prevent browser from opening the file
		e.preventDefault();
	};

	const fileDropped = (dropEvent: any) => {
		dropEvent.preventDefault();
		dropEvent.stopPropagation();

		if (dropEvent.dataTransfer.files && dropEvent.dataTransfer.files[0]) {
			validateSelectedFile(dropEvent.dataTransfer.files[0]);
		}
	};
	const browseClicked = (e: any) => {
		if (fileInputRef.current !== null) {
			fileInputRef.current.click();
		}
	};

	const onCancel = () => {
		if (cancelUploadRef.current) {
			cancelUploadRef.current.abort();
		}
		reset();
	};

	const reset = () => {
		setSelectedFile(null);
		setSelectedFileType('');
		setFilename(null);
		resetFileInput();
		setProgress(0);
		cancelUploadRef.current = null;
	};

	const onInputChange = (e: any) => {
		setFilename(e.target.value);
	};

	const onUpload = async () => {
		if (selectedFile !== null && filename && isFilenameValid === true) {
			setUploadInProgress(true);
			cancelUploadRef.current = new AbortController();
			await dispatch(
				uploadOTAFileWithProgress(
					selectedFile,
					(progress: number) => {
						if (setProgress) {
							setProgress(progress);
						}
					},
					cancelUploadRef.current.signal,
					selectedFileType,
					filename,
				),
			);
			setUploadInProgress(false);
			reset();
		}
	};

	const resetFileInput = () => {
		// File input has on change listener
		// need to reset it to be able to select same file again.
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	// Renders hidden input field to select files
	return (
		<Box sx={{mt: 1}}>
			<input ref={fileInputRef} hidden accept=".deb,.zip" data-test="ota_file_input" multiple={false} type="file" onChange={handleFileSelect} id="file-input" />
			{!selectedFile ? (
				<Box
					sx={{
						height: '200px',
						width: '100%',
						backgroundColor: '#eef2f4',
						borderRadius: '5px',
						border: '1px solid rgba(224, 224, 224, 1)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onDrop={fileDropped}
					onDragOver={onDragOver}
					onClick={browseClicked}
				>
					<FileUploadIcon className={classes.uploadIcon} />
					<Typography variant="body1" className={classes.uploadTitle}>
						{t('upload_instructions')}
					</Typography>
				</Box>
			) : (
				<Box
					sx={{
						height: '200px',
						width: '100%',
						backgroundColor: '#eef2f4',
						borderRadius: '5px',
						border: '1px solid rgba(224, 224, 224, 1)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Stack direction={'row'} spacing={2} alignItems={'center'}>
						{uploadInProgress && <CircularProgressWithLabel size={100} value={progress} />}
						<TextField
							inputProps={{
								autoComplete: 'new-password',
								'data-test': 'ota_filename_input',
							}}
							disabled={uploadInProgress}
							error={isFilenameValid === false}
							inputRef={fileNameRef}
							variant="outlined"
							label="Rename file"
							id="filled-size-normal"
							defaultValue={selectedFile.name}
							onChange={onInputChange}
						/>

						<TextField
							inputProps={{
								'data-test': 'ota_filetype_input',
							}}
							select={true}
							label={t('file_type')}
							sx={{width: 120}}
							value={selectedFileType}
							disabled={uploadInProgress}
							onChange={(e) => setSelectedFileType(e.target.value)}
						>
							{fileTypes.map((p) => {
								return (
									<MenuItem key={p.type} value={p.type}>
										{p.displayName}
									</MenuItem>
								);
							})}
						</TextField>

						<Button data-test="upload_button" startIcon={<FileUploadIcon />} variant="contained" disabled={uploadInProgress || isFilenameValid === false || selectedFileType === ''} onClick={onUpload} color="primary">
							{t('upload')}
						</Button>
						<Button data-test="cancel_button" variant="contained" onClick={onCancel} color="warning">
							{t('cancel')}
						</Button>
					</Stack>
				</Box>
			)}
		</Box>
	);
};

export default FileSelect;
