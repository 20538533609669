import {createSelector, createSlice, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import {Socket} from 'socket.io-client';
import {AppThunk, RootState} from '../../app/store';
import {GET, POST, PUT} from '../../lib/httpClient';
import {setupSocketConnectionAsync} from '../../lib/socketClient';
import {enqueueError} from '../appSlice';

export interface IModule {
	// User can modify these
	hidden: boolean;
	displayName: string;

	// These are updated from the AZ function by "status" message handler
	id: string;
	moduleId: string;
	iotDeviceId: string;
	type: string;
	ip: string;
	port: string;
	state: string;
	timeStamp: string;
	serialNumber: string;
	firmware: string;
}

export type UpdateModuleType = Partial<Pick<IModule, 'hidden' | 'displayName'>>;

// "flattended" module entry.
export interface IModuleListEntry extends IModule {
	solutionDisplayName: string; // parent solution display name
	solutionId: string; // parent solution guid
	//id: string; // combination of iotDeviceId and moduleId to ensure it is unique (to be used as a key)
}

export interface IOrganization {
	name: string;
	_id: string;
}

export interface ISolution {
	id: string; // UUID of a solution
	displayName: string; // Human readable name for solution
	location: string;
	state: string; // last known state ok | issues | fatal
	organization: IOrganization; // organization object
	connected: boolean; // if IoT hub has connection to device
	type: string;
	iotDevices: string[];
	modules: Record<string, IModule>;
	timeZone: string; // like Europe/Helsinki
	singleLineDiagramConfiguration: string;
	expirationDate?: Date; // Date when the organization's access to the solution expires; if unset then the access does not expire
}

export type CreateSolutionType = Omit<ISolution, 'id' | 'modules' | 'state' | 'iotDevices'>;
export type UpdateSolutionType = Partial<Pick<ISolution, 'displayName' | 'location' | 'type' | 'organization' | 'expirationDate' | 'timeZone' | 'singleLineDiagramConfiguration'>>;

interface SolutionState {
	loading: boolean;
	hasError: boolean;
	solutions: {
		[key: string]: ISolution;
	};
}

const initialState: SolutionState = {
	loading: false,
	hasError: false,
	solutions: {},
};

export const solutionsSlice = createSlice({
	name: 'engineering/solutions',
	initialState,
	reducers: {
		getSolutions: (state) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer-library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.loading = true;
		},
		getSolutionsSuccess: (state, action: PayloadAction<any>) => {
			state.solutions = action.payload;
			state.loading = false;
			state.hasError = false;
		},

		// Updates or adds solution
		updateSolutionList: (state, action: PayloadAction<ISolution>) => {
			// N.B. don't try this without immer
			const id = action.payload.id;
			state.solutions[id] = action.payload;
		},
		// Use the PayloadAction type to declare the contents of `action.payload`
		getSolutionsFail: (state) => {
			state.hasError = true;
			state.loading = false;
		},
		removeSolution: (state, action: PayloadAction<string>) => {
			const solutions = {...state.solutions};
			const solutionId = action.payload;
			delete solutions[solutionId];
			state.solutions = solutions;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {getSolutions, getSolutionsSuccess, getSolutionsFail, updateSolutionList, removeSolution} = solutionsSlice.actions;

// Gets all solutions that user has access to from the backend.
export const getSolutionsAsync = (): AppThunk => async (dispatch) => {
	dispatch(getSolutions());

	try {
		const solutions = await GET<ISolution[]>('/api/engineering/solutions');
		if (solutions) {
			// create map of solutions for easier (write) access
			let sol: any = {};

			for (const s of solutions) {
				sol[s.id] = s;
			}

			dispatch(getSolutionsSuccess(sol));

			// Setup socket connection
			dispatch(setupSocketConnectionAsync())
				.then(() => {})
				.catch((err) => {
					console.error('Unable to setup socket connection!', err);
				});
		}
	} catch (error: any) {
		dispatch(getSolutionsFail());
		dispatch(enqueueError('unable_to_load_solutions', error));
	}
};

// Gets one solution that user has access to from the backend.
export const getSolutionDetailsAsync =
	(solutionId: string): AppThunk =>
	async (dispatch) => {
		try {
			const solution = await GET<ISolution>(`/api/engineering/solutions/${solutionId}`);
			dispatch(updateSolutionList(solution));
		} catch (error: any) {
			dispatch(enqueueError('unable_to_load_solution_details', error));
		}
	};

// Adds new solution and updates solution list
export const addSolutionAsync =
	(solution: CreateSolutionType): AppThunk<Promise<string | undefined>> =>
	async (dispatch) => {
		try {
			const newSolution = await POST<ISolution>('/api/engineering/solutions', solution);

			if (newSolution) {
				dispatch(updateSolutionList(newSolution));
				return newSolution.id;
			}
		} catch (error: any) {
			dispatch(enqueueError('unable_to_create_solution', error));
		}
	};

export const updateSolutionAsync =
	(solutionId: string, update: UpdateSolutionType): AppThunk<Promise<string | undefined>> =>
	async (dispatch) => {
		try {
			const updatedSolution = await PUT<ISolution>(`/api/engineering/solutions/${solutionId}`, update);
			if (updatedSolution) {
				dispatch(updateSolutionList(updatedSolution));
				return updatedSolution.id;
			}
		} catch (error: any) {
			dispatch(enqueueError('unable_to_update_solution', error));
		}
	};

export const updateSolutionModuleAsync =
	(solutionId: string, moduleId: string, update: UpdateModuleType): AppThunk =>
	async (dispatch) => {
		try {
			const updatedSolution = await PUT<ISolution>(`/api/engineering/solutions/${solutionId}/${moduleId}`, update);
			dispatch(updateSolutionList(updatedSolution));
		} catch (error: any) {
			dispatch(enqueueError('unable_to_update_solution_module', error));
		}
	};

/**
 * Register solution related socket event handlers
 * @param dispatch
 * @param socket
 */
export const registerSolutionEventHandlers = (dispatch: ThunkDispatch<any, any, any>, socket: Socket) => {
	socket.on('solutionUpdate', (updatedSolution: ISolution) => {
		dispatch(updateSolutionList(updatedSolution));
	});
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const SelectSolutionsRoot = (state: RootState) => state.engineering.solutions;
//export const infoLoading = (state: RootState) => state.info.loading;
//export const infoError = (state: RootState) => state.info.hasError;

export const selectSolutionsLoading = (state: RootState) => state.engineering.solutions.loading;

const getSolutionId = (_state: RootState, solutionId: string) => solutionId;
const getModuleId = (_state: RootState, _solutionId: string, moduleId: string) => moduleId;

export const selectSolutionAsArray = createSelector([SelectSolutionsRoot], (solutions) => {
	if (solutions) {
		return Object.values(solutions.solutions);
	}
	return [];
});

export const selectSolutionById = createSelector([SelectSolutionsRoot, getSolutionId], (solutions, solutionId: string) => solutions.solutions[solutionId]);

// Returns all modules for a solution
export const selectModulesBySolutionId = createSelector([SelectSolutionsRoot, getSolutionId], (solutions, solutionId) => {
	const solution = solutions.solutions[solutionId];
	return Object.values(solution?.modules || {}).map((m) => Object.assign({}, m, {solutionDisplayName: solution.displayName, solutionId: solution.id}));
});

// Returns a list of modules which are not flagged as hidden
export const selectVisibleModulesBySolutionId = createSelector([SelectSolutionsRoot, getSolutionId], (solutions, solutionId) => {
	const solution = solutions.solutions[solutionId];
	let modules = Object.values(solution?.modules || {}).map((m) => Object.assign({}, m, {solutionDisplayName: solution.displayName, solutionId: solution.id}));
	return modules.filter((module) => !module.hidden);
});

// Returns "flattened" list of modules
export const selectAllModulesInSolutions = createSelector(selectSolutionAsArray, (solutions) => {
	let allModules: IModuleListEntry[] = [];

	for (const s of solutions) {
		const modules = Object.values(s.modules || {});
		const moduleListEntries: IModuleListEntry[] = modules.map((m) => Object.assign({}, m, {solutionDisplayName: s.displayName, solutionId: s.id}));
		allModules = allModules.concat(moduleListEntries);
	}
	return allModules;
});

export const selectAllFaultedModulesInSolutions = createSelector(selectSolutionAsArray, (solutions) => {
	// TODO: implement trips & alarms
	let modules: IModule[] = [];
	return modules;
});

export const selectAllDisconnectedModulesInSolutions = createSelector(selectSolutionAsArray, (solutions) => {
	let disconnectedModules: IModuleListEntry[] = [];

	for (const s of solutions) {
		const modules = Object.values(s.modules || {});
		const moduleListEntries: IModuleListEntry[] = modules.filter((m) => m.state === 'Disconnected').map((m) => Object.assign({}, m, {solutionDisplayName: s.displayName, solutionId: s.id}));
		disconnectedModules = disconnectedModules.concat(moduleListEntries);
	}
	return disconnectedModules;
});

export const selectModuleById = createSelector([SelectSolutionsRoot, getSolutionId, getModuleId], (solutions, solutionId, deviceId) => {
	const solution = solutions.solutions[solutionId];
	const selectedDevice = solution?.modules[deviceId];
	return selectedDevice;
});

export default solutionsSlice.reducer;
