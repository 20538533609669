import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {GET, PUT, POST} from '../../lib/httpClient';
import {enqueueError, enqueueNotification} from '../appSlice';
import {AvailableMarkets} from 'src/lib/reserveMarketConstants';
import {closeSnackbar} from 'notistack';
import {clearSolutionDataAction} from '../../app/actions';

/**
 * Trading configuration as returned by the backend
 */
export interface ITradingConfig {
	ecpConfiguration: {
		eicCode: string;
		edxHost?: string;
		edxPort?: number;
		edxUsername?: string;
		edxPasswordSet: boolean; // Metadata field. True if password has been set
	};
	enabledMarkets?: AvailableMarkets[];
	maximumEnergyCapacity: number;
	maximumOutputPower: number;
	optibidIdentifier?: string;
}

/**
 * Initial trading configuration.
 * Fields required to crate trading configuration (POST).
 */
export interface IInitialTradingConfig {
	ecpConfiguration: {
		eicCode: string;
		edxHost?: string;
		edxPort?: number;
		edxUsername?: string;
		edxPassword?: string;
	};
	enabledMarkets?: AvailableMarkets[];
	maximumEnergyCapacity: number;
	maximumOutputPower: number;
}

/**
 * Trading configuration update (PUT) payload.
 * Null values will remove field from DB.
 */
export interface ITradingConfigChanges {
	ecpConfiguration?: {
		eicCode?: string;
		edxHost?: string | null;
		edxPort?: number | null;
		edxUsername?: string | null;
		edxPassword?: string | null;
	};
	enabledMarkets?: AvailableMarkets[];
	maximumEnergyCapacity?: number;
	maximumOutputPower?: number;
}

export interface ITradingConfigResponse extends ITradingConfig {
	solutionId: string;
}

interface tradingConfigState {
	tradingConfig: {
		[key: string]: ITradingConfigResponse;
	};
}

const initialState: tradingConfigState = {
	tradingConfig: {},
};

export const tradingConfigSlice = createSlice({
	name: 'trading/tradingconfig',
	initialState,
	reducers: {
		setTradingConfig: (state, action: PayloadAction<ITradingConfigResponse>) => {
			const id = action.payload.solutionId;
			state.tradingConfig[id] = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});

		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.tradingConfig[action.payload];
		});
	},
});

export const {setTradingConfig} = tradingConfigSlice.actions;

export const getTradingConfigAsync =
	(solutionId: string): AppThunk<Promise<ITradingConfigResponse | undefined>> =>
	async (dispatch) => {
		try {
			const response = await GET<ITradingConfigResponse>(`/api/trading/tradingconfig/${solutionId}`);
			dispatch(setTradingConfig(response));

			return response;
		} catch (error) {
			dispatch(enqueueError('unable_to_load_trading_configuration', error));
			console.error('Unable to load Trading solution configuration', solutionId, error);
		}
	};

export const updateTradingConfigAsync =
	(solutionId: string, tradingConfig: ITradingConfigChanges): AppThunk<Promise<ITradingConfigResponse | undefined>> =>
	async (dispatch) => {
		let notificationId: string | undefined;

		try {
			notificationId = dispatch(enqueueNotification('trading_config_verification_in_progress', 'warning'));
			const response = await PUT<ITradingConfigResponse>(`/api/trading/tradingconfig/${solutionId}`, tradingConfig);
			closeSnackbar(notificationId);

			dispatch(setTradingConfig(response));
			dispatch(enqueueNotification('trading_config_updated', 'success'));
			return response;
		} catch (error: any) {
			closeSnackbar(notificationId);

			dispatch(enqueueError('unable_to_update_trading_config', error));
			console.log(error);
		}
	};

export const createTradingConfigAsync =
	(solutionId: string, newConfig: IInitialTradingConfig): AppThunk<Promise<ITradingConfigResponse | undefined>> =>
	async (dispatch) => {
		let notificationId: string | undefined;

		try {
			notificationId = dispatch(enqueueNotification('trading_config_verification_in_progress', 'warning'));
			const response = await POST<ITradingConfigResponse>(`/api/trading/tradingconfig/${solutionId}`, newConfig);
			closeSnackbar(notificationId);

			dispatch(setTradingConfig(response));
			dispatch(enqueueNotification('trading_config_created', 'success'));
			return response;
		} catch (error: any) {
			closeSnackbar(notificationId);

			dispatch(enqueueError('unable_to_create_trading_config', error));
			console.log(error);
		}
	};

export const selectTradingConfigBySolutionId = (state: RootState, solutionId: string): ITradingConfigResponse | undefined => state.trading.tradingConfig?.tradingConfig[solutionId];

export default tradingConfigSlice.reducer;
