import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {DELETE, GET, POST, PUT, uploadFileProgress} from '../../lib/httpClient';
import {enqueueError, enqueueNotification} from '../appSlice';

export const ScheduleStatuses = ['not_started', 'downloading', 'scheduled', 'download_failed', 'install_started', 'install_succeeded', 'install_failed', 'config_error', 'cancelled'] as const;

export type ScheduleStatus = (typeof ScheduleStatuses)[number];

export interface ISchedule {
	_id?: string;
	solution: {
		_id: string;
		id: string;
		displayName: string;
	};
	deviceId: string; // device id in IoT portal
	created: string;
	fileUrl: string; // Sas token that is valid to at least scheduled install time
	archived: boolean; //  To prevent editing and further processing

	// These are synced from twins reported properties
	scheduledInstallTime?: Date;
	status?: ScheduleStatus;
	reason?: string;

	// After schedule has been created user may update this field.
	desiredInstallTime: string | null; // set to null to indicate cancellation
}

export interface IOTAFile {
	_id: string;
	filename: string; // Human readable filename
	fileHandle: string; // File handle (filename) in azure blob storage
	fileType: string;
	created: string; // ISO String
	size: number;
	mime: string;
	hidden: boolean; // If there are schedules that are deployed, mark file deleted. If there are no deployments just delete the file and DB entry
	schedules: ISchedule[];
	md5: string;
}

interface IOTAFilesState {
	otaFiles: IOTAFile[] | undefined;
	selectedOTAFileId: string | undefined; // File id
}

const initialState: IOTAFilesState = {
	otaFiles: undefined,
	selectedOTAFileId: undefined,
};

export const otaFilesSlice = createSlice({
	name: 'engineering/otaFiles',
	initialState,
	reducers: {
		setOTAFiles: (state, action: PayloadAction<IOTAFile[]>) => {
			state.otaFiles = action.payload;
		},
		updateOTAFile: (state, action: PayloadAction<IOTAFile>) => {
			if (state.otaFiles === undefined) {
				state.otaFiles = [action.payload];
			} else {
				const index = state.otaFiles.findIndex((o) => o._id === action.payload._id);
				if (index === -1) {
					state.otaFiles.unshift(action.payload);
				} else {
					state.otaFiles[index] = action.payload;
				}
			}
		},
		setSelectedOTAFileId: (state, action: PayloadAction<string | undefined>) => {
			state.selectedOTAFileId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
	},
});

export const {setOTAFiles, updateOTAFile, setSelectedOTAFileId} = otaFilesSlice.actions;

export const getOTAFiles = (): AppThunk => (dispatch) => {
	GET<IOTAFile[]>(`/api/engineering/otafiles`)
		.then((response) => {
			dispatch(setOTAFiles(response));
		})
		.catch((error) => {
			console.error('Unable to get ota files', error);
			dispatch(enqueueError('ota_files_list_failed', error));
		});
};

// Refreshes single ota file
export const getOTAFile =
	(fileId: string | number): AppThunk =>
	(dispatch) => {
		GET<IOTAFile>(`/api/engineering/otafiles/${fileId}`)
			.then((response) => {
				dispatch(updateOTAFile(response));
			})
			.catch((error) => {
				console.error('Unable to get ota file', error);
				dispatch(enqueueError('ota_files_list_failed', error));
			});
	};

// Download single ota file
export const downloadOTAFile =
	(fileId: string): AppThunk =>
	async (dispatch) => {
		try {
			const response = await GET<{fileUrl: string}>(`/api/engineering/otafiles/${fileId}/download`);
			return response.fileUrl;
		} catch (error) {
			console.error(error);
			dispatch(enqueueError('download_failed', error));
		}
	};

export const deleteOTAFile =
	(fileId: string | number): AppThunk =>
	(dispatch) => {
		DELETE<IOTAFile[]>(`/api/engineering/otafiles/${fileId}`)
			.then((response) => {
				dispatch(setOTAFiles(response));
			})
			.catch((error) => {
				console.error('Unable to delete ota file', error);
				dispatch(enqueueError('ota_file_delete_failed', error));
			});
	};

export const uploadOTAFileWithProgress =
	(file: File, progressCb: (p: number) => void, abortSignal: AbortSignal, fileType: string, alternativeFilename?: string): AppThunk =>
	async (dispatch) => {
		const formData = new FormData();
		formData.append('ota-file', file);
		if (alternativeFilename !== undefined) {
			formData.append('filename', alternativeFilename);
		}
		formData.append('fileType', fileType);
		try {
			const response = await uploadFileProgress<IOTAFile>(`/api/engineering/otafiles`, formData, progressCb, abortSignal);
			dispatch(updateOTAFile(response));
		} catch (error: any) {
			console.error('Upload failed', error);

			if (error && error.type === 'abort') {
				dispatch(enqueueNotification('upload_cancelled', 'warning'));
			} else {
				dispatch(enqueueError('upload_failed', error));
			}
		}
	};

export interface IDeviceSchedule {
	deviceId: string;
	scheduledInstallTime: string; // ISO Date string
}

export interface IDeviceScheduleUpdate {
	scheduleId: string;
	scheduledInstallTime: string | null; // ISO Date string
}

export const createOtaFileSchedule =
	(fileId: string | number, schedules: IDeviceSchedule[]): AppThunk =>
	(dispatch) => {
		POST<IOTAFile>(`/api/engineering/otafiles/${fileId}/schedule`, schedules)
			.then((response) => {
				dispatch(updateOTAFile(response));
			})
			.catch((error) => {
				console.error('Unable to create ota schedule', error);
				dispatch(enqueueError('ota_file_schedule_failed', error));
			});
	};

export const updateOtaFileSchedule =
	(fileId: string | number, schedules: IDeviceScheduleUpdate[]): AppThunk =>
	(dispatch) => {
		PUT<IOTAFile>(`/api/engineering/otafiles/${fileId}/schedule`, schedules)
			.then((response) => {
				dispatch(updateOTAFile(response));
			})
			.catch((error) => {
				console.error('Unable to update ota schedule(s)', error);
				dispatch(enqueueError('ota_file_schedule_failed', error));
			});
	};

export const selectAllOTAFiles = (state: RootState) => state.engineering.otaFiles.otaFiles;
export const getSelectedOTAFileId = (state: RootState) => state.engineering.otaFiles.selectedOTAFileId;
export default otaFilesSlice.reducer;
