// the hook
import {LinearProgress} from '@mui/material';
import {Box} from '@mui/system';
import {DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowParams, GridSlots} from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {shortDateTimeFormatWithSeconds, useDateTimeFormatter} from 'src/hooks/useDateTimeFormatter';
import {GET} from 'src/lib/httpClient';
import {enqueueError} from 'src/reducers/appSlice';
import {IOTAFile, ISchedule, deleteOTAFile, getOTAFiles, selectAllOTAFiles} from 'src/reducers/engineering/otaFilesSlice';
import {useAppDispatch} from '../../app/store';
import ConfirmDialog from '../ConfirmDialog';
import FileSelect from './FileSelect';

function getHumanReadableSize(value?: number) {
	if (value !== undefined) {
		return `${Math.ceil(value / 1024)} KB`;
	} else {
		return 'N/A';
	}
}

interface IOTAFileListProps {
	onFileLinkClick?: (fileId: string) => void;
}

const OTAFileList = (props: IOTAFileListProps) => {
	const otaFiles = useSelector(selectAllOTAFiles);
	const {t} = useTranslation();
	const dateTimeFormatter = useDateTimeFormatter('en-GB', shortDateTimeFormatWithSeconds);

	const {onFileLinkClick} = props;

	const [showDeleteFileDialog, setShowDeleteFileDialog] = useState<boolean>(false);
	const [selectedFile, setSelectedFile] = useState<IOTAFile | null>(null);

	const fileDownloadRef = useRef<HTMLAnchorElement | null>(null);

	const dispatch = useAppDispatch();
	// Get list of OTA files
	useEffect(() => {
		dispatch(getOTAFiles());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deleteFile = useCallback(
		(id: GridRowId) => () => {
			const file = otaFiles?.find((f) => f._id === id);
			if (file) {
				setSelectedFile(file);
				setShowDeleteFileDialog(true);
			} else {
				setSelectedFile(null);
			}
		},
		[otaFiles],
	);

	const download = useCallback(
		(file: IOTAFile) => async () => {
			try {
				const response = await GET<{fileUrl: string}>(`/api/engineering/otafiles/${file._id}/download`);
				const a = fileDownloadRef.current;
				if (a) {
					a.href = response.fileUrl;
					a.click();
				}
			} catch (error) {
				dispatch(enqueueError('download_failed', error));
				console.log(error);
			}
		},
		[fileDownloadRef, dispatch],
	);

	// Schedule cannot be removed/archived if it has active schedules
	const hasActiveSchedules = useCallback((schedules: ISchedule[]) => {
		return schedules.some((s) => !s.archived);
	}, []);

	const allSchedulesAreArchived = useCallback((schedules: ISchedule[]) => {
		return schedules.length > 0 && schedules.every((s) => s.archived);
	}, []);

	const columns = useMemo<GridColDef<IOTAFile>[]>(
		() => [
			{
				field: 'filename',
				headerName: t('file_name'),
				flex: 1,
				renderCell: (params) => (
					<span
						style={{cursor: 'pointer', color: 'blue', textDecorationLine: 'underline'}}
						onClick={() => {
							if (onFileLinkClick) {
								onFileLinkClick(params.row._id);
							}
						}}
					>
						{params.value}
					</span>
				),
			},
			{field: 'fileType', headerName: t('file_type'), valueGetter: (_value, row) => (row.fileType === 'deb' ? t('file_type_debian') : t('file_type_firmware')), width: 100, type: 'string'},
			{field: 'schedules', headerName: t('file_scheduled'), valueGetter: (_value, row) => row.schedules?.length || '', width: 120, type: 'string'},
			{field: 'created', headerName: t('file_uploaded'), width: 300, valueFormatter: dateTimeFormatter, type: 'dateTime'},
			{field: 'size', headerName: t('file_size'), width: 150, valueFormatter: getHumanReadableSize},
			{
				field: 'actions',
				type: 'actions',
				getActions: (params: GridRowParams<IOTAFile>) => [
					<GridActionsCellItem icon={<DeleteIcon />} disabled={hasActiveSchedules(params.row.schedules)} onClick={deleteFile(params.id)} label="Delete" />,
					<GridActionsCellItem icon={<FileDownloadIcon />} data-test={'download_ota_file_' + params.id} onClick={download(params.row)} label="Download" />,
				],
			},
		],
		[deleteFile, t, hasActiveSchedules, download, onFileLinkClick],
	);

	return (
		<Box
			sx={{
				height: '100%',
				p: 0,
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				minHeight: 0,
				minWidth: 0,
			}}
		>
			<FileSelect />
			<Box sx={{flexGrow: 1, mt: 1, minHeight: 0, minWidth: 0}} data-test="ota_file_list_container">
				<DataGrid
					loading={otaFiles === undefined}
					slots={{
						loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
					}}
					rows={otaFiles || []}
					getRowId={(row) => row._id as string}
					columns={columns}
					autoPageSize
				/>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content*/}
				<a ref={fileDownloadRef} hidden id="file-download" />
			</Box>
			{showDeleteFileDialog && selectedFile && (
				<ConfirmDialog
					dialogText={allSchedulesAreArchived(selectedFile.schedules) ? t('archive_file') : t('delete_file')}
					open={true}
					handleClose={() => {
						setShowDeleteFileDialog(false);
					}}
					handleConfirmClick={() => {
						dispatch(deleteOTAFile(selectedFile._id));
						setShowDeleteFileDialog(false);
					}}
				/>
			)}
		</Box>
	);
};
export default OTAFileList;
