// import { useSelector, useDispatch } from "react-redux";
import {useIsAuthenticated} from '@azure/msal-react';
import {Box} from '@mui/material';
import {Navigate, Outlet} from 'react-router-dom';
export interface ToolLayoutProps {
	topbar: any;
}

export const topBarHeight = '65px';
/**
 * Renders main layout of wise app.
 * @param props Top menu component
 * @returns
 */
const ToolLayout = (props: ToolLayoutProps) => {
	const isAuthenticated: boolean = useIsAuthenticated();

	// All views sharing tool layout should only
	// be accessible to authenticated users.
	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}

	return (
		<Box
			sx={{
				display: 'grid',
				height: '100vh',
				width: '100vw',
				gridTemplateAreas: ['"header" "main"'],
				gridTemplateColumns: '100vw',
				gridTemplateRows: `${topBarHeight} 1fr`,
			}}
		>
			<Box
				sx={{
					gridArea: 'header',
				}}
			>
				{props.topbar}
			</Box>
			<Box
				sx={{
					gridArea: 'main',
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
};

export default ToolLayout;
