import {Aggregate, ArithmeticOperator, ComparisonOperator, HighLevelOperator, IComparison, ITransformRule, LogicalOperator, logicalOperators} from 'src/reducers/uiConfig.types';
import {IMomentaryValues, ITransformedValue} from '../reducers/momentaryValuesSlice';

const DEFAULT_TARGET_MODULE = '#solution';

const logicalOperations: Record<LogicalOperator, (values: boolean[]) => boolean> = {
	and: (values: boolean[]): boolean => {
		return values.every((v) => v === true);
	},
	or: (values: boolean[]): boolean => {
		return values.some((v) => v === true);
	},
	/*
	xor: (values: boolean[]): boolean => {
		return values.some((v) => v === true) && !values.every((v) => v === true);
	},
    */
};

const arithmeticOperations: Record<ArithmeticOperator, (values: number[]) => number> = {
	avg: (values: number[]): number => {
		if (values.length === 0) return NaN;
		const sum = values.reduce((s, c) => s + c, 0);
		const value = sum / values.length;
		return value;
	},
	sum: (values: number[]): number => {
		// empty sum is 0(?)
		const sum = values.reduce((s, c) => s + c, 0);
		return sum;
	},
	multiply: (values: number[]): number => {
		if (values.length === 0) return NaN;
		const sum = values.reduce((s, c) => s * c, 1);
		return sum;
	},
	div: (values: number[]): number => {
		if (values.length !== 2) {
			throw new Error(`Incorrect amount of arguments`);
		}
		// returns Infinity if values[1] is zero
		return values[0] / values[1];
	},
};

const comparisonsOperations: Record<ComparisonOperator, (values: any, referenceValue: any) => boolean> = {
	eq: (value: number, referenceValue): boolean => {
		return value === referenceValue;
	},
	gt: (value: number, referenceValue): boolean => {
		return value > referenceValue;
	},
	gte: (value: number, referenceValue): boolean => {
		return value >= referenceValue;
	},
	lt: (value: number, referenceValue): boolean => {
		return value < referenceValue;
	},
	lte: (value: number, referenceValue): boolean => {
		return value <= referenceValue;
	},
	bitmask: (value: number, referenceValue): boolean => {
		return Boolean(value & referenceValue);
	},
};

const highLevelOperations: Record<HighLevelOperator, (values: number[]) => number> = {
	calcPF: (values: number[]): number => {
		if (values.length !== 6) {
			throw new Error(`Incorrect amount of arguments`);
		}
		const [A1, A2, A3, R1, R2, R3] = values;
		const P = A1 + A2 + A3;
		const Q = R1 + R2 + R3;

		if (Q === 0 && P === 0) {
			return NaN;
		}

		let PF = Math.cos(Math.atan2(Q, P));
		if (Q < 0) {
			PF = -PF;
		}
		return PF;
	},

	calcS: (values: number[]): number => {
		if (values.length !== 7) {
			throw new Error(`Incorrect amount of arguments`);
		}

		const getApparentPower = (P: number, Q: number, mult: number) => {
			return Math.sqrt(P * P + Q * Q) * mult;
		};

		const [A1, A2, A3, R1, R2, R3, mult] = values;
		const S1 = getApparentPower(A1, R1, mult);
		const S2 = getApparentPower(A2, R2, mult);
		const S3 = getApparentPower(A3, R3, mult);
		return S1 + S2 + S3;
	},

	loadRate: (values: number[]): number => {
		if (values.length !== 4) {
			throw new Error(`Incorrect amount of arguments`);
		}
		const [A1, A2, A3, ANominal] = values;
		const rate = (Math.max(A1, A2, A3) / ANominal) * 100;
		return rate;
	},
};

const validateInputValues = (inputValues: (number | boolean | undefined)[], expectedType: 'boolean' | 'number') => {
	for (const v of inputValues) {
		if (typeof v !== expectedType) {
			throw new Error(`Invalid transform rule operation input value type. Expected ${expectedType} Actual:${typeof v}`);
		}
	}
	return true;
};

export const transform = (transformRules: Record<string, ITransformRule>, momentaryValues: IMomentaryValues) => {
	const values = new Map<string, number | boolean | undefined>();

	// Applies operation on input values. Returns a single value - number or boolean.
	const applyOperation = (operation: string, inputValues: (number | boolean | undefined)[]) => {
		if (operation in logicalOperators) {
			validateInputValues(inputValues, 'boolean');
			const opFunc = logicalOperations[operation as LogicalOperator];
			return opFunc(inputValues as boolean[]);
		} else if (operation in arithmeticOperations) {
			validateInputValues(inputValues, 'number');
			const opFunc = arithmeticOperations[operation as ArithmeticOperator];
			return opFunc(inputValues as number[]);
		} else if (operation in highLevelOperations) {
			const opFunc = highLevelOperations[operation as HighLevelOperator];
			return opFunc(inputValues as number[]);
		} else {
			throw new Error(`Unknown operation!  ${operation}`);
		}
	};

	const applyComparison = (comparison: IComparison, inputValue: number | boolean | undefined) => {
		if (comparison.operator in comparisonsOperations) {
			let referenceValue;

			if (referenceValue && typeof referenceValue === 'string') {
				referenceValue = findRuleValue(referenceValue);
			} else {
				referenceValue = comparison.value;
			}
			if (referenceValue === undefined) {
				throw new Error(`Invalid comparison operation reference value. ${referenceValue}`);
			}
			if (inputValue === undefined) {
				throw new Error(`Invalid comparison operation inputValue value. ${inputValue}`);
			}
			const comparisonFunc = comparisonsOperations[comparison.operator];
			return comparisonFunc(inputValue, referenceValue);
		} else {
			throw new Error(`Unsupported comparison operator ${comparison.operator}`);
		}
	};

	const getParameterValue = (moduleId: string, parameterId: string, aggregate: Aggregate | undefined) => {
		const valueStats = momentaryValues.momentaryvalues[moduleId]?.values[parameterId];

		return aggregate ? valueStats[aggregate] : valueStats['avg'];
	};

	const findRuleValue = (ruleId: string): number | boolean | undefined => {
		try {
			// Check if rule has been applied already
			if (values.has(ruleId)) {
				return values.get(ruleId);
			}
			let rule = transformRules[ruleId] as ITransformRule;
			if (!rule) {
				throw new Error(`Rule not found ${rule}`);
			}
			if (rule.kind === 'ValueAccessor') {
				let paramValue: number | boolean;
				paramValue = getParameterValue(rule.module, rule.pid, rule.aggregate);
				if (rule.comparison) {
					paramValue = applyComparison(rule.comparison, paramValue);
				}
				values.set(ruleId, paramValue);
				return paramValue;
			}
			// Rule is "DerivedValue"

			// Make sure all rules referenced in this rule are populated and get their values
			// if values is not a string use it directly
			const ruleValues = rule.rules.map((r) => (typeof r === 'string' ? findRuleValue(r) : r));

			let value = undefined;

			if (rule.operation) {
				value = applyOperation(rule.operation, ruleValues);
			} else {
				// If operation is not present, RuleIDs should have only one entry.
				value = ruleValues.length > 0 ? ruleValues[0] : undefined;
			}

			if (rule.comparison) {
				value = applyComparison(rule.comparison, value);
			}
			values.set(ruleId, value);
			return value;
		} catch (error: any) {
			console.warn(`Unable to calculate rule value: ${error.message}`);
			values.set(ruleId, undefined);
			return undefined;
		}
	};

	// Execute all rules
	const deriveMomentaryValues: Record<string, Record<string, ITransformedValue>> = {};

	for (const [ruleId, rule] of Object.entries(transformRules)) {
		let value = findRuleValue(ruleId);

		if (value !== undefined) {
			// By default push everything under #solution module
			const targetModuleId = rule.targetModuleId !== undefined ? '#' + rule.targetModuleId : DEFAULT_TARGET_MODULE;

			if (!deriveMomentaryValues[targetModuleId]) {
				deriveMomentaryValues[targetModuleId] = {};
			}

			deriveMomentaryValues[targetModuleId][ruleId] = {
				value: value,
				title: rule.title,
				unit: rule.unit,
			};
		}
	}

	return deriveMomentaryValues;
};
