import {Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {Delete} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../app/store';
import {selectChannelTitlesByIds} from '../reducers/engineering/notificationChannelsSlice';
import {deleteNotificationRule, getNotificationRulesAsync, INotificationRule, selectNotificationRulesAsArray, updateNotificationRule} from '../reducers/engineering/notificationRulesSlice';
import {selectSolutionById} from '../reducers/engineering/solutionsSlice';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& > *': {
				borderBottom: 'unset',
			},
		},
	};
});

interface RowProps {
	rule: INotificationRule;
}

function Row(props: RowProps) {
	const {rule} = props;
	const {classes} = useStyles();
	const dispatch = useAppDispatch();
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

	const solution = useSelector((state: RootState) => selectSolutionById(state, rule.solutionId));
	const channelIds = useSelector((state: RootState) => selectChannelTitlesByIds(state, rule.channels));

	const toggleActive = async (rule: INotificationRule) => {
		dispatch(updateNotificationRule(rule._id, {active: !rule.active}));
	};

	const deleteRule = async (rule: INotificationRule) => {
		setButtonDisabled(true);
		await dispatch(deleteNotificationRule(rule._id));
		setButtonDisabled(false);
	};

	return (
		<React.Fragment>
			<TableRow className={classes.root} hover>
				<TableCell>
					<Checkbox
						value={rule.active}
						checked={rule.active}
						onClick={() => {
							toggleActive(rule);
						}}
					/>
				</TableCell>
				<TableCell>{rule.title}</TableCell>
				{/* <TableCell>{rule.kind}</TableCell> */}
				<TableCell>{solution?.displayName}</TableCell>
				<TableCell>{channelIds?.join(', ')}</TableCell>
				<TableCell>
					<IconButton
						aria-label="delete"
						size="small"
						disabled={buttonDisabled}
						onClick={() => {
							deleteRule(rule);
						}}
					>
						<Delete />
					</IconButton>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

interface NotificationRuleListProps {}

const NotificationRuleList = (props: NotificationRuleListProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		// Perform initial load of solution data
		// TODO: cache results (RTK Query?)
		dispatch(getNotificationRulesAsync());
	}, [dispatch]);

	const rules = useSelector(selectNotificationRulesAsArray);

	return (
		<TableContainer component={Paper}>
			<Table aria-label={t('solution_overview_table')}>
				<TableHead>
					<TableRow>
						<TableCell>{t('notification_rule_active')}</TableCell>
						<TableCell>{t('notification_rule_name')}</TableCell>
						{/* <TableCell>{t('notification_rule_type')}</TableCell> */}
						<TableCell>{t('solution_display_name')}</TableCell>
						<TableCell>{t('notification_rule_channels')}</TableCell>
						<TableCell>{t('notification_delete')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rules.map((rule) => (
						<Row key={rule._id} rule={rule} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default NotificationRuleList;
