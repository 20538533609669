import {Action, AnyAction, combineReducers, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {isDevEnv} from 'src/lib/envUtils';
import appReducer from '../reducers/appSlice';
import momentaryValuesReducer from '../reducers/momentaryValuesSlice';
import engineeringReducer from './engineering';
import tradingReducer from './trading';
import managementReducer from './management';

// Create root reducer
const combinedRootReducer = combineReducers({
	app: appReducer,
	momentaryValues: momentaryValuesReducer,
	engineering: engineeringReducer,
	trading: tradingReducer,
	management: managementReducer,
});

export type RootState = ReturnType<typeof combinedRootReducer>;

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: ['engineering', 'trading', 'momentaryValues'],
};

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	// Uncomment in case you want to see all the action(s)
	//console.log('action', action);

	// Override app reducer logout action
	if (action.type === 'app/clearAppData') {
		state = {} as RootState;
	}
	return combinedRootReducer(state, action);
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const store = configureStore({
	reducer: persistReducer(persistConfig, rootReducer),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			// disabled in production anyway. Causes dev env to slow down and we are using immer
			serializableCheck: false,
			immutableCheck: false,
		}),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;

// convenience method to get properly typed dispatch
// needed when return value of dispatch() is used
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Expose store if running inside cypress
if (isDevEnv && (window as any).Cypress) {
	(window as any).store = store;
}

export default store;
