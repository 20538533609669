import {Grid, Paper, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useTranslation} from 'react-i18next';
import NotificationChannel from 'src/components/NotificationChannelForm';
import NotificationChannelList from 'src/components/NotificationChannelList';
import NotificationRuleForm from 'src/components/NotificationRuleForm';
import NotificationRuleList from 'src/components/NotificationRuleList';
import {useEffect} from 'react';
import {useAppDispatch} from 'src/app/store';
import {IBreadcrumbItem, setBreadcrumbs} from 'src/reducers/appSlice';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			flexGrow: 1,
		},
		paper: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
		},
	};
});

type NotificationsProps = {};

const Notifications = (props: NotificationsProps) => {
	const {t} = useTranslation();
	const {classes} = useStyles();
	const dispatch = useAppDispatch();

	useEffect(() => {
		let crumbs: IBreadcrumbItem[] = [
			{
				title: t('home_view_title'),
				path: '/engineering',
			},
			{
				title: t('notifications_view_title'),
			},
		];

		dispatch(setBreadcrumbs(crumbs));
		
		return () => {
			dispatch(setBreadcrumbs([]));
		};
	}, [t, dispatch])

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item lg={6} xs={12}>
					<Typography variant="body1" color="primary">
						{t('notification_channel_form_title')}
					</Typography>
					<Paper className={classes.paper}>
						<NotificationChannel />{' '}
					</Paper>
				</Grid>
				<Grid item lg={6} xs={12}>
					<Typography variant="body1" color="primary">
						{t('notification_rule_form_title')}
					</Typography>
					<Paper className={classes.paper}>
						<NotificationRuleForm />{' '}
					</Paper>
				</Grid>
				<Grid item lg={6} xs={12}>
					<Typography variant="body1" color="primary">
						{t('notification_channel_list_title')}
					</Typography>
					<Paper className={classes.paper}>
						<NotificationChannelList />{' '}
					</Paper>
				</Grid>
				<Grid item lg={6} xs={12}>
					<Typography variant="body1" color="primary">
						{t('notification_rule_list_title')}
					</Typography>
					<Paper className={classes.paper}>
						<NotificationRuleList />{' '}
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default Notifications;
