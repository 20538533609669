import {useSnackbar} from 'notistack';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AppNotificationAction, appNotifications, removeNotification} from '../reducers/appSlice';
import {Button} from '@mui/material';

let displayed: string[] = [];

/**
 * Display notifications using notistack.
 * This component connects redux store to notistack.
 * @returns
 */
const AppNotifications = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const notifications = useSelector(appNotifications);
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const addDisplayed = (key: string) => {
		displayed.push(key);
	};

	const removeDisplayed = (removedKey: string) => {
		displayed = displayed.filter((key) => key !== removedKey);
	};

	React.useEffect(() => {
		if (!notifications) {
			return;
		}
		notifications.forEach(({key, variant, message, translateMessage, errorDescription, translateErrorDescription, notificationAction}) => {
			// If notification is already displayed skip it
			if (displayed.includes(key)) return;

			let snackbarMessage = translateMessage ? t(message) : message;
			if (errorDescription) {
				snackbarMessage += `:\n${translateErrorDescription ? t(errorDescription) : errorDescription}`;
			}

			let action: JSX.Element | undefined;
			if (notificationAction) {
				switch (notificationAction.type) {
					case AppNotificationAction.Reload:
						{
							action = (
								<Button color="inherit" size="small" onClick={() => window.location.reload()}>
									{t('reload')}
								</Button>
							);
						}
						break;

					default:
						break;
				}
			}

			// Keep track of displayed notifications
			addDisplayed(key);

			// Display notification using enqueueSnackbar
			enqueueSnackbar(snackbarMessage, {
				key,

				action: action,

				// remove notification from redux store once displayed
				onExited: (_event, snackbarKey) => {
					const key = snackbarKey as string;
					dispatch(removeNotification(key as string));
					removeDisplayed(key);
				},
				variant: variant,
				autoHideDuration: variant === 'error' ? 6000 : 4000,
				style: {whiteSpace: 'pre-line'},
			});
		});
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

	return null;
};

export default AppNotifications;
