import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {Theme} from '@mui/material';

import {makeStyles} from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Joi from 'joi';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../app/store';
import {createNotificationChannel, INotificationChannel} from '../reducers/engineering/notificationChannelsSlice';

const E164PhoneNumberPattern = /^\+[1-9]\d{1,14}$/;

export const createNotificationChannelValidation = Joi.object<Partial<INotificationChannel>>({
	kind: Joi.string().required().valid('sms', 'email'),
	title: Joi.string().trim().min(1).max(128),
	number: Joi.alternatives().conditional('kind', {is: 'sms', then: Joi.string().regex(E164PhoneNumberPattern).required(), otherwise: Joi.forbidden()}),
	email: Joi.alternatives().conditional('kind', {
		is: 'email',
		then: Joi.string().email({tlds: false}).required(),
		otherwise: Joi.forbidden(),
	}),
});

const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {
			'& .MuiTextField-root': {
				//margin: theme.spacing(1),
				width: 200,
			},
		},
	};
});

interface IValidationError {
	[key: string]: boolean;
}

interface INotificationChannelProps {}

const NotificationChannel = (props: INotificationChannelProps) => {
	const {classes} = useStyles();
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [channelType, setChannelType] = useState<'sms' | 'email'>('email');
	const [channelName, setChannelName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [sms, setSMS] = useState<string>('+358');
	const [validationErrors, setValidationErrors] = useState<IValidationError>({});
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

	const clearValidationError = (key: string) => {
		if (validationErrors[key]) {
			const ve = {...validationErrors};
			delete ve[key];
			setValidationErrors(ve);
		}
	};

	const handleChange = (event: any) => {
		const value = event.target.value;
		switch (event.target.name) {
			case 'channel-name':
				// Clear field validation error when user starts to type
				clearValidationError('title');
				setChannelName(value);
				break;
			case 'channel-type-select':
				clearValidationError('email');
				clearValidationError('number');
				setChannelType(value);
				break;
			case 'channel-email':
				clearValidationError('email');
				setEmail(value);
				break;
			case 'channel-sms':
				clearValidationError('number');
				setSMS(value);
				break;
		}
	};

	const validate = (channel: Partial<INotificationChannel>) => {
		// set abortEarly to false to get all validation errors
		const result = createNotificationChannelValidation.validate(channel, {abortEarly: false});

		if (result.error) {
			const err = result.error.details.reduce((prev, err) => {
				const key = err.path[0] as string; // path refers to key in validation schema
				prev[key] = true;
				return prev;
			}, {} as IValidationError);
			setValidationErrors(err);
			return false;
		}
		return true;
	};

	const createChannel = async () => {
		const channel: Partial<INotificationChannel> = {
			kind: channelType,
			title: channelName,
			email: channelType === 'email' ? email : undefined,
			number: channelType === 'sms' ? sms : undefined,
		};

		// Validate user input before making the request
		if (validate(channel)) {
			setButtonDisabled(true);
			await dispatch(createNotificationChannel(channel));
			setButtonDisabled(false);
		}
	};

	return (
		<form className={classes.root} noValidate autoComplete="off">
			<Grid container justifyContent="space-evenly">
				<Grid item>
					<TextField name="channel-name" label={t('notification_channel_name')} value={channelName} onChange={handleChange} error={validationErrors['title']} variant="standard" />
				</Grid>

				<Grid item>
					<FormControl>
						<InputLabel id="channel-type-select-label">{t('channel_type')}</InputLabel>
						<Select labelId="channel-type-select-label" name="channel-type-select" value={channelType} label={t('notification_channel_type')} onChange={handleChange} variant="standard">
							<MenuItem value="email">{t('channel_type_email')}</MenuItem>
							{/*<MenuItem value="sms">{t('channel_type_sms')}</MenuItem> */}
						</Select>
					</FormControl>
				</Grid>

				<Grid item>
					{channelType === 'email' && <TextField name="channel-email" label={t('notification_channel_email')} value={email} onChange={handleChange} error={validationErrors['email']} variant="standard" />}
					{channelType === 'sms' && <TextField name="channel-sms" label={t('notification_channel_sms')} value={sms} onChange={handleChange} error={validationErrors['number']} variant="standard" />}
				</Grid>

				<Grid item>
					<Button variant="contained" color="primary" disabled={buttonDisabled} onClick={createChannel}>
						{t('create_channel_button')}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default NotificationChannel;
