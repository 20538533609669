import {SimplePaletteColorOptions} from '@mui/material/styles';

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=00619d&secondary.color=d3741b

export const mwPrimary: SimplePaletteColorOptions = {
	main: '#00619d',
	dark: '#104b86', // landing content background, specified as main color in design document
};

export const mwSecondary: SimplePaletteColorOptions = {
	main: '#d3741b',
};

export const mw: SimplePaletteColorOptions = {
	main: '#ed5e42',
};

// app menu background
export const mwToolbarPrimary: SimplePaletteColorOptions = {
	main: '#0061a1',
};

// app menu text color
export const mwToolbarSecondary: SimplePaletteColorOptions = {
	main: '#ffffff',
};

// app menu selected background color
export const mwToolbarSelectedBg: SimplePaletteColorOptions = {
	main: '#c6d2de',
};

export const accountCirclePrimary: SimplePaletteColorOptions = {
	main: '#104b86',
};

export const accountCircleSecondary: SimplePaletteColorOptions = {
	main: '#cbd8e4',
};
export const percentageFillPrimary: SimplePaletteColorOptions = {
	main: '#00600f',
};
export const layout1: SimplePaletteColorOptions = {
	main: '#cc9999',
};

export const layout2: SimplePaletteColorOptions = {
	main: '#7fffd4',
};

export const layout3: SimplePaletteColorOptions = {
	main: '#ff69b4',
};

export const layout4: SimplePaletteColorOptions = {
	main: '#228b22',
};

export const layout5: SimplePaletteColorOptions = {
	main: '#fafad2',
};
