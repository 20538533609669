import {Configuration, ILoggerCallback, LogLevel, RedirectRequest} from '@azure/msal-browser';
import {LoggerOptions} from '@azure/msal-common';
import {authVariables} from './authVariables';

export const logMsalLine: ILoggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
	console.log('[MSAL]', message);
};

const authenticationLoggerOptions: LoggerOptions = {
	loggerCallback: logMsalLine,
	piiLoggingEnabled: false,
	logLevel: LogLevel.Warning,
};

export const b2cPolicies = {
	names: {
		signUpSignIn: 'B2C_1_sign_up_sign_in_user_flow',
		resetPassword: 'B2C_1_reset_password_user_flow',
	},
	authorities: {
		signUpSignIn: {
			authority: `https://${authVariables.B2C_DB_TENANT_NAME}.b2clogin.com/${authVariables.B2C_DB_TENANT_NAME}.onmicrosoft.com/B2C_1_sign_up_sign_in_user_flow`,
		},
		resetPassword: {
			authority: `https://${authVariables.B2C_DB_TENANT_NAME}.b2clogin.com/${authVariables.B2C_DB_TENANT_NAME}.onmicrosoft.com/B2C_1_reset_password_user_flow`,
		},
	},
	authorityDomain: `${authVariables.B2C_DB_TENANT_NAME}.b2clogin.com`,
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: authVariables.B2C_DB_WEB_APP_CLIENT_ID,
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		redirectUri: window.location.origin,
		navigateToLoginRequestUrl: false,
		postLogoutRedirectUri: '/login?logout=true',
		knownAuthorities: [b2cPolicies.authorityDomain],
	},
	system: {
		loggerOptions: authenticationLoggerOptions,
	},
};

export const apiScopes = [`https://${authVariables.B2C_DB_TENANT_NAME}.onmicrosoft.com/rest-api/api_access`];

// Scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
	scopes: [...apiScopes],
};

export const tokenRequest = {
	scopes: [...apiScopes],
};

export class AuthError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'AuthError';
	}
}
