import {DateTime} from 'luxon';
import {useEffect, useMemo, useState} from 'react';

// Self updating time limits for OTA file scheduling forms
export const useDateTimeLimits = () => {
	const [now, setNow] = useState<DateTime>(DateTime.local());
	const tick = () => {
		setNow(DateTime.local().startOf('minute'));
	};
	useEffect(() => {
		// Check reference time every 5sec to prevent selecting past dates
		const timer = setInterval(() => tick(), 5000);
		return () => {
			clearInterval(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return useMemo(() => {
		return {
			minDate: now.plus({minutes: 1}),
			maxDate: now.plus({day: 90}),
		};
	}, [now]);
};
