import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Interval} from 'luxon';
import {AppThunk, RootState} from '../../app/store';
import {GET} from '../../lib/httpClient';
import {AvailableMarkets} from '../../lib/reserveMarketConstants';
import {enqueueError} from '../appSlice';
import {clearSolutionDataAction} from '../../app/actions';

interface IRecommendationDetails {
	capacity: number;
}

export type RecommendationsByMarket = {
	[key in AvailableMarkets]: IRecommendationDetails;
};
export interface IBidRecommendation {
	startTime: string;
	endTime: string;
	markets: RecommendationsByMarket;
}

interface IBidRecommendationsResponse {
	solutionId: string;
	recommendations: IBidRecommendation[];
}

interface BidRecommendationsState {
	recommendationsBySolution: {
		[solutionId: string]: IBidRecommendation[] | undefined;
	};
	showRecommendationsBySolution: {
		[solutionId: string]: boolean | undefined;
	};
}

const initialState: BidRecommendationsState = {
	recommendationsBySolution: {},
	showRecommendationsBySolution: {},
};

export const bidRecommendationsSlice = createSlice({
	name: 'trading/bidRecommendations',
	initialState,
	reducers: {
		setBidRecommendations: (state, action: PayloadAction<{solutionId: string; recommendations: IBidRecommendation[]}>) => {
			const {solutionId, recommendations} = action.payload;
			state.recommendationsBySolution[solutionId] = recommendations;
		},
		setShowRecommendationsToggleState: (state, action: PayloadAction<{solutionId: string; enabled: boolean}>) => {
			const {solutionId, enabled} = action.payload;
			state.showRecommendationsBySolution[solutionId] = enabled;
			if (!enabled) {
				delete state.recommendationsBySolution[action.payload.solutionId];
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase('persist/PURGE', (state, _action) => {
			Object.assign(state, initialState);
		});
		builder.addCase(clearSolutionDataAction, (state, action) => {
			delete state.recommendationsBySolution[action.payload];
		});
	},
});

export const {setBidRecommendations, setShowRecommendationsToggleState} = bidRecommendationsSlice.actions;

/**
 * Get recommendations for desired time interval
 */
export const loadBidRecommendationsAsync =
	(solutionId: string, interval: Interval): AppThunk<Promise<boolean>> =>
	async (dispatch) => {
		const start = interval.start?.toUTC().toISO();
		const end = interval.end?.toUTC().toISO();
		try {
			const response = await GET<IBidRecommendationsResponse>(`/api/trading/bidrecommendations/${solutionId}?startTime=${start}&endTime=${end}`);
			dispatch(
				setBidRecommendations({
					recommendations: response.recommendations,
					solutionId: solutionId,
				}),
			);
			return true;
		} catch (error: any) {
			console.error('Unable to load recommendations', solutionId, error);
			dispatch(enqueueError('bid_unable_to_load_recommendations', error));
			return false;
		}
	};

export const selectBidRecommendationsBySolutionId = (state: RootState, solutionId: string): IBidRecommendation[] | undefined => state.trading.bidRecommendations?.recommendationsBySolution[solutionId];
export const selectShowBidRecommendationsStateBySolutionId = (state: RootState, solutionId: string): boolean | undefined => state.trading.bidRecommendations?.showRecommendationsBySolution[solutionId];

export default bidRecommendationsSlice.reducer;
