import {AuthError} from '../authConfig';

//@ts-ignore
import contentDispositionParser from 'content-disposition-parser'; // This library doesn't have TypeScript definitions

export interface IRestApiError {
	errorCode: number;
	//name: string;
	message: string;
	description: string | undefined;
}

export class RestApiError extends Error implements IRestApiError {
	errorCode: number;
	description: string | undefined;

	constructor(code: number, message: string, description?: string) {
		super(message);
		this.name = 'RestApiError';
		this.errorCode = code;
		this.description = description;
	}
}

export class NetworkError extends Error {
	description: string | undefined;

	constructor(message: string, description?: string) {
		super(message);
		this.name = 'NetworkError';
		this.description = description;
	}
}

export class UnderMaintenanceError extends Error {
	constructor() {
		super('UnderMaintenanceError');
	}
}

let accessTokenCb: () => Promise<string>;

export const setAccessTokenCb = (cb: () => Promise<string>) => {
	accessTokenCb = cb;
};

// If access token is being fetched, re-use same promise
// instead of fetching again.
let accessTokenPromise: Promise<string> | null;
export const accessToken = () => {
	if (accessTokenPromise) {
		return accessTokenPromise;
	}
	accessTokenPromise = new Promise((resolve, reject) => {
		accessTokenCb()
			.then((token) => {
				resolve(token);
				accessTokenPromise = null;
			})
			.catch((error) => {
				reject(error);
				accessTokenPromise = null;
			});
	});
	return accessTokenPromise;
};

const getHeaders = async () => {
	const token = await accessToken();

	const headers = new Headers();
	headers.set('Content-Type', 'application/json');
	headers.set('Authorization', `Bearer ${token}`);
	return headers;
};

const contentTypeJson = (res: Response) => {
	// Check repsonse content type. Should be app/json
	const contentType = res.headers.get('Content-type');
	return contentType && contentType.startsWith('application/json');
};

const isErrorResponse = (resp: any) => {
	return resp && 'errorCode' in resp;
};

const handleApiResponse = async <T>(res: Response) => {
	const isJSONResponse = contentTypeJson(res);

	if (res.ok && isJSONResponse) {
		// status 200-299
		return (await res.json()) as T;
	} else if (res.status === 503) {
		throw new UnderMaintenanceError();
	} else if (res.status === 401) {
		// Unauthorized
		throw new AuthError('Unauthorized request');
	} else if (isJSONResponse) {
		// Likely an API error
		const payload = await res.json();
		if (isErrorResponse(payload)) {
			const apiError = payload as IRestApiError;
			console.error(apiError);
			throw new RestApiError(apiError.errorCode, apiError.message, apiError.description);
		}
	} else if (res.status === 500) {
		throw new RestApiError(res.status, 'internal_server_error');
	}
	throw Error('Unknown response');
};

export const POST = async <T>(url: string, body: any): Promise<T> => {
	let res: Response;
	try {
		res = await fetch(url, {
			method: 'POST',
			headers: await getHeaders(),
			body: JSON.stringify(body),
		});
	} catch (error: any) {
		// Fetch throws in case there is network error like DNS, No connection, bad URL etc..
		throw new NetworkError('network_error');
	}
	return await handleApiResponse<T>(res);
};

export const PUT = async <T>(url: string, body: any): Promise<T> => {
	let res: Response;
	try {
		res = await fetch(url, {
			method: 'PUT',
			headers: await getHeaders(),
			body: JSON.stringify(body),
		});
	} catch (error: any) {
		throw new NetworkError('network_error');
	}
	return await handleApiResponse<T>(res);
};

export const GET = async <T>(url: string): Promise<T> => {
	let res: Response;
	try {
		res = await fetch(url, {
			method: 'GET',
			headers: await getHeaders(),
		});
	} catch (error: any) {
		throw new NetworkError('network_error');
	}
	return await handleApiResponse<T>(res);
};

export const DELETE = async <T>(url: string): Promise<T> => {
	let res: Response;
	try {
		res = await fetch(url, {
			method: 'DELETE',
			headers: await getHeaders(),
		});
	} catch (error: any) {
		throw new NetworkError('network_error');
	}
	return await handleApiResponse<T>(res);
};

const initiateDownload = async (res: Response, filename?: string) => {
	// Get response as blob
	const blob = await res.blob();

	// Create object URL from the blob and assign it as target for download link
	const dlUrl = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = dlUrl;

	// If no filename was provided in the call then see if there's a
	// Content-Disposition header in the response, and use filename from there
	if (!filename) {
		const contentDispositionHeader = res.headers.get('Content-Disposition');

		if (contentDispositionHeader) {
			const parsedHeader = contentDispositionParser(contentDispositionHeader);

			if (parsedHeader && parsedHeader.filename) {
				a.setAttribute('download', parsedHeader.filename);
			}
		}
	} else {
		a.setAttribute('download', filename);
	}

	a.click();

	setTimeout(() => {
		a.remove();
		URL.revokeObjectURL(dlUrl);
	}, 0);
};

/**
 * Downloads file, if response response from backend is ok (2xx).
 * For other status codes, response is mapped to matching error type.
 * This enables us to show more detailed information on why download failed.
 */
export const downloadFile = async (url: string, filename?: string) => {
	const headers = await getHeaders();

	const res = await fetch(url, {
		method: 'GET',
		headers: headers,
	});

	const isJSONResponse = contentTypeJson(res);

	if (res.ok) {
		await initiateDownload(res, filename);
		return;
	} else if (res.status === 503) {
		throw new UnderMaintenanceError();
	} else if (res.status === 401) {
		throw new AuthError('Unauthorized request');
	} else if (isJSONResponse) {
		// Likely an API error
		const payload = await res.json();
		if (isErrorResponse(payload)) {
			const apiError = payload as IRestApiError;
			console.error(apiError);
			throw new RestApiError(apiError.errorCode, apiError.message, apiError.description);
		}
	} else if (res.status === 500) {
		throw new RestApiError(res.status, 'internal_server_error');
	}
	throw Error('Unknown response');
};

/**
 * Uploads file as multipart form data
 * @param url
 * @param formData
 * @returns
 */
export const uploadFile = async <T>(url: string, formData: FormData): Promise<T> => {
	let res: Response;

	const token = await accessToken();
	const headers = new Headers();
	headers.set('Authorization', `Bearer ${token}`);

	try {
		res = await fetch(url, {
			method: 'POST',
			headers: headers,
			body: formData,
		});
	} catch (error: any) {
		// Fetch throws in case there is network error like DNS, No connection, bad URL etc..
		throw new NetworkError('network_error');
	}
	return await handleApiResponse<T>(res);
};

/**
 * Uploads file as multipart form data (with progress)
 * @param url
 * @param formData
 * @returns
 */
export const uploadFileProgress = async <T>(url: string, formData: FormData, progressListener: (percentage: number) => void, signal: AbortSignal): Promise<T> => {
	const token = await accessToken();

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		xhr.responseType = 'json';
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);
		xhr.upload.addEventListener('progress', (event) => {
			let percentage = 0;
			if (event.lengthComputable) {
				percentage = (event.loaded / event.total) * 100;
			}
			progressListener(percentage);
		});

		signal.addEventListener('abort', () => {
			if (xhr && xhr.readyState < 4) {
				xhr.abort();
			}
		});

		xhr.addEventListener('abort', reject);
		xhr.addEventListener('error', reject);
		xhr.addEventListener('timeout', reject);
		xhr.addEventListener('load', () => {
			if (xhr.status === 200) {
				const resp = xhr.response;
				resolve(resp);
			} else if (xhr.status === 401) {
				// Unauthorized
				reject(new AuthError('Unauthorized request'));
			} else if (xhr.status === 500) {
				reject(new RestApiError(xhr.status, 'internal_server_error'));
			} else {
				reject(new NetworkError('network_error'));
			}
		});
		xhr.send(formData);
	});
};
